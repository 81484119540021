import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import GeneralServices from "../../services/GeneralServices";
import { useNavigate, useParams } from "react-router-dom";
import { showErrorToast, showSuccessToast } from "../../utils/toastUtils";

function RFQ() {
  // get id form url
  const { id } = useParams();

  const navigate = useNavigate()
  const [rfq, setRfq] = useState({});
  const [validation, setValidation] = useState([]);

  const [selectedResponses, setSelectedResponses] = useState([]);

  const service = new GeneralServices();

  const get_rfq = async () => {
    try {
      const res = await service.get(`rfqs/${id}`);
      setRfq(res);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    get_rfq();
  }, []);

  const forma =
    rfq.created_at &&
    rfq.created_at.split("T")[0].split("-").reverse().join("-");


  const createInvoice = async () => {
    try {
      const res = await service.post("create_invoice", selectedResponses);
      showSuccessToast("تم اضافة الفاتورة بنجاح");
      navigate("/rfqs");

    } catch (error) {
      console.log('error', error)
      if (error.validationData) {
        showErrorToast(error.validationData?.rfq_id[0])
      }
    }
  }

  return (
    <div className="m-4 bg-white rounded-md p-4 shadow-md">
      <div>
        <div>
          <table class="table table-bordered  ">
            <tbody>
              <tr>
                <td>رقم عرض السعر</td>
                <td>
                  <h3>{rfq.rfq_number}#</h3>
                </td>
              </tr>
              <tr>
                <td>التاريخ</td>
                <td>{forma}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <table class="table table-bordered  ">
          <thead class="thead-inverse">
            <tr>
              <th>اسم المنتج</th>
              <th>الكميه</th>
              <th>العروض</th>
            </tr>
          </thead>
          <tbody>
            {rfq.line_items &&
              rfq.line_items.map((item, index) => (
                <tr>
                  <td>{item?.product?.name}</td>
                  <td>{item?.quantity}</td>
                  <td>
                    {item?.responses.length > 0 ? (
                      <ProductPriceList responses={item?.responses} id={item.id} setSelectedResponses={setSelectedResponses} selectedResponses={selectedResponses} />
                    ) : (
                      <div className="flex justify-center">
                        <h1 className="bg-red-500 text-white p-2 rounded-sm">
                          لاتوجد عروض
                        </h1>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div>
        <button onClick={createInvoice} className="btn btn-md bg-blue-500 text-white">انشاء</button>
      </div>
    </div>
  );
}

// showing price for each product
const ProductPriceList = ({ responses, id, setSelectedResponses, selectedResponses }) => {
  const sortedResponses = responses?.sort((a, b) => a.price - b.price);

  // const [selectedResponses, setSelectedResponses] = useState([]);

  const SortByPrice = () => { };

  const service = new GeneralServices();

  const handleSelectChange = async (item) => {
    selectedResponses.includes(item)
      ? setSelectedResponses(
        selectedResponses.filter((response) => response.id != item.id)
      )
      : setSelectedResponses([...selectedResponses, item]);

    console.log("selectedResponses", selectedResponses);
    return;
    try {
      const res = await service.post("create_invoice", item);
      selectedResponses.includes(item)
        ? setSelectedResponses(
          selectedResponses.filter((response) => response.id != item.id)
        )
        : setSelectedResponses([...selectedResponses, item]);
      console.log("res", res);
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div className="">
      <table className="table table-bordered">
        <thead className="thead-inverse">
          <tr>
            <th>المورد</th>
            <th onClick={SortByPrice}>السعر</th>
            <th>الصناعة</th>
            <th>العروض السابقة</th>
          </tr>
        </thead>
        <tbody>
          {sortedResponses?.map((item, index) => (
            <tr key={index}>
              <td>
                <div
                  onClick={() => handleSelectChange(item)}
                  class="form-check"
                >
                  {/* <div onClick={() => selectedResponses.includes(item) ? setSelectedResponses(selectedResponses.filter((response) => response.id != item.id)) : setSelectedResponses([...selectedResponses, item])} class="form-check"> */}
                  <label class="form-check-label">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      value={item.id}
                      checked={selectedResponses.some(
                        (response) => response.id == item.id
                      )}
                    />
                    {item.supplier?.name}
                  </label>
                </div>
              </td>
              <td>{item.price}</td>
              <td>{item.note}</td>
              <td>
                {item.previous_prices.map((prevPrice, index) => (
                  <p key={index}>{prevPrice}</p>
                ))}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RFQ;
