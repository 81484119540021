import React, { useEffect, useState } from 'react'
import GeneralServices from '../../services/GeneralServices'
import Badge from '../../components/Badge'

function RepresentativesList() {

    const [contract, setContract] = useState([])

    const service = new GeneralServices

    const get_contracts = async () => {

        try {
            const res = await service.get('representatives')
            setContract(res)
        } catch (error) {
            console.log(error)
        }
    }


    const handelDelete = async (id) => {

        try {
            const res = await service.post('representatives/delete', {
                id
            })
            setContract(res)

        } catch (error) {

        }
    }



    useEffect(() => {
        get_contracts()
    }, [])


    return (
        <div className='m-4 shadow rounded overflow-auto'>
            <div className='p-2 bg-gray-900 text-white'>
                <h1>المناديب</h1>
            </div>
            <table class="table table-bordered  ">
                <thead class="thead-inverse">
                    <tr>
                        <th>رقم العقد</th>
                        <th>المناديب</th>
                        <th>التاريخ</th>
                    </tr>
                </thead>
                <tbody>
                    {contract &&
                        contract.map((contractItem, index) => (
                            <tr key={index}>
                                <td>{contractItem.contract_number ?? 'لايوجد'}</td>
                                <td>
                                    {contractItem.representatives &&
                                        contractItem.representatives.map((representative, repIndex) => (
                                            <span key={repIndex}>
                                                {representative.representativeable_type === 'App\\Models\\Client' ? (
                                                    <>
                                                        <Badge text={`${representative?.representativeable?.data.first_name} ${representative?.representativeable?.data.last_name}`} />
                                                    </>
                                                ) : representative.representativeable_type === 'App\\Models\\Employee' ? (

                                                    <Badge text={representative?.representativeable?.name} />
                                                ) : (
                                                    <Badge text={representative?.name} />
                                                )}
                                            </span>
                                        ))}
                                </td>
                                <td>{contractItem.created_at}</td>
                            </tr>
                        ))}
                </tbody>


            </table>
        </div>
    )
}


export default RepresentativesList