import React, { useEffect, useState } from 'react';
import GeneralServices from '../../../services/GeneralServices';
import InputFormGroup from '../../../components/InputFormGroup';
import { showErrorToast, showSuccessToast } from '../../../utils/toastUtils';
import { useParams } from 'react-router-dom';
import Loader from '../../../components/Loader';
import SelectFormGroup from '../../../components/SelectFormGroup';

function PurchaseContractProducts() {
    // useParams();
    const { id } = useParams();


    const [contract, setContract] = useState({});
    const [products, setProducts] = useState([]);
    const [quotation, setQuotation] = useState({});
    const [selectedProducts, setSelectedProducts] = useState([]); // State to track selected products and quantities
    const [loading, setLoading] = useState(true);
    const [productsQyt, setProductsQyt] = useState([]);

    const [stageId, setStageId] = useState(1);


    const [name, setName] = useState("");
    const [results, setResults] = useState([]);


    const service = new GeneralServices();

    const get_contract = async () => {
        setQuotation({});
        try {

            const res = await service.get(`contracts/products/${id}/${stageId}`);
            setProductsQyt(res.products_qyt);
            if (res.quotation) {
                setQuotation(res.quotation);
                setContract(res.contract);
            }
            setProducts(res.products);
            setContract(res.contract);
        } catch (error) {
            // Handle error
        } finally {
            setLoading(false)
        }
    }
    const handleCheckboxChange = (productId) => {
        setSelectedProducts((prevSelected) => {
            // Check if the product is already selected
            const isSelected = prevSelected.some((selectedProduct) => selectedProduct.id === productId);

            if (isSelected) {
                // If already selected, remove it from the array
                return prevSelected.filter((selectedProduct) => selectedProduct.id !== productId);
            } else {
                // If not selected, add it with a default quantity of 1
                return [...prevSelected, { id: productId, quantity: 1 }];
            }
        });
    }



    const handleQuantityChange = (productId, quantity) => {
        // Update the quantity for the selected product
        setSelectedProducts((prevSelected) => {
            const updatedSelected = prevSelected.map((id) =>
                id === productId ? { id, quantity } : id
            );
            return updatedSelected;
        });
    }


    const createInvoice = async () => {

        // get products has qty only
        const data = productsQyt.filter((product) => product.qty > 0);
        if (data.length == 0) {

            showErrorToast('لا يوجد منتجات متاحه')
            return;
        }
        try {

            const res = await service.post(`contracts/products/${id}`, { data, stage_id: stageId });
            showSuccessToast('تم اضافة العرض بنجاح')

        } catch (error) {
            console.log('error', error)
            showErrorToast(error.message)
        }
    }

    const handleChange = (e, index) => {
        const updatedProducts = [...productsQyt];
        updatedProducts[index].qty = e.target.value;
        setProductsQyt(updatedProducts);

        // const updatedProducts = [...products];
        // updatedProducts[index].quantity = e.target.value;
        // setProducts(updatedProducts);
    };


    const addProduct = (item) => {
        // Check if a product with the same ID already exists in the productList
        const existingProduct = productsQyt.find((product) => product.product_id === item.id);

        if (existingProduct) {
            // If the product exists, increase its quantity
            existingProduct.qty += 1;
        } else {
            // If the product doesn't exist, add it to the productList
            productsQyt.push({
                id: productsQyt.length + 1, // Assign a new ID (you can adjust this logic if needed)
                product_id: item.id,
                price: item.sale_price, // You may adjust this as per your data structure
                qty: 1, // Initial quantity is 1
                elevator_type_id: item.elevator_types_id, // You may adjust this as per your data structure
                floor: 1, // You may adjust this as per your data structure
                stage: 1, // You may adjust this as per your data structure
                created_at: new Date().toISOString(), // Current timestamp
                updated_at: new Date().toISOString(), // Current timestamp
                product: item,
            });
        }

        setResults([])
        setName("")
    };



    const serach_products = async () => {
        if (name.length == 0) return;
        try {
            const res = await service.post("serach_products", { name });
            setResults(res);
        } catch (error) {
            console.log('error', error)
        }
    };


    useEffect(() => {
        serach_products();
    }, [name]);



    useEffect(() => {
        get_contract();
    }, [stageId]);


    if (loading) return <Loader />

    if (quotation.length > 0) {
        return (
            <div className=' my-3 '>
                <div className='flex justify-between items-center p-3 bg-gray-700'>
                    <h1 className='text-base text-white font-medium'>المنتجات</h1>
                    <SelectFormGroup
                        value={stageId}
                        onChange={(e) => setStageId(e.target.value)}
                        options={[
                            { id: 1, name: 'المرحلة الاولي' },
                            { id: 2, name: 'المرحلة الثانية' },
                            { id: 3, name: 'المرحلة الثالثة' },
                        ]}
                        col={3}
                    />
                </div>
                <ContractInfo contract={contract} />
                <table class="table table-bordered  ">
                    <thead class="thead-inverse">
                        <tr>
                            <th>اسم المنتج</th>
                            <th>الكميه</th>
                        </tr>
                    </thead>
                    <tbody>

                        {quotation && quotation[0].quotation_d.map((item, index) => (

                            <tr>
                                <td>{item.product?.name ?? 'غير موجود'}</td>
                                <td>{item.quantity}</td>

                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        )
    } else return (
        <div>

            <div className='m-4 rounded-md bg-white shadow-md overflow-hidden'>
                <div className='flex justify-between items-center p-3 bg-green-400'>
                    <h1 className='text-base text-gray-700 font-medium'>المنتجات</h1>
                    <SelectFormGroup
                        value={stageId}
                        onChange={(e) => setStageId(e.target.value)}
                        options={[
                            { id: 1, name: 'المرحلة الاولي' },
                            { id: 2, name: 'المرحلة الثانية' },
                            { id: 3, name: 'المرحلة الثالثة' },
                        ]}
                        col={3}
                    />
                </div>
                <ContractInfo contract={contract} />
                <hr className='my-4' />
                <div className="my-4">
                    <div class="form-group">
                        <input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            class="form-control"
                            placeholder="اكتب اسم المنتج"
                        />
                    </div>
                    <div className="position-absolute bg-white z-10 p-2 w-full">
                        <ul>
                            {results.map((item, index) => {
                                return <li className="p-1 border m-1 rounded-sm cursor-pointer" onClick={() => addProduct(item)} key={index}>{item.name}</li>;
                            })}
                        </ul>
                    </div>
                </div>
                <table className="table table-bordered">
                    <thead className="thead-inverse">
                        <tr>
                            <th>الاسم</th>
                            <th>سعر الوحده</th>
                            <th>الكميه</th>
                            <th>المجموع الفرعي</th>
                        </tr>
                    </thead>
                    <tbody>
                        {productsQyt &&
                            productsQyt.map((item, index) => (
                                <tr key={item.id}>
                                    <td>
                                        <div className="form-check">
                                            <label className="form-check-label">

                                                {item.product?.name ?? 'لايوجد اسم'}
                                            </label>
                                        </div>
                                    </td>
                                    <td><p>{item.price}</p></td>
                                    <td>
                                        <InputFormGroup
                                            type='number'
                                            value={item?.qty}
                                            onChange={(e) => handleChange(e, index)}
                                        />
                                    </td>

                                    <td><p>{item.price * item?.qty}</p></td>
                                </tr>
                            ))}
                    </tbody>
                </table>

                <div>
                    <button onClick={createInvoice} className="btn btn-primary">
                        انشاء
                    </button>
                </div>
            </div>

        </div>
    );
}

const ContractInfo = ({ contract }) => {

    const client_name = (client) => {
        if (client?.type == 'individual') {
            return client?.data?.first_name + ' ' + client?.data?.last_name
        } else if (client?.type == 'private') {
            return client?.data?.name
        } else if (client?.type == 'government') {
            return client?.data?.name
        }
    }


    return <div>
        {/* تفاصيل العقد الاسم والعميل والعنوان والمرحله */}
        <table class="table table-bordered  ">
            <thead class="thead-inverse">
                <tr>
                    <th>رقم المشروع</th>
                    <th>اسم المشروع</th>
                    <th>العميل</th>
                    <th>المرحله</th>
                    <th>نوع المصعد</th>
                    <th>عدد الوقفات</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{contract.id}</td>
                    <td>{contract.project_name}</td>
                    <td>{client_name(contract.client)}</td>
                    <td>{contract.stage_id}</td>
                    <td>{contract?.elevator_type?.name}</td>
                    <td>{(contract?.stops_numbers?.name)}</td>
                </tr>

            </tbody>
        </table>
    </div>
}
export default PurchaseContractProducts;
