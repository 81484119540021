import React, { useEffect, useState } from 'react'
import GeneralServices from '../../../../../services/GeneralServices';

function RemainingProducts({ id }) {
    const [products, setProducts] = useState([])

    const service = new GeneralServices;


    const get_products = async () => {
        try {
            const res = await service.get(`installation/work-orders-remaining/${id}`)
            setProducts(res)
        } catch (error) {
            console.error('error', error)
        }
    }

    useEffect(() => {
        get_products()

    }, [])

    return (
        <div>
            <table class="table table-bordered">
                <thead class="thead-inverse">
                    <tr>
                        <th>اسم المنتج</th>
                        {/* <th>الكميه المستلمه</th> */}
                        <th>الكميه المتبقيه</th>
                    </tr>
                </thead>
                <tbody>
                    {products && products.map((product, index) => (

                        <tr>
                            <td>{product?.product?.name}</td>
                            {/* <td>{product?.received}</td> */}
                            <td>{product?.qty - product?.received}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default RemainingProducts