import React, { useEffect, useState } from 'react';
import GeneralServices from '../services/GeneralServices';
import Badge from './Badge';

// NotificationList component
const NotificationList = () => {
    const [notifications, setNotifications] = React.useState([])

    const service = new GeneralServices;

    const get_notifications = async () => {
        try {
            const res = await service.get('notifications')
            setNotifications(res)
        } catch (error) {
            console.log('error', error)
        }
    }

    const readNotification = async (id) => {
        try {
            const res = await service.get(`notifications/make_as_read/${id}`)
            setNotifications(res)
        } catch (error) {
            console.log('error', error)
        }
    }


    const convertToDateString = (dateString) => {
        const moment = require('moment');
        moment.locale("ar");
        const date = moment(dateString);
        return date.fromNow();
    };

    useEffect(() => {
        get_notifications()
    }, [])



    return <div className='rounded shadow bg-white w-96 py-2 justify-center items-center absolute top-14 left-5 z-50'>
        <div className='flex justify-center items-center p-3'>
            <h2 className='font-bold'>الاشعارات</h2>
        </div>
        {notifications && notifications.map((notification, index) => {
            // if (index > 5) return;
            return <div className={`border-t cursor-pointer p-2 ${notification.read_at === null ? 'bg-blue-50 ' : 'bg-gray-50'}`} onClick={() => readNotification(notification.id)}>

                <p className='font-bold'>{notification?.data?.title}</p>
                <div className='text-opacity-80 text-gray-500' dangerouslySetInnerHTML={{ __html: notification?.data?.body }} />
                <div className='flex justify-between mt-2 items-center'>
                    <p className='text-gray-300 ltr' style={{ direction: 'ltr !important' }}>
                        {convertToDateString(notification.created_at)}
                    </p>
                    <Badge text='stock' />
                </div>
            </div>
        })}
        <div>
            {/* <p>مشاهده الكل</p> */}
        </div>


    </div>
    return (
        <div className='m-4 absolute w-36 z-40'>
            <div className='col-4 p-0 border rounded'>
                <div className='p-2 bg-gray-100'>
                    <h2>اشعارات</h2>
                </div>
                {/* {notifications && notifications.map((notification, index) => <Notification key={index} notification={notification} />)} */}


            </div>
        </div>
    )
};



const Notification = ({ notification }) => {


    const convertToDateString = (dateString) => {
        const moment = require('moment');
        moment.locale("ar");
        const date = moment(dateString);
        return date.fromNow();
    };



    return (
        <div className={`items-center rounded shadow-sm p-2 m-2 overflow-ellipsis`}>
            <div className='row items-center'>
                <div className='w-8 h-8 rounded-full mx-1 border border-indigo-300 justify-center items-center flex overflow-auto bg-indigo-100'>
                    <div className={`bg-red-500 w-2 h-2 rounded-full top-0 left-0`}></div>
                    <i class="fa fa-bell" aria-hidden="true"></i>
                </div>
                <div className='col p-2'>
                    <div>
                        <p className='font-semibold text-sm text-gray-500'>{notification.data.title}</p>
                        <p className='text-sm text-gray-500'>{notification.data.body}</p>
                    </div>
                </div>
            </div>
            <hr className='mt-1' />
            <div className='flex items-center space-x-2 p-2 bg-gray-50'>
                <Badge text={convertToDateString(notification.created_at)} />
                <div className='w-2 h-2 rounded-full bg-gray-500 mx-1 mb-4'></div>
                <Badge text={notification.type} />
            </div>
        </div>

    );
}
export default NotificationList;
