import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import GeneralServices from '../../services/GeneralServices';
import { useParams } from 'react-router-dom';
import SelectFormGroup from '../../components/SelectFormGroup';
import { showErrorToast, showSuccessToast } from '../../utils/toastUtils';

function RfqSupplier() {
    // id form link

    // get id form url
    const { id } = useParams();
    const [rfq, setRfq] = useState({});
    const [suppliers, setSuppliers] = useState([]);
    const [supplier_id, setSupplier_id] = useState(null)

    const [selectedProducts, setSelectedProducts] = useState([]);
    const service = new GeneralServices;


    const get_rfq = async () => {
        try {
            const res = await service.get(`rfqs/${id}`);
            setRfq(res)
        } catch (error) {
            console.log(error)
        }
    }

    const get_suppliers = async () => {
        try {
            const res = await service.get('suppliers');
            setSuppliers(res)
            setSupplier_id(res[0].id)
        } catch (error) {
            console.log(error)
        }
    }

    const handleSelectChange = async (item) => {
        selectedProducts.includes(item) ? setSelectedProducts(selectedProducts.filter((product) => product.id != item.id)) : setSelectedProducts([...selectedProducts, item])
    }


    const handSubmitForSupplier = async () => {

        if (selectedProducts.length == 0) {
            showErrorToast('الرجاء اختيار المنتجات اولا')
            return
        }

        try {
            const res = await service.post('rfqs_supplisers', {
                supplier_id: supplier_id,
                rfq_id: id,
                items: selectedProducts
            })
            setSelectedProducts([])
            showSuccessToast('تمت العملية بنجاح')

        } catch (error) {
            console.log('error', error)
            if (error.state == 400) {
                showErrorToast(error.message + ' تم اختياره مسبقا لنفس المورد')
            }
        }
    }


    useEffect(() => {
        get_rfq();
        get_suppliers();
    }, [])


    const forma = rfq.created_at && rfq.created_at.split('T')[0].split('-').reverse().join('-')

    return (
        <div className='m-4 bg-white rounded-md p-4 shadow-md'>
            <div>
                <div>
                    <table class="table table-bordered  ">

                        <tbody>
                            <tr>
                                <td>
                                    رقم عرض السعر
                                </td>
                                <td>
                                    <h3>{rfq.rfq_number}#</h3>

                                </td>
                            </tr>
                            <tr>
                                <td>التاريخ</td>
                                <td>
                                    {forma}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <table class="table table-bordered  ">
                    <thead class="thead-inverse">
                        <tr>
                            <th>اسم المنتج</th>
                            <th>الكميه</th>
                            <th>عدد العروض</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rfq.line_items && rfq.line_items.map((item, index) => (
                            <tr>
                                <td>
                                    <div class="form-check">
                                        <label class="form-check-label">
                                            <input type="checkbox"
                                                onClick={(e) => handleSelectChange(item)}
                                                class="form-check-input" value="checkedValue"
                                                checked={selectedProducts.includes(item)}
                                            />
                                            {item?.product?.name}
                                        </label>
                                    </div>
                                </td>
                                <td>{item?.quantity}</td>
                                <td>{item?.number_of_rfq_line_items}</td>

                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className='p-4 bg-yellow-200'>
                    <div className='p-4'>
                        <h1 className='text-2xl mb-4'>اختيار المورد</h1>
                        <hr />
                    </div>
                    <div className='flex justify-between'>

                        <SelectFormGroup
                            value={supplier_id}
                            onChange={(e) => setSupplier_id(e.target.value)}
                            options={suppliers}
                        />
                        <button onClick={handSubmitForSupplier} className='btn btn-md bg-green-500 text-white'>اختيار</button>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default RfqSupplier