import React, { useEffect, useState } from 'react'
import SelectFormGroup from '../../../components/SelectFormGroup'
import { showErrorToast, showSuccessToast } from '../../../utils/toastUtils'
import GeneralServices from '../../../services/GeneralServices'


const ExternalDoorSpecifications = ({ contract, setContract }) => {

    const [floors, setFloors] = useState([])
    const [elevatorData, setElevatorData] = useState([])

    const [specifications, setSpecifications] = useState([{
        floor: 1,
        door_number: 1,
        external_door_specifications: 1,
        door_opening_direction: 1,
        external_door_specifications2: null,
        door_opening_direction2: null,
    }])

    const addSpecification = () => {
        if (specifications.length >= contract?.elevator?.stop_number) {
            showErrorToast('لايمكن ان تكون الاضافات اكثر من عدد الطوابق')
            return;
        }

        setSpecifications([...specifications, {
            floor: 1,
            door_number: 1,
            external_door_specifications: 1,
            door_opening_direction: 1,
            external_door_specifications2: null,
            door_opening_direction2: null,
        }])
    }

    const elevator_data = async () => {
        try {
            const res = await generalServices.get('elevator_data')
            console.log('res', res)
            setElevatorData(res)
        } catch (error) {

        }
    }

    const onChangeSpecification = (fild, value, index) => {
        const newSpecifications = specifications.map((item, i) => {
            if (i === index) {
                item[fild] = value
            }
            return item
        })
        setSpecifications(newSpecifications)
    }

    const onChangeDoorNumber = (fild, value, index) => {

        const newSpecifications = specifications.map((item, i) => {
            if (i === index) {
                item[fild] = value
                if (value == 1) {
                    item['external_door_specifications2'] = null
                    item['door_opening_direction2'] = null
                }
                else
                    item['external_door_specifications2'] = 1
                item['door_opening_direction2'] = 1
            }

            return item
        })
        setSpecifications(newSpecifications)
    }


    const generalServices = new GeneralServices;


    const handelDelete = (index) => {
        const newSpecifications = specifications.filter((item, i) => i !== index)
        setSpecifications(newSpecifications)
    }

    useEffect(() => {
        setContract({ ...contract, external_door_specifications: specifications })
    }, [specifications])


    useEffect(() => {

        const newSpecifications = [];
        for (let i = 0; i < contract?.elevator?.stop_number; i++) {
            if (!newSpecifications[i]) {
                newSpecifications.push({
                    floor: 1,
                    door_number: 1,
                    external_door_specifications: 1,
                    door_opening_direction: 1,
                    external_door_specifications2: null,
                    door_opening_direction2: null,
                })
            }
        }
        setSpecifications(newSpecifications)


    }, [contract?.elevator?.stop_number])

    const get_floors = async () => {
        try {
            const res = await generalServices.get('floors')
            setFloors(res)
        } catch (error) {

        }
    }

    useEffect(() => {
        get_floors()
        if (contract.outer_door_specifications) {
            setSpecifications(contract.outer_door_specifications)
        }
        elevator_data()

    }, [])





    return (
        <div>

            <div className='bg-gray-700  p-2 flex justify-between items-center'>


                <h1 className='text-base text-white font-medium'>مواصفات الباب الخارجي</h1>
                <img onClick={addSpecification}
                    className="w-8 h-8 cursor-pointer"
                    src="https://img.icons8.com/glyph-neue/64/ffffff/plus-2-math.png"
                    alt="add" />
            </div>
            <div className='row p-4'>


                {specifications.map((item, index) => (

                    <div className='col-12'>
                        <div className='row items-center'>
                            <div className='col'>
                                <div className='row'>
                                    <SelectFormGroup
                                        label='الطابق'
                                        value={item.floor}
                                        onChange={(e) => onChangeSpecification('floor', e.target.value, index)}
                                        options={floors}
                                        col={2}
                                    />
                                    <SelectFormGroup
                                        label='عدد الابواب'
                                        value={item.door_number}
                                        onChange={(e) => onChangeDoorNumber('door_number', e.target.value, index)}
                                        options={[
                                            { id: '1', name: 'باب' },
                                            { id: '2', name: 'بابين' },
                                        ]}
                                        col={2}
                                    />

                                    <SelectFormGroup
                                        label='موصفات الباب الخارجي'
                                        value={item.external_door_specifications}
                                        onChange={(e) => onChangeSpecification('external_door_specifications', e.target.value, index)}
                                        options={elevatorData.external_door_specifications}
                                        col={2}
                                    />



                                    <SelectFormGroup
                                        label='اتجاه فتح الباب'
                                        value={item.door_opening_direction}
                                        onChange={(e) => onChangeSpecification('door_opening_direction', e.target.value, index)}
                                        options={elevatorData.inner_door_types}

                                        col={2}
                                    />


                                    {item.external_door_specifications2 &&
                                        <>
                                            <SelectFormGroup
                                                label='موصفات الباب الخارجي الثاني'
                                                value={item.external_door_specifications2}
                                                onChange={(e) => onChangeSpecification('external_door_specifications2', e.target.value, index)}
                                                options={elevatorData.external_door_specifications}
                                                // options={[
                                                //     { id: 'عادي', name: 'عادي' },
                                                //     { id: 'تلبيس', name: 'تلبيس' },
                                                //     { id: 'رش بويه ', name: 'رش بويه ' },
                                                // ]}

                                                col={2}
                                            />

                                            <SelectFormGroup
                                                label='اتجاه فتح الباب الثاني'
                                                value={item.door_opening_direction2}
                                                onChange={(e) => onChangeSpecification('door_opening_direction2', e.target.value, index)}

                                                options={elevatorData.inner_door_types}
                                                // options={[
                                                //     { id: 'غير محدد', name: 'غير محدد' },
                                                //     { id: 'يسار', name: 'يسار' },
                                                //     { id: 'يمين', name: 'يمين' },
                                                //     { id: 'سنتر', name: 'سنتر' },
                                                //     { id: 'تلسكوبي يسار', name: 'تلسكوبي يسار' },
                                                //     { id: 'تلسكوبي يمين', name: 'تلسكوبي يمين' },
                                                // ]}
                                                col={2}
                                            />
                                        </>
                                    }
                                </div>
                            </div>

                            <img className="h-10 cursor-pointer mt-4" onClick={() => handelDelete(index)} src="https://img.icons8.com/color/48/delete-forever.png" alt="delete" />

                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default ExternalDoorSpecifications