import React, { useEffect } from 'react'
import InputFormGroup from '../../../../components/InputFormGroup';
import SelectFormGroup from '../../../../components/SelectFormGroup';
import ImageUpload from '../../../../components/ImageUpload';
import { useState } from 'react';
import GeneralServices from '../../../../services/GeneralServices';
import { showSuccessToast } from '../../../../utils/toastUtils';
import FileUploadComponent from '../../../../FileUploadComponent';

const Private = ({ data, setData, validation = [] }) => {




    const {
        name,
        owner_name,
        commercial_register,
        tax_number,
        id_number,
        phone,
        whatsapp,
        how_did_you_get_to_us,
        phone2

    } = data;

    useEffect(() => {
        console.log('laod priver')
        setData({
            name: name ?? '',
            owner_name: owner_name ?? '',
            commercial_register: commercial_register ?? '',
            tax_number: tax_number ?? '',
            id_number: id_number ?? '',
            phone: phone ?? '',
            whatsapp: whatsapp ?? '',
            phone2: phone2 ?? '',
        })
    }, [])

    const handelonChangeData = (fild, value) => {
        setData({ ...data, [fild]: value })
    }
    const service = new GeneralServices;


    const findCompany = async (type, value) => {


        try {
            const res = await service.get(`clients/${type}/${value}`)

            if (res.id) {
                showSuccessToast('الشركه بيانات الشركه موجوده مسبقا')
                setData({
                    ...data,
                    name: res.data.name,
                    owner_name: res.data.owner_name,
                    commercial_register: res.data.commercial_register,
                    tax_number: res.data.tax_number,
                    id_number: res.data.id_number,
                    phone: res.data.phone,
                    phone2: res.data.phone2,
                    whatsapp: res.data.whatsapp,
                })
            }
        } catch (error) {

        }
    }



    const [attachment, setAttachment] = useState(null)
    const handleLogoChange = (image) => {
        data.image = image
        setData({ ...data, image })
        setAttachment(image)
    };


    return (
        <div className='row px-2'>
            <InputFormGroup
                label='رقم السجل التجارى'
                value={commercial_register}
                onChange={(e) => handelonChangeData('commercial_register', e.target.value)}
                col={4}
                onBlur={() => findCompany('commercial_register', commercial_register)}
                error={validation['client.commercial_register']?.[0]}
            />
            <InputFormGroup
                label='الرقم الضريبى'
                value={tax_number}
                onChange={(e) => handelonChangeData('tax_number', e.target.value)}

                onBlur={() => findCompany('tax_number', tax_number)}
                col={4}
                error={validation['client.tax_number']?.[0]}
            />
            <InputFormGroup
                label='اسم المؤسسه'
                value={name}
                onChange={(e) => handelonChangeData('name', e.target.value)}
                col={4}
                error={validation['client.name']?.[0]}
            />
            <InputFormGroup
                label='يثملها'
                value={owner_name}
                onChange={(e) => handelonChangeData('owner_name', e.target.value)}
                col={4}
                error={validation['client.owner_name']?.[0]}
            />

            <InputFormGroup
                label='رقم الهويه'
                value={id_number}
                onChange={(e) => handelonChangeData('id_number', e.target.value)}
                col={4}
                error={validation['client.id_number']?.[0]}
            />
            <InputFormGroup
                label='الجوال'
                value={phone}
                onChange={(e) => handelonChangeData('phone', e.target.value)}
                col={4}
                error={validation['client.phone']?.[0]}
            />
            <InputFormGroup
                label='الواست اب'
                value={whatsapp}
                onChange={(e) => handelonChangeData('whatsapp', e.target.value)}
                col={4}
                error={validation['client.whatsapp']?.[0]}
            />
            <InputFormGroup
                label='جوال اخر'
                value={phone2}
                onChange={(e) => handelonChangeData('phone2', e.target.value)}
                col={4}
                error={validation['client.phone2']?.[0]}
            />



            <FileUploadComponent setData={setData} data={data} validation={validation} />
        </div>
    )
}


export default Private

