import React from 'react'
import { Route, Routes } from 'react-router-dom'
import PurchasesLayout from '../../layoutes/PurchasesLayout'
import PurchasesDashboard from './PurchasesDashboard'

function PurchasesRoutes() {
    return (
        <Routes>
            <Route path="/" Component={PurchasesLayout} >
                <Route path="" Component={PurchasesDashboard} />
            </Route>
        </Routes>
    )
}

export default PurchasesRoutes