import axios from "../api/axiosConfig";
import BaseService from "./BaseService";

class DashboardServices extends BaseService {
    async get(url) {

        try {
            const response = await axios({
                url: url,
                method: "GET",
            });
            return response.data;
        }
        catch (error) {
            this.handleRequestError(error);
            return [];
        }
    }
}


export default DashboardServices;