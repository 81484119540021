import React, { useState, useEffect } from "react";
import DashboardServices from "../services/DashboardServices";
import Alert from "../components/Alert";
import { Helmet } from "react-helmet";

const Dashboard = () => {

  useEffect(() => {
    setLoading(true)
    setErrorMessages(null)
    getDashboard();
    setLoading(false)
  }, [])


  const [loading, setLoading] = useState(false)
  const [dashboard, setdashboard] = useState([]);
  const [errorMessages, setErrorMessages] = useState(null);

  const dashboardServices = new DashboardServices();

  const getDashboard = async () => {
    setLoading(true)

    setErrorMessages(null)

    try {
      const response = await dashboardServices.get('dashboard')
      setdashboard(response)
    } catch (error) {
      setErrorMessages(error)
    }
    setLoading(false)

  }
  const title = "لوحة التحكم"
  if (loading)
    return <div>
      <p>loading...</p>
    </div>
  return (
    <>


      <div className="mt-4">

        {errorMessages && <Alert
          message={errorMessages.message}
          type={errorMessages.type} />}


        <h1 className="text-xl">dashboard</h1>

      </div>
    </>
  );
};

export default Dashboard;
