import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../contexts/AuthContextProvider';
import MainHeader from '../components/MainHeader';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import PurchasesSidebar from '../models/purchases/PurchasesSidebar';

function PurchasesLayout() {
    const [loading, setLoading] = useState(false)

    const { user, setUser } = useContext(AuthContext);


    const navigate = useNavigate();

    const data = localStorage.getItem('user');
    useEffect(() => {
        const data = localStorage.getItem('user');
        if (!data || data === 'undefined' || data === 'null') {

            setUser(null);
            navigate('/login');
        }
        setLoading(false);
    }, [user]);




    if (!data) {
        return <Navigate to="/login" />
    }

    if (loading) return <div className='bg-blue-200 h-screen justify-center items-center flex'>
        <Loader />
    </div>;

    return (
        <div className="wrapper">
            <MainHeader />
            <PurchasesSidebar />

            <div className="content-wrapper" style={{ minHeight: "2175.5px" }}>
                <div className="content">
                    <div className="container-fluid">
                        <Outlet />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PurchasesLayout