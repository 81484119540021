import React from 'react'
import StocksLayout from '../../layoutes/StocksLayout'
import stocks from './stocks'
import OrdersList from './pages/orders/OrdersList'
import OrderDetails from './pages/orders/OrderDetails'
import { Route, Routes } from 'react-router-dom'
import ProductsList from './pages/products/ProductsList'
import ProductDetails from './pages/products/ProductDetails'
import RfqList from './pages/rfqs/RfqList'
import RfqCreate from './pages/rfqs/RfqCreate'

function StocksRoute() {
    return (
        <Routes>
            <Route path="/" Component={StocksLayout} >

                <Route path="" Component={stocks} />
                <Route path="orders" Component={OrdersList} />
                <Route path="orders/:id" Component={OrderDetails} />

                <Route path="products" Component={ProductsList} />
                <Route path="products/:id" Component={ProductDetails} />

                <Route path="rfqs" Component={RfqList} />
                <Route path="rfqs/create" Component={RfqCreate} />

            </Route>
        </Routes>
    )
}

export default StocksRoute