import React, { useEffect, useState } from 'react'

import GeneralServices from '../../services/GeneralServices';


import FinancialSection from './components/FinancialSection';
import ExternalDoorSpecifications from './components/ExternalDoorSpecifications';
import Client from './components/Client';
import ElevatorData from './components/ElevatorData';
import Technicians from './components/Technicians';
import Address from './components/Address';
import { showErrorToast, showLoadingToast, showSuccessToast } from '../../utils/toastUtils';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import SelectFormGroup from '../../components/SelectFormGroup';
import Multiselect from 'multiselect-react-dropdown';
import InputFormGroup from '../../components/InputFormGroup';

function ContractCreate() {

    const [contract, setContract] = useState({})
    const [validation, setValidation] = useState([])
    const [Loading, setLoading] = useState(false)

    const navigate = useNavigate();

    const generalServices = new GeneralServices;

    const handelSaveData = async () => {
        setValidation([])
        const t = showLoadingToast('جاري انشاء العقد')

        setLoading(true)
        try {
            const res = await generalServices.post('contract', contract)
            showSuccessToast('مبروك انشاء العقد بنجاح')
            navigate('/contracts'); // Go to contracts route

        } catch (error) {

            if (error.validationData) {
                showErrorToast('تاكيد من انك ملئت جميع الحقول المطلوبة')
                setValidation(error.validationData)
                console.log('first', error.validationData)
            }
        }
        finally {
            setLoading(false)
        }
        toast.dismiss(t);
    }

    return (
        <div className='rounded-sm shadow-lg m-4'>

            <Client contract={contract} validation={validation} setContract={setContract} />
            <Representative contract={contract} setContract={setContract} />

            <Address contract={contract} setContract={setContract} validation={validation} />
            <ElevatorData contract={contract} setContract={setContract} />
            <ExternalDoorSpecifications contract={contract} setContract={setContract} />
            <FinancialSection contract={contract} validation={validation} setContract={setContract} />

            <div className='p-2 bg-gray-100'>
                <button disabled={Loading} onClick={handelSaveData} className={`btn btn-md bg-green-500 text-white rounded-sm ${Loading && 'loading'}`}>حفظ</button>
            </div>
        </div>
    )
}


const Representative = ({ contract, setContract }) => {

    const [how_did_you_get_to_us, setHow_did_you_get_to_us] = useState('')

    const handelonChangeData = (value) => {
        setHow_did_you_get_to_us(value)
        setContract({ ...contract, how_did_you_get_to_us: value })
    }




    return <div className='my-4'>
        <div>
            <div className='bg-gray-700  p-2 flex justify-between items-center my-2'>
                <h1 className='text-base text-white font-medium'>كيف وصلت لينا</h1>
            </div>


            <div className='flex items-baseline'>
                <SelectFormGroup
                    label='كيف وصلت الينا'
                    value={how_did_you_get_to_us}
                    onChange={(e) => handelonChangeData(e.target.value)}
                    options={[
                        { id: 'الموقع الالكتروني', name: 'الموقع الالكتروني' },
                        { id: 'الفيس', name: 'الفيس' },
                        { id: 'انستا', name: 'انستا' },
                        { id: 'تويتر', name: 'تويتر' },
                        { id: 'سناب شات', name: 'سناب شات' },
                        { id: 'يويتوب', name: 'يويتوب' },
                        { id: 'اعلانات', name: 'اعلانات' },
                        { id: 'ClientOfTheOrganization', name: 'عميل لدى المؤسسة' },
                        { id: 'InternalRepresentative', name: 'مندوب داخلي' },
                        { id: 'ExternalRepresentative', name: 'مندوب خارجي' },
                    ]}
                    col={6}
                />
                <div className='col-6'>
                    {how_did_you_get_to_us === 'InternalRepresentative' && <InternalRepresentative contract={contract} setContract={setContract} />}
                    {how_did_you_get_to_us === 'ClientOfTheOrganization' && <ClientOfTheOrganization contract={contract} setContract={setContract} />}
                    {how_did_you_get_to_us === 'ExternalRepresentative' && <ExternalRepresentative contract={contract} setContract={setContract} />}
                </div>
            </div>
        </div>

    </div>
}



const InternalRepresentative = ({ setContract, contract }) => {

    const [employees, setEmployees] = useState([])
    const [selectedEmployees, setSelectedEmployees] = useState([])

    const service = new GeneralServices;


    const get_employees = async () => {
        try {
            const data = await service.get('employees')
            setEmployees(data)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        get_employees()
    }, [])


    useEffect(() => {
        setContract({ ...contract, representative: selectedEmployees })
    }, [selectedEmployees])



    return <div>

        <div class="form-group col">
            <label>مندوب داخلي</label>
            <Multiselect
                options={employees}
                selectedValues={selectedEmployees}
                onSelect={setSelectedEmployees}
                onRemove={setSelectedEmployees}
                displayValue="name"
                placeholder="مندوب داخلي"
                // showCheckbox={true}
                style={{ 'textAlign': 'right' }}
            // onSearch={setSelectedEmployees}
            />
        </div>


    </div>
}

const ExternalRepresentative = ({ setContract, contract }) => {

    // المناديب
    const [representatives, setRepresentatives] = useState([{
        'name': '',
        'phone': '',
    }])

    useEffect(() => {
        // get_employees()
    }, [])

    const onRemove = (item) => {
        const repr = representatives.filter(rep => rep !== item);
        setRepresentatives(repr)
    }

    const updateObject = (itemToUpdate, propertyToUpdate, newValue) => {
        const updatedRepresentatives = representatives.map(item => {
            if (item === itemToUpdate) {
                // If this is the item to update, create a new object with the changed property
                return {
                    ...item,
                    [propertyToUpdate]: newValue,
                };
            }
            return item; // Otherwise, return the original item
        });

        // Update the state with the new array
        setRepresentatives(updatedRepresentatives);
        setContract({ ...contract, representative: updatedRepresentatives })
    };

    useEffect(() => {
        // setContract({ ...contract, representative: selectedEmployees })
    }, [])



    return <div className='col-12'>
        {representatives.map(item =>
            <div class="row">
                <div className='col'>
                    <div class="row">
                        <InputFormGroup
                            label='الاسم'
                            onChange={(e) => updateObject(item, 'name', e.target.value)}
                            value={item.name}
                        />
                        <InputFormGroup
                            value={item.phone}
                            onChange={(e) => updateObject(item, 'phone', e.target.value)}
                            label='الهاتف' />

                    </div>
                </div>
                <div>
                    <span onClick={() => onRemove(item)} class="badge badge-danger">_</span>
                </div>

            </div>
        )}
        <div>
            <span onClick={() => setRepresentatives([...representatives, { 'name': '', 'phone': '' }])} class="badge badge-primary">+</span>
        </div>

    </div>
}


const ClientOfTheOrganization = ({ setContract, contract }) => {

    const [employees, setEmployees] = useState([])

    const [cliens, setCliens] = useState([])
    const [selectedCliens, setSelectedCliens] = useState([])

    const [selectedEmployees, setSelectedEmployees] = useState([])

    const service = new GeneralServices;


    const get_cliens = async () => {
        try {
            const data = await service.get('cliens')
            setCliens(data)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        get_cliens()
    }, [])


    useEffect(() => {
        setContract({ ...contract, representative: selectedEmployees })
    }, [selectedEmployees])

    return <div>

        <div class="form-group col">
            <label>مندوب داخلي</label>
            <Multiselect
                options={cliens}
                selectedValues={selectedEmployees}
                onSelect={setSelectedEmployees}
                onRemove={setSelectedEmployees}
                displayValue="name"
                placeholder="مندوب داخلي"
                // showCheckbox={true}
                style={{ 'textAlign': 'right' }}
            // onSearch={setSelectedEmployees}
            />
        </div>


    </div>
}

export default ContractCreate