import React, { useEffect, useState } from 'react'
import GeneralServices from '../../services/GeneralServices';
import Badge from '../../components/Badge';
import SelectFormGroup from '../../components/SelectFormGroup';
import InputFormGroup from '../../components/InputFormGroup';

function Rules() {

    const [tab, setTab] = useState('rules')

    return (
        <div className='m-4 border rounded '>

            <div className='p-4'>
                <RulesList />
            </div>
        </div>
    )
}


const RulesList = () => {

    const [ruleCategories, setRuleCategories] = useState([])
    const [rules, setRules] = useState([])
    const [ruleCategoryId, setRuleCategoryId] = useState(1)
    const [items, setItems] = useState([])

    const [name, setName] = useState('')

    const service = new GeneralServices();


    const getRuleCategories = async () => {


        try {
            const res = await service.get('rule_categories')
            setRuleCategories(res)
        } catch (error) {
            console.log(error)
        }
    }

    const getRules = async () => {


        try {
            const res = await service.get(`rules/${ruleCategoryId}`)
            setRules(res)
        } catch (error) {
            console.log(error)
        }
    }


    const getRuleItems = async () => {

        try {
            const res = await service.get(`rules/${ruleCategoryId}/items`)
            setItems(res)
        } catch (error) {
            console.log(error)
        }
    }

    const updatedRules = async () => {

        try {
            const res = await service.post(`rules`, {
                ruleCategoryId,
                rules
            })
            setRules(res)
        } catch (error) {
            console.log(error)
        }
    }


    useEffect(() => {
        getRuleCategories()
        getRules()

    }, [])

    useEffect(() => {
        getRuleItems()
        getRules()

    }, [ruleCategoryId])





    return <div>
        <div className='my-4'>

            <AddRule setRuleCategories={setRuleCategories} />
        </div>
        <div className='flex justify-between items-center bg-gray-500 p-3'>
            <h1 className='text-white'>الصلاحيات</h1>
            <SelectFormGroup
                value={ruleCategoryId}
                options={ruleCategories}
                onChange={e => setRuleCategoryId(e.target.value)}
                col={3}
            />
        </div>

        <div className='row p-2'>

            {rules && rules.map((rule, index) => {

                return <div class="form-check col-3" key={index}>
                    <label class="form-check-label">
                        <input type="checkbox"
                            class="form-check-input"
                            onChange={e => {
                                if (e.target.checked) {
                                    const updatedRules = [...rules]; // Create a copy of the rules array
                                    updatedRules[index] = { ...updatedRules[index], selected: true }; // Update the selected property to true
                                    setRules(updatedRules); // Update the state with the modified array
                                } else {
                                    const updatedRules = [...rules]; // Create a copy of the rules array
                                    updatedRules[index] = { ...updatedRules[index], selected: false }; // Update the selected property to false
                                    setRules(updatedRules); // Update the state with the modified array
                                }
                            }}
                            checked={rule.selected}
                        />
                        {rule.display_name}
                    </label>
                </div>
            })}
        </div>
        <hr className='my-3' />
        <div>
            <button onClick={updatedRules} className='btn btn-primary'>تحديث</button>
        </div>
    </div>
}



const AddRule = ({ setRuleCategories }) => {

    const [name, setName] = useState('')
    const [validation, setValidation] = useState([])

    const service = new GeneralServices();


    const AddRuleCategories = async () => {
        setValidation([])

        try {
            const res = await service.post(`rule_categories`, { name })
            setName('')
            setRuleCategories(res)

        } catch (error) {

            if (error.validationData) {
                setValidation(error.validationData)
            }
        }
    }


    return <div className='my-4'>
        <InputFormGroup
            label='الاسم'
            value={name}
            onChange={e => setName(e.target.value)}
            col={12}
            error={validation?.name?.[0]}
        />

        <div className="form-group col mt-2">

            <button onClick={AddRuleCategories} className='btn btn-primary'>اضافة</button>
        </div>
    </div>
}
export default Rules