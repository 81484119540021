import React from 'react'
import GeneralServices from '../../services/GeneralServices';
import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import SelectFormGroup from '../../components/SelectFormGroup';
import InputFormGroup from '../../components/InputFormGroup';
import { formatDate } from '../../utils/utility';
import Badge from '../../components/Badge';

function InvoiceShow() {


    const { id } = useParams()

    const [invoice, setInvoice] = useState({});
    const [products, setProducts] = useState([])
    const [suppliers, setSuppliers] = useState([])

    const [validation, setValidation] = useState([])

    const [tab, setTab] = useState('payments')
    const [payments, setPayments] = useState([])
    const [paymentForm, setPaymentForm] = useState({
        supplier_id: '',
        amount: '',
        attachment: ''
    })


    const service = new GeneralServices;


    const get_invoice_info = async () => {
        try {
            const res = await service.get(`invoice_list/${id}`);
            console.log('res', res.suppliers)
            setInvoice(res.invoices)
            setProducts(res.invoices.invoice_details)
            setSuppliers(res.suppliers)

            setPaymentForm({ ...paymentForm, supplier_id: res.suppliers[0].id })

        } catch (error) {

        }
    }


    const get_suppliers = async () => {

        try {
            const res = await service.get('suppliers')
            // setSuppliers(res)
            setPaymentForm({ ...paymentForm, supplier_id: res[0].id })
        } catch (error) {
            console.log(error)
        }
    }

    const get_supplier_payments = async () => {

        try {
            const res = await service.get(`supplier_payments/${id}`);
            setPayments(res)

        } catch (error) {
            console.log(error)
        }
    }


    const handelPayment = async () => {
        setValidation([])
        try {
            const res = await service.post('supplier_payments', {
                invoice_id: id,
                data: paymentForm

            })

            get_invoice_info()
        } catch (error) {
            if (error.validationData) {
                setValidation(error.validationData)
            }
        }
    }


    const set_supplisers = () => {

        const uniqueSupplierIds = new Set();

        console.log('products', products)
        const uniqueSupplierData = products
            .map(item => item.supplier)
            .filter(supplier => {
                if (!uniqueSupplierIds.has(supplier.id)) {
                    uniqueSupplierIds.add(supplier.id);
                    return true;
                }
                return false;
            });

        // setSuppliers(uniqueSupplierData);
    };

    // This assumes you have the 'products' array defined elsewhere.



    useEffect(() => {
        get_supplier_payments()
        get_invoice_info()
    }, [])


    useEffect(() => {
        set_supplisers()
    }, [products])

    return (
        <>
            <div className='m-4 shadow rounded overflow-hidden p-4'>
                <div>
                    <table class="table table-bordered">

                        <tbody>
                            <tr>
                                <td>
                                    رقم عرض السعر
                                </td>
                                <td>
                                    {invoice?.rfq?.rfq_number ?? 0}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    رقم الفاتورة
                                </td>
                                <td>
                                    {invoice.id}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    المجموع الكلى</td>
                                <td>
                                    {invoice.calculate_total}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    المدفوع</td>
                                <td>
                                    {invoice.calculate_total_paid}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    المتبقى</td>
                                <td>
                                    {invoice.calculate_total - invoice.calculate_total_paid}
                                </td>
                            </tr>
                            <tr>
                                <td>الحاله</td>
                                <td>
                                    <span className='badge '>{invoice.status}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    التاريخ
                                </td>
                                <td>
                                    {new Date(invoice.created_at).toLocaleString()}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>


                <div>
                    <table class="table table-bordered  ">
                        <thead class="thead-inverse">
                            <tr>
                                <th>اسم المنتج</th>
                                <th>السعر</th>
                                <th>الكميه</th>
                                <th>السعر</th>
                                <th>الكميه المستلمه</th>
                                <th>المورد</th>
                            </tr>
                        </thead>
                        <tbody>

                            {products && products.map((item, index) => (
                                <tr key={index}>
                                    <td>{item?.product?.name}</td>
                                    <td>{item.price}</td>
                                    <td>{item.qty}</td>
                                    <td>{item.qty * item.price}</td>
                                    <td>{item.stock_qty ?? 0}</td>
                                    <td>{item?.supplier?.name}</td>
                                </tr>
                            ))}

                        </tbody>
                    </table>
                </div>


            </div>

            <div className='p-3  shadow rounded m-4'>
                <Badge onClick={() => setTab('payments')} text='المدفوعات' color={'green'} active={tab === 'payments'} />
                <Badge text='المدفوعات' color='green' />
                <Badge text='المدفوعات' color='green' />
            </div>

            <div className='p-3  shadow rounded m-4'>

                <table class="table table-bordered  ">
                    <thead class="thead-inverse">
                        <tr>
                            <th>المبلغ</th>
                            <th>المورد</th>
                            <th>الموظف</th>
                            <th>التاريخ</th>
                        </tr>
                    </thead>
                    <tbody>

                        {payments && payments.map((item, index) => (
                            <tr>
                                <td>{item.amount}</td>
                                <td>{item.supplier?.name}</td>
                                <td>{item.user?.name}</td>
                                <td>{new Date(item.created_at).toLocaleString()}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className='p-3  shadow rounded m-4'>

                <table class="table table-bordered  ">
                    <thead class="thead-inverse">
                        <tr>
                            <th>المورد</th>
                            <th>المجموع</th>
                            <th>المدفوع</th>
                            <th>المتبقى</th>
                        </tr>
                    </thead>
                    <tbody>

                        {suppliers && suppliers.map((supplier, index) => (
                            <tr>
                                <td>{supplier.name}</td>
                                <td>{supplier.total}</td>
                                <td>{supplier.payed}</td>
                                <td>{supplier.total - supplier.payed}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <div className='p-3  shadow rounded m-4'>
                <div className='row'>
                    <SelectFormGroup
                        value={paymentForm.supplier_id}
                        onChange={(e) => setPaymentForm({ ...paymentForm, supplier_id: e.target.value })}
                        options={suppliers}

                        error={validation['data.supplier_id']?.[0]}
                        col={6}
                    />



                    <InputFormGroup
                        type='number'
                        value={paymentForm.amount}
                        onChange={(e) => setPaymentForm({ ...paymentForm, amount: e.target.value })}
                        col={6}
                        error={validation['data.amount']?.[0]}

                    />
                    <div className='form-group col-md-6 mt-3'>
                        <button onClick={handelPayment} className='btn btn-success'>
                            دفع
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default InvoiceShow