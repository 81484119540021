

import React, { useEffect, useState } from 'react'
import GeneralServices from '../../services/GeneralServices';
import { Link } from 'react-router-dom';


function SupplierInvoice() {


    const [invoices, setInvoices] = useState();
    const service = new GeneralServices;

    const get_invoices = async () => {
        try {
            const res = await service.get('invoice_list', { supplier_id: 1 });
            setInvoices(res)
            console.log('res', res)
        } catch (error) {
            console.log('error', error)
        }
    }
    useEffect(() => {
        get_invoices()
    }, [])

    return (
        <div className='m-4 shadow rounded overflow-hidden'>
            <div className='p-2 bg-gray-700'>
                <h1 className='text-white font-medium'>الفواتير النهائيه</h1>
            </div>
            <div className='p-3'>
                <table class="table table-bordered">
                    <thead class="thead-inverse">
                        <tr>
                            <th>رقم الفاتورة</th>
                            <th>رقم عرض السعر</th>
                            <th>الحاله</th>
                            <th>التاريخ</th>
                            <th>اعدادات</th>
                        </tr>
                    </thead>
                    <tbody>
                        {invoices && invoices.map((item, index) => (

                            <tr key={item.id}>
                                <td>{item.id}</td>
                                <td>{item.rfq_id}</td>
                                <td>{item.status}</td>
                                <td>{new Date(item.created_at).toLocaleDateString('en-GB')}</td>


                                <td>
                                    <Link className='badge badge-primary success' to={`/supplier_invoice/${item.id}`}>
                                        <span>عرض</span>
                                    </Link>
                                </td>
                            </tr>
                        ))}

                    </tbody>
                </table>
            </div>


        </div>
    )
}

export default SupplierInvoice