import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import GeneralServices from '../../services/GeneralServices'

function ClientShow() {
    const { id } = useParams()

    const [client, setClient] = useState({})
    const [type, setType] = useState(null)
    const [loading, setLoading] = useState(true)

    const service = new GeneralServices;

    const get_client = async () => {
        setLoading(true)
        try {
            const res = await service.get(`client/${id}`)
            setClient(res)
            setType(res.type)
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        get_client()
    }, [])



    const clientTablesType = {
        individual: <Individual data={client} />,
        private: <Private data={client} />,
        government: <Government data={client} />
    }
    if (loading) return null;
    return (
        <div className=' shadow rounded m-4 overflow-hidden'>
            <div className='p-3 bg-gray-700 text-white'>
                <h1>بيانات العميل</h1>
            </div>
            <div className='p-2'>
                {clientTablesType[type]}
            </div>
        </div>
    )
}

const Individual = ({ data }) => {

    const { first_name, second_name, third_name, last_name, id_number, whatsapp, phone2 } = data.data
    return <div>
        <table class="table table-bordered">
            <thead class="thead-inverse">

            </thead>
            <tbody>
                <tr>
                    <td>الاسم </td>
                    <td>
                        {` ${first_name}  ${second_name}  ${third_name}  ${last_name}`}
                    </td>

                </tr>
                <tr>
                    <td>الهاتف </td>
                    <td>
                        {` ${phone2}  `}
                    </td>
                </tr>
                <tr>
                    <td>واتس اب </td>
                    <td>
                        {` ${whatsapp}  `}
                    </td>
                </tr>
                <tr>
                    <td>الهويه </td>
                    <td>
                        {` ${id_number}  `}
                    </td>
                </tr>
            </tbody>
        </table>

    </div>
}

const Private = ({ data }) => {

    const { commercial_register, tax_number, id_number, name, owner_name, phone, whatsapp } = data.data

    return <div>
        <table class="table table-bordered">
            <thead class="thead-inverse">

            </thead>
            <tbody>
                <tr>
                    <td>الاسم </td>
                    <td>
                        {name}
                    </td>

                </tr>
                <tr>
                    <td>المالك </td>
                    <td>
                        {owner_name}
                    </td>

                </tr>
                <tr>
                    <td>الهاتف </td>
                    <td>
                        {phone}
                    </td>

                </tr>
                <tr>
                    <td>واتس </td>
                    <td>
                        {whatsapp}
                    </td>

                </tr>
                <tr>
                    <td>الرقم الضريبيى </td>
                    <td>
                        {` ${tax_number}  `}
                    </td>
                </tr>
                <tr>
                    <td>السجل </td>
                    <td>
                        {` ${commercial_register}  `}
                    </td>
                </tr>
                <tr>
                    <td>الهويه </td>
                    <td>
                        {` ${id_number}  `}
                    </td>
                </tr>
            </tbody>
        </table>

    </div>
}

const Government = ({ data }) => {

    const { name,
        id_number,
        phone,
        whatsapp,
        how_did_you_get_to_us,
        phone2 } = data.data

    return <div>
        <table class="table table-bordered">
            <thead class="thead-inverse">

            </thead>
            <tbody>
                <tr>
                    <td>الاسم </td>
                    <td>
                        {name}
                    </td>

                </tr>
                <tr>
                    <td>الهويه </td>
                    <td>
                        {id_number}
                    </td>

                </tr>
                <tr>
                    <td>الهاتف </td>
                    <td>
                        {phone}
                    </td>

                </tr>
                <tr>
                    <td>واتس </td>
                    <td>
                        {whatsapp}
                    </td>

                </tr>

            </tbody>
        </table>

    </div>
}


export default ClientShow