import React, { useEffect, useState } from 'react'

import GeneralServices from '../../services/GeneralServices';


import FinancialSection from './components/FinancialSection';
import ExternalDoorSpecifications from './components/ExternalDoorSpecifications';

import ElevatorData from './components/ElevatorData';
import Technicians from './components/Technicians';
import Address from './components/Address';
import { showErrorToast, showLoadingToast, showSuccessToast } from '../../utils/toastUtils';
import { useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import ClientEdit from './components/ClientEdit';
import Client from './components/Client';

function ContractEdit() {

    const { id } = useParams();


    const [contract, setContract] = useState({})
    const [validation, setValidation] = useState([])
    const [Loading, setLoading] = useState(true)

    const [data, setData] = useState({})

    const navigate = useNavigate();

    const service = new GeneralServices;

    const handelSaveData = async () => {
        const t = showLoadingToast('جاري انشاء العقد')

        setLoading(true)
        try {
            const res = await service.post('contract/update', {
                contract, id
            })
            showSuccessToast('مبروك انشاء العقد بنجاح')
            // navigate('/contracts'); // Go to contracts route

        } catch (error) {

            if (error.validationData) {
                showErrorToast('تاكيد من انك ملئت جميع الحقول المطلوبة')
                setValidation(error.validationData)
                console.log('first', error.validationData)
            }
        }
        finally {
            setLoading(false)
        }
        toast.dismiss(t);
    }




    const get_contract = async () => {

        try {
            const res = await service.get(`contract/${id}`)
            // setContract(res)
            setData(res)
            set_clint(res.client)
            // console.log('get_contract', res.client)
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }

    const set_clint = (clint) => {

        setContract({ ...contract, client: clint })
        if (clint.type == 'individual') {

        }
    }

    useEffect(() => {
        get_contract()
        // setContract({
        //     ...contract, elevator: {
        //         floor: 10,
        //     }
        // })
    }, [])



    if (Loading) {
        return <h1>Loading</h1>
    }

    return (
        <div className='rounded-sm shadow-lg m-4'>
            {JSON.stringify(contract.client, null, 2)}
            <Client contract={contract} validation={validation} setContract={setContract} />
            <Address addressData={data} contract={contract} setContract={setContract} />
            <ElevatorData contract={contract} setContract={setContract} />
            <ExternalDoorSpecifications contract={contract} setContract={setContract} />
            <FinancialSection contract={contract} setContract={setContract} />

            <div className='p-2 bg-gray-100'>
                <button disabled={Loading} onClick={handelSaveData} className={`btn btn-md bg-green-500 text-white rounded-sm ${Loading && 'loading'}`}>تحديث</button>
            </div>
        </div>
    )
}




export default ContractEdit