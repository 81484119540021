import React, { useState } from 'react'
import { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import GeneralServices from '../../services/GeneralServices'

function supplierShow() {

    const { id } = useParams()

    const [supplier, setSupplier] = useState({})
    const [invoices, setInvoices] = useState([])
    const [payments, setPayments] = useState([])

    const [activeTab, setActiveTab] = useState('info')

    const tabs = {
        info: <Info id={id} />,
        invoices: <Invoices id={id} />,
        payments: <Payments id={id} />
    };




    return (
        <div className='my-4  rounded-lg overflow-hidden shadow'>
            <div className='bg-gray-700  p-2 flex justify-between items-center'>
                <h1 className='text-base text-white font-medium'>المورد</h1>
            </div>
            {/* tabs */}
            <div className='flex justify-between items-center border-b'>
                <div className='flex'>
                    <div className={`cursor-pointer p-2 border-b-4 border-transparent hover:border-gray-700 ${activeTab === "info" ? "bg-gray-400" : "border-transparent"}`}>
                        <a onClick={() => setActiveTab('info')}>معلومات المورد</a>
                    </div>
                    <div className={`cursor-pointer p-2 border-b-4 border-transparent hover:border-gray-700 ${activeTab === "invoices" ? "bg-gray-400" : "border-transparent"}`}>
                        <a onClick={() => setActiveTab('invoices')}>الفواتير</a>
                    </div>
                    <div className={`cursor-pointer p-2 border-b-4 border-transparent hover:border-gray-700 ${activeTab === "payments" ? "bg-gray-400" : "border-transparent"}`}>
                        <a onClick={() => setActiveTab('payments')}>المدفوعات</a>
                    </div>
                </div>

            </div>
            <div>
                {tabs[activeTab]}
            </div>
        </div>
    )
}


const Invoices = ({ id }) => {

    const [invoices, setInvoices] = useState([])

    const service = new GeneralServices

    const get_invoices = async () => {

        try {
            const res = await service.get(`suppliers/${id}/invoices`);
            setInvoices(res)

        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        get_invoices()
    }, [])


    return <div className='p-3'>
        <table class="table table-bordered  ">
            <thead class="thead-inverse">
                <tr>
                    <th>رقم الفاتورة</th>
                    <th>رقم عرض السعر</th>
                    <th>اعدادت</th>
                </tr>
            </thead>
            <tbody>
                {invoices.map((invoice, index) => (
                    <tr>
                        <td>{invoice?.id}</td>
                        <td>{invoice?.rfq?.rfq_number}</td>
                        <td>
                            <Link to={`/suppliers/${id}/invoices/${invoice.id}`}>
                                عرض
                            </Link>
                        </td>
                    </tr>
                ))}


            </tbody>
        </table>
    </div>
}

const Payments = ({ id }) => {
    const [payments, setPayments] = useState([])

    const service = new GeneralServices

    const get_supplier_payments = async () => {

        try {
            const res = await service.get(`suppliers/${id}/payments`);
            setPayments(res.payments)

        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        get_supplier_payments()
    }, [])


    return <div className='p-3'>
        <table class="table table-bordered  ">
            <thead class="thead-inverse">
                <tr>
                    <th>رقم الفاتورة</th>
                    <th>المبلغ</th>
                    <th>الموظف</th>
                    <th>التاريخ</th>
                </tr>
            </thead>
            <tbody>
                {payments.map((payment, index) => (
                    <tr>
                        <td>{payment?.invoice?.id}</td>
                        <td>{payment.amount}</td>
                        <td>{payment?.user?.name}</td>
                        <td>{payment.created_at}</td>
                    </tr>
                ))}

            </tbody>
        </table>
    </div>
}

const Info = ({ id }) => {

    const [supplier, setSupplier] = useState({})

    const service = new GeneralServices

    const get_supplier_payments = async () => {

        try {
            const res = await service.get(`suppliers/${id}`);
            setSupplier(res)

        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        get_supplier_payments()
    }, [])

    return <div>
        <div className='p-3'>
            <div className='flex justify-between items-center'>
                <h1 className='text-base text-gray-700 font-medium'>معلومات المورد</h1>

            </div>
            <div className='flex justify-between items-center'>
                <div className='flex justify-between'>
                    <div className='flex flex-col mx-1'>
                        <label className='text-sm text-gray-700'>الاسم</label>
                        <p className='text-xs text-gray-500'>{supplier.name}</p>
                    </div>
                    <div className='flex flex-col mx-1'>
                        <label className='text-sm text-gray-700'>البريد الالكتروني</label>
                        <p className='text-xs text-gray-500'>{supplier.email}</p>
                    </div>
                    <div className='flex flex-col mx-1'>
                        <label className='text-sm text-gray-700'>الهاتف</label>
                        <p className='text-xs text-gray-500'>{supplier.phone}</p>
                    </div>
                </div>

            </div>
        </div>
    </div>
}
export default supplierShow