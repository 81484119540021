import React, { useEffect, useState } from 'react'
import InputFormGroup from '../../../components/InputFormGroup'
import GeneralServices from '../../../services/GeneralServices'



/**
 * 
 * Component for the financial section of the application.
 *
 * @param {object} contract - The contract object.
 * @param {function} setContract - The function to set the contract object.
 * @return {JSX.Element} The financial section component.
 */
const FinancialSection = ({ contract, setContract, validation = [] }) => {

    const [tax, setTax] = useState('15')
    const [total, setTotal] = useState(0)
    const [installments, setInstallments] = useState([])

    const generalServices = new GeneralServices;

    const handelAddInstallment = () => {
        setInstallments([...installments, {
            total: 0,
            tax: 0,
            subtotal: 0
        }])
    }


    /**
     * Deletes an item from the installments array at the specified index.
     *
     * @param {number} index - The index of the item to delete.
     * @return {void} 
     */
    const handelDelete = (index) => {
        const newInstallments = installments.filter((item, i) => i !== index)
        setInstallments(newInstallments)
    }

    const onChangeTotal = (value, index) => {
        const newInstallments = installments.map((item, i) => {
            if (i === index) {
                item.total = value
                item.tax = value * (tax / 100)
                item.subtotal = parseFloat(item.total) + parseFloat(item.tax)
            }
            return item
        })
        setTotal(newInstallments.reduce((a, b) => a + parseFloat(b.subtotal), 0))
        setInstallments(newInstallments)
    }
    useEffect(() => {
        calculateTotal()

    }, [installments, tax])


    const fetchTax = async () => {
        try {
            const response = await generalServices.get('tax');
            setTax(response);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {

        const totals = installments.map(item => item.total);
        setContract({ ...contract, financial: { tax, totals } })
    }, [installments, tax])


    useEffect(() => {
        fetchTax()
        if (contract.installments) {
            const result = [];
            const totals = [];
            for (let i = 0; i < contract.installments.length; i++) {
                totals.push(parseFloat(contract.installments[i].amount));
                result.push({
                    tax: parseFloat(contract.installments[i].tax),
                    subtotal: parseFloat(contract.installments[i].amount),
                    total: parseFloat(contract.installments[i].amount) + parseFloat(contract.installments[i].tax),
                });
            }

            setInstallments(result);

            setContract({ ...contract, financial: { tax, totals } })
        }
    }, [])




    const calculateTotal = () => {
        let t = 0;
        const total = installments.map((item) => (
            t += parseFloat(item.total)
        ))
        setTotal(t)
    }



    return (
        <div className='mb-4'>

            <div className='bg-gray-700  p-2 flex justify-between items-center my-1'>
                <h1 className='text-base text-white font-medium'>البيانات المالية</h1>

                <img
                    src="https://img.icons8.com/glyph-neue/64/ffffff/plus-2-math.png"
                    alt="Add Installment"
                    onClick={handelAddInstallment}
                    className="w-8 h-8 cursor-pointer"
                />
            </div>

            <div className='row px-2'>
                <InputFormGroup
                    label='المجموع'
                    value={total}
                    col={4}
                    error={validation['financial.totals']}
                />

                <InputFormGroup
                    label='الضريبه'
                    value={tax}
                    disabled
                    col={4}
                />
                <InputFormGroup
                    label='المجموع + الضريبه'
                    value={total * (tax / 100) + total}
                    disabled
                    col={4}
                />
                {installments.map((item, index) => (
                    <div className='col-12 px-2'>
                        <div className='row items-center'>
                            <div className='col'>
                                <div className='row'>
                                    <InputFormGroup
                                        label='المجموع'
                                        value={item.total}
                                        onChange={(e) => onChangeTotal(e.target.value, index)}
                                        col={4}
                                    />
                                    <InputFormGroup
                                        label='فيمه الضريبه'
                                        value={tax * (item.total / 100)}
                                        disabled
                                        col={4}
                                    />
                                    <InputFormGroup
                                        label='المجموع الكلي'
                                        value={tax * (item.total / 100) + parseFloat(item.total)}
                                        disabled
                                        col={4}
                                    />
                                </div>
                            </div>
                            <img onClick={() => handelDelete(index)} class="h-10 cursor-pointer" src="https://img.icons8.com/color/48/delete-forever.png" alt="delete"></img>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default FinancialSection