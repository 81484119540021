import React from 'react'

import { useState } from 'react'
import GeneralServices from '../../services/GeneralServices'
import { useEffect } from 'react'
import ReactQuill from 'react-quill'
import SelectFormGroup from '../../components/SelectFormGroup'

import 'react-quill/dist/quill.snow.css'; // Import Quill's snow theme CSS
import 'react-quill/dist/quill.core.css'; // Import Quill's core CSS

function ContractTemplate() {

    const [contract, setContract] = useState(null)
    const [contractType, setContractType] = useState('template')

    const services = new GeneralServices

    const update = async () => {
        try {
            const response = await services.post(`settings/${contractType}`, { contract })
            // console.log('response', response)
        } catch (error) {
            console.log('error', error)
        }
    }


    const get_template = async () => {
        try {
            const response = await services.get(`settings/${contractType}`)
            console.log('response', response.data.contract)
            setContract(response.data.contract)
        } catch (error) {
            console.log('error', error)
        }
    }


    useEffect(() => {
        get_template()
    }, [contractType])

    const modules = {
        toolbar: [
            [{
                'align': [
                    'center',
                    'right',
                    'justify',
                ]
            }],
            [{ 'header': '1' }, { 'header': '2' }],
            // Include the default toolbar options
            ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['link', 'image'],
            [{ 'color': [] }, { 'background': [] }],
            ['clean'],
            ['table'],
            [{ 'indent': '-1' }, { 'indent': '+1' }],
        ],
    };


    return (
        <div className='p-4 rounded shadow m-4'>

            <div className='p-4 flex justify-between'>
                <h1 className='text-2xl'> اعدادات العقد </h1>
                <SelectFormGroup
                    value={contractType}
                    onChange={(e) => { setContractType(e.target.value) }}
                    options={[{ id: 'template', name: 'عادي' }, { id: 'template_2', name: 'اوتمتك' }]}
                />
            </div>
            <div className="scrollable-container" style={{ maxHeight: '300px', overflow: 'auto' }}>
                <ReactQuill
                    value={contract}
                    onChange={setContract}
                    theme="snow"

                    modules={modules}
                />
            </div>
            <div className='p-4'>
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th>المتغير</th>
                            <th>الوصف</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>FRIST_NAME	</td>
                            <td>اسم العميل</td>
                        </tr>
                        <tr>
                            <td>TABLE	</td>
                            <td>جدول الكميات</td>
                        </tr>
                        <tr>
                            <td>{'elevator_type'.toUpperCase()}	</td>
                            <td>نوع المصعد</td>
                        </tr>
                        <tr>
                            <td>{'payment'.toUpperCase()}	</td>
                            <td>جدول الدفعيات</td>
                        </tr>
                        <tr>
                            <td>{'machine_speed'.toUpperCase()}	</td>
                            <td>سرعه المصعد</td>
                        </tr>
                        <tr>
                            <td>{'people_load'.toUpperCase()}	</td>
                            <td>حموله المصعد</td>
                        </tr>
                        <tr>
                            <td>{'control_card'.toUpperCase()}	</td>
                            <td>كارد كنترول</td>
                        </tr>
                        <tr>
                            <td>{'machine_warranty'.toUpperCase()}	</td>
                            <td>اضافات اخرى</td>
                        </tr>
                        <tr>
                            <td>{'other'.toUpperCase()}	</td>
                            <td>اضافات اخرى</td>
                        </tr>
                        <tr>
                            <td>CARD_NMUMBER	</td>
                            <td>رقم بطاقه العميل</td>
                        </tr>

                    </tbody>
                </table>
            </div>

            <div class="form-group col mt-3 flex">

                <button onClick={update} className='bg-blue-500 mx-1 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded justify-center items-center flex' >
                    <span>تحديث</span>
                </button>
                <button onClick={get_template} className='bg-blue-500 mx-1 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded justify-center items-center flex' >
                    <span>اعاده تحميل</span>
                </button>
            </div>
        </div>
    )
}

export default ContractTemplate