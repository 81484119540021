import React from 'react';

function InputFormGroup({ label, value, onChange, placeholder, error, col = 6, type = "text", onLoad, onKeyDown, disabled = false, multiple = false, onBlur }) {
    return (
        <div className={"form-group col-md-" + col}>
            {label && <label htmlFor={label}>{label}</label>}
            <input
                type={type}
                className={`form-control ${error ? 'is-invalid' : ''}`}
                id={label}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                onLoad={onLoad}
                placeholder={placeholder}
                disabled={disabled}
                multiple={multiple}
            />
            {error && <small className="form-text text-danger">{error}</small>}

        </div>
    );
}

export default InputFormGroup;
