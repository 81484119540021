import React, { useEffect, useState } from 'react'
import GeneralServices from '../../../../services/GeneralServices'
import Badge from '../../../../components/Badge'
import { Link } from 'react-router-dom'
import Modal from '../../../../components/Modal'
import InputFormGroup from '../../../../components/InputFormGroup'
import SelectFormGroup from '../../../../components/SelectFormGroup'

function ProductsList() {
    const [products, setProducts] = useState([])
    const [productId, setProductId] = useState(null)

    const [show, setShow] = useState(false)


    const [AddProductModal, setAddProductModal] = useState(true)





    const service = new GeneralServices

    const get_products = async () => {
        try {
            const res = await service.get('stock/products')
            setProducts(res)
        } catch (error) {
            console.log('error', error)
        }
    }


    const handleshow = () => {
        setShow(!show)

    }



    useEffect(() => {
        get_products()

    }, [])


    return (
        <div className='m-4 rounded overflow-hidden shadow'>




            {
                show &&
                <Modal onClose={handleshow} title='تفاصيل المنتج'>
                    <AddProduc />
                </Modal>
            }
            <div className='p-4 text-white bg-gray-900 row justify-between items-center '>
                <h1>المنتجات</h1>
                <button onClick={() => handleshow()} className='btn btn-md text-white border'>اضافة منتج</button>
            </div>
            <div className='p-3'>
                <table class="table table-bordered table-inverse ">
                    <thead class="thead-inverse">
                        <tr>
                            <th>الاسم</th>
                            <th>الكميه</th>
                            <th>غير مستلمه</th>
                            <th>المرحله</th>
                            <th>المصعد</th>
                            <th>التفاصيل</th>

                        </tr>
                    </thead>
                    <tbody>
                        {products && products.map((product, index) =>
                            <tr>
                                <td>{product.name}</td>
                                <td>{product.in_stock}</td>
                                <td>{product.not_received}</td>
                                <td>
                                    <Badge text={product?.ths_stage?.name} color={product?.ths_stage?.id === 1
                                        ? 'red'
                                        : product?.ths_stage?.id === 2
                                            ? 'green'
                                            : 'blue'} />

                                </td>
                                <td><Badge text={product?.elevator_type?.name} /></td>
                                <td>
                                    <Link to={`/stocks/products/${product.id}`}>
                                        <Badge text='تفاصيل' color='blue' />
                                    </Link>
                                </td>
                            </tr>
                        )}

                    </tbody>
                </table>
            </div>
        </div >
    )
}

const AddProduc = () => {

    const service = new GeneralServices;

    const [product, setProduct] = useState({
        'elevator_types_id': 1,
        'name': '',
        'stage': 1,
    });
    const [elevator, setElevator] = useState([]);
    const [validation, setValidation] = useState([]);

    const fetchEevators = async () => {
        try {
            const res = await service.get('elevator_data')
            setElevator(res.elevator_types)
            setProduct({ ...product, elevator_types_id: res.elevator_types[0].id })
        } catch (error) {

        }
    }


    const addProduct = async () => {
        setValidation([])
        try {
            const res = await service.post('products/create', product)

            console.log('res', res)
        } catch (error) {
            if (error.validationData) {
                setValidation(error.validationData)
            }
        }
    }


    useEffect(() => {
        fetchEevators()

    }, [])


    return <div>
        <SelectFormGroup
            label='المرحله'
            value={product.stage}
            onChange={(e) => setProduct({ ...product, stage: e.target.value })}
            options={[
                { id: 1, name: 'المرحله الاولي' },
                { id: 2, name: 'المرحله الثانيه' },
                { id: 3, name: 'المرحله الثالثة' },
            ]}
            error={validation?.stage?.[0]}
            col={12}
        />
        <SelectFormGroup
            label='نوع المصعد'
            value={product.elevator_types_id}
            onChange={(e) => setProduct({ ...product, elevator_types_id: e.target.value })}
            options={elevator}

            error={validation?.elevator_types_id?.[0]}
            col={12}
        />

        <InputFormGroup
            label='اسم المنتج'

            value={product.name}
            onChange={(e) => setProduct({ ...product, name: e.target.value })}

            error={validation?.name?.[0]}
            col={12}
        />

        <hr className='my-2' />

        <div class="form-group col">
            <button onClick={addProduct} className='btn btn-info btn-md'>اضافة</button>
        </div>
    </div>
}

export default ProductsList