import React, { useEffect, useState } from 'react'
import GeneralServices from '../../services/GeneralServices';
import SelectFormGroup from '../../components/SelectFormGroup';
import { Link } from 'react-router-dom';

function ClientsList() {

    const [clients, setClients] = useState([])
    const [type, setType] = useState('individual')

    const generalServices = new GeneralServices;


    const handelGetData = async () => {
        try {
            const res = await generalServices.get('clients/' + type)
            setClients(res)
        } catch (error) {
            console.log('error', error)
        }
    }


    useEffect(() => {
        handelGetData()
    }, [type])


    const clientTablesType = {
        individual: <IndividualTable data={clients} />,
        private: <PrivateTable data={clients} />,
        government: <GovernmentTable data={clients} />
    }

    return (
        <div className='my-4  rounded-lg overflow-hidden'>
            <div className='bg-gray-700  p-2 flex justify-between items-center'>
                <h1 className='text-base text-white font-medium'>العملاء</h1>
                <SelectFormGroup
                    name='type'
                    value={type}
                    onChange={e => setType(e.target.value)}
                    options={[
                        { id: 'individual', name: 'افراد' },
                        { id: 'private', name: 'قطاع خاص' },
                        { id: 'government', name: 'قطاع حكومي' },
                    ]}
                    col={3}
                />
            </div>
            {clientTablesType[type]}

        </div>
    )
}

const IndividualTable = ({ data }) => {
    return <div>
        <div>
            <table class="table table-bordered  ">
                <thead class="thead-inverse">
                    <tr>
                        <th>الاسم ثلاثى</th>
                        <th>الهويه</th>
                        <th>رقم الهاتف</th>
                        <th>واتس اب</th>
                        <th>أعدادات</th>
                    </tr>
                </thead>
                <tbody>

                    {data.map((client, index) => {
                        return <tr key={index}>
                            <td>
                                <span className='text-md text-gray-500'> {client.data.first_name}</span>
                                <span className='text-md text-gray-500'> {client.data.second_name}</span>
                                <span className='text-md text-gray-500'> {client.data.third_name}</span>
                                <span className='text-md text-gray-500'> {client.data.last_name}</span>

                            </td>
                            <td>
                                <span className='text-md text-gray-500'> {client.data.id_number}</span>

                            </td>
                            <td>
                                <span className='text-md text-gray-500'> {client.data.phone}</span>
                                <br />
                                <span className='text-md text-gray-500'> {client.data.phone2}</span>

                            </td>
                            <td>
                                <span className='text-md text-gray-500'> {client.data.whatsapp}</span>

                            </td>
                            <td>

                                {/* show and edit */}
                                <Link to={`/clients/${client.id}/show`} class="badge badge-success mx-1">عرض</Link>
                                <Link to={`/clients/${client.id}/edit`} class="badge badge-success mx-1">تعديل</Link>

                            </td>
                        </tr>

                    })}
                </tbody>
            </table>
        </div>
    </div>
}
const PrivateTable = ({ data }) => {
    return <div>
        <table class="table table-bordered">
            <thead class="thead-inverse">
                <tr>
                    <th>الاسم</th>
                    <th>الشخص المسؤول</th>
                    <th>السجل</th>
                    <th>الهاتف</th>
                    <th>واتس</th>
                    <th>الرقم الضريبى</th>
                    <th>اعدادات</th>
                </tr>
            </thead>
            <tbody>
                {data && data.map((item, index) => (

                    <tr>
                        <td>{item.data.name}</td>
                        <td>{item.data.owner_name}</td>
                        <td>{item.data.commercial_register}</td>
                        <td>{item.data.phone} - {item.data.phone2}</td>
                        <td>{item.data.whatsapp}</td>
                        <td>{item.data.tax_number}</td>
                        <td>

                            {/* show and edit */}
                            <Link to={`/clients/${item.id}/show`} class="badge badge-success mx-1">عرض</Link>
                            <Link to={`/clients/${item.id}/edit`} class="badge badge-success mx-1">تعديل</Link>

                        </td>
                    </tr>
                ))}

            </tbody>
        </table>
    </div>
}
const GovernmentTable = ({ data }) => {
    return <div>


        <table class="table table-bordered">
            <thead class="thead-inverse">
                <tr>
                    <th>الجهه</th>
                    <th>رقم الهويه</th>
                    <th>الهاتف</th>
                    <th>واتس</th>
                    <th>اعدادات</th>
                </tr>
            </thead>
            <tbody>
                {data && data.map((item, index) => (

                    <tr>
                        <td>{item.data.name}</td>
                        <td>{item.data.id_number}</td>
                        <td>{item.data.phone} - {item.data.phone2}</td>
                        <td>{item.data.whatsapp}</td>
                        <td>

                            {/* show and edit */}
                            <Link to={`/clients/${item.id}/show`} class="badge badge-success mx-1">عرض</Link>
                            <Link to={`/clients/${item.id}/edit`} class="badge badge-success mx-1">تعديل</Link>

                        </td>
                    </tr>
                ))}

            </tbody>
        </table>
    </div>
}

export default ClientsList