import React, { useEffect, useState } from 'react'
import Individual from './Customer/Individual'
import Private from './Customer/Private'
import Government from './Customer/Government'
import SelectFormGroup from '../../../components/SelectFormGroup'

const Client = ({ contract, setContract, validation = [] }) => {

    // const { data: clientData = {}, type: clienType = '' } = contract.client ?? { type: 'individual' };

    const [type, setType] = useState('individual')
    const [data, setData] = useState({})

    const clientType = {
        individual: <Individual data={data} validation={validation} setData={setData} />,
        private: <Private data={data} validation={validation} setData={setData} />,
        government: <Government data={data} validation={validation} setData={setData} />
    }

    useEffect(() => {
        data.type = type
        setContract({ ...contract, client: data })
    }, [data])


    return (
        <div className='my-4'>
            <div>
                <div className='bg-gray-700  p-2 flex justify-between items-center my-2'>
                    <h1 className='text-base text-white font-medium'>العملاء</h1>
                    <SelectFormGroup
                        value={type}
                        onChange={(e) => { setType(e.target.value) }}
                        options={[
                            { id: 'individual', name: 'فرد' },
                            { id: 'private', name: 'قطاع خاص' },
                            { id: 'government', name: 'قطاع حكومي' },

                        ]}
                        col={3}
                    />
                </div>
                <div>
                    {clientType[type]}
                </div>
            </div>

        </div>
    )
}

export default Client