import React, { useContext, useEffect, useState } from 'react'
import MainHeader from '../components/MainHeader'
import { Navigate, Outlet, useNavigate } from 'react-router-dom'
import Sidebar from '../components/Sidebar'
import { AuthContext } from '../contexts/AuthContextProvider'
import Loader from '../components/Loader'

function DashboardLayout() {


    const [loading, setLoading] = useState(false)

    const { user, setUser } = useContext(AuthContext);


    const navigate = useNavigate();

    const data = localStorage.getItem('user');

    useEffect(() => {

        const data = localStorage.getItem('user');
        if (!data || data === 'undefined' || data === 'null') {

            setUser(null);
            navigate('/login');
        }
        if (user?.user?.level !== 'admin') {
            // navigate(user?.user?.level);
        }
        // switch (user?.user?.level) {
        //     case 'admin':
        //         navigate('/');
        //         break;
        //     case 'supplier':
        //         navigate('/supplier');
        //         break;
        //     case 'technician':
        //         navigate('/installations');
        //         break;
        //     default:
        //         navigate('/login');
        // }
        setLoading(false);
    }, [user]);




    if (!data) {
        return <Navigate to="/login" />
    }

    if (loading) return <div className='bg-blue-200 h-screen justify-center items-center flex'>
        <Loader />
    </div>;

    return (
        <div className="wrapper">
            <MainHeader />
            <Sidebar />

            <div className="content-wrapper" style={{ minHeight: "2175.5px" }}>
                <div className="content">
                    <div className="container-fluid">
                        <Outlet />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DashboardLayout