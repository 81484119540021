import React, { useEffect, useState } from 'react'
import GeneralServices from '../../../../services/GeneralServices'
import { useParams } from 'react-router-dom'
import Loader from '../../../../components/Loader'
import Badge from '../../../../components/Badge'
import { showErrorToast, showSuccessToast } from '../../../../utils/toastUtils'
import SelectFormGroup from '../../../../components/SelectFormGroup'

function OrderDetails() {


    const { id } = useParams()


    const [products, setProducts] = React.useState([])
    const [employees, setEmployees] = useState([])

    const [employeeId, setEmployeeId] = useState(null)



    const [notFoundProducts, setNotFoundProducts] = React.useState(false)
    const [dispatch, setDispatch] = React.useState(false)
    const [loading, setLoading] = React.useState(true)

    const service = new GeneralServices()

    const get_order = async () => {
        setNotFoundProducts(false)
        setDispatch(false)
        try {
            const res = await service.get(`stock/work_orders_products/${id}`)
            setProducts(res)
            console.log('res', res)
        } catch (error) {
            console.log('error', error)
        } finally {
            setLoading(false)
        }
    }



    const get_employees = async () => {
        try {
            const res = await service.get(`stock/work_order_employees/${id}`)
            setEmployees(res)
            setEmployeeId(res[0].id)
        } catch (error) {
            console.log('error', error)
        }
    }

    const dispatchProducts = async () => {
        try {
            const res = await service.post(`stock/work_orders_products/${id}/dispatch`, {
                employee_id: employeeId,
                products: products,

            })
            showSuccessToast('تم تسليم المنتجات بنجاح')
            setProducts(res)
        } catch (error) {
            showErrorToast('عليك صرف منتج واحد على لااقل')
        }
    }


    useEffect(() => {
        get_order()
        get_employees()


    }, [])


    if (loading) return <div>
        <Loader />
    </div>
    return (
        <div className='m-4 rounded shadow overflow-auto'>
            <div className='p-3 bg-gray-900 text-white row items-center justify-between'>
                <h1>تفاصيل المنتجات</h1>
                <SelectFormGroup
                    onChange={(e) => setEmployeeId(e.target.value)}
                    options={employees}
                    col={3}
                />
            </div>
            {notFoundProducts ? (
                <div className='p-3'>
                    <h1 className='text-2xl border p-2 rounded-full justify-center flex'> 🥲🥲😓 لاتوجد فاتورة لهذا العقد بعد</h1>
                </div>
            ) :
                <div className='p-2'>
                    <table class="table table-bordered" id="myTable">
                        <thead class="thead-inverse">
                            <tr>
                                <th>اسم المنتج</th>
                                <th>الكميه الكليه</th>
                                <th>الكميه الستلمه</th>
                                <th>الكميه المتبقيه</th>
                                <th>الكميه المتبقيه</th>
                            </tr>
                        </thead>
                        <tbody>
                            {products && products.map((product, index) => {
                                return (
                                    <tr>
                                        <td>{product.name}</td>
                                        <td>{product.quantity}</td>
                                        <td>{product.received}</td>
                                        <td>{product.quantity - product.received}</td>
                                        <td>
                                            <input
                                                type="number"
                                                className="form-control col-3"
                                                value={product.qty}
                                                onChange={(e) => {
                                                    product.qty = e.target.value
                                                    setProducts([...products])
                                                }}
                                            />
                                        </td>
                                    </tr>
                                )
                            })}

                        </tbody>
                    </table>
                    <div>
                        <button className='btn btn-success' onClick={dispatchProducts}>صرف البضاعه</button>
                    </div>
                </div>
            }
        </div >
    )
}

export default OrderDetails