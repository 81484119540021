import React from 'react'
import { useState } from 'react';
import InputFormGroup from '../../components/InputFormGroup';
import { useEffect } from 'react';
import GeneralServices from '../../services/GeneralServices';
import { showSuccessToast } from '../../utils/toastUtils';
import SelectFormGroup from '../../components/SelectFormGroup';
import Badge from '../../components/Badge';
import Modal from '../../components/Modal';

function Settings() {

    const [currentTab, setCurrentTab] = useState('tax');


    const Tabs = {
        tax: <Tax />,
        elevator: <Elevator />,
        cities: <Cities />,
        regions: <Regions />,
        required_percentage: <RequiredPercentage />,
        industries: <Industries />,
    }

    return (
        <div className='m-4 shadow-md bg-white rounded-md overflow-hidden'>
            <div className='flex bg-gray-700 p-1'>
                <p onClick={() => setCurrentTab('tax')} className={`${currentTab == 'tax' ? 'bg-blue-500' : null}  cursor-pointer rounded-sm text-white p-2 m-1`}>الضريبه</p>
                <p onClick={() => setCurrentTab('elevator_types')} className={`${currentTab == 'elevator_types' ? 'bg-blue-500' : null}  cursor-pointer rounded-sm text-white p-2 m-1`}> المصعد</p>
                <p onClick={() => setCurrentTab('elevator')} className={`${currentTab == 'elevator' ? 'bg-blue-500' : null}  cursor-pointer rounded-sm text-white p-2 m-1`}>بيانات المصعد</p>
                <p onClick={() => setCurrentTab('cities')} className={`${currentTab == 'cities' ? 'bg-blue-500' : null}  cursor-pointer rounded-sm text-white p-2 m-1`}>المدن</p>
                <p onClick={() => setCurrentTab('regions')} className={`${currentTab == 'regions' ? 'bg-blue-500' : null}  cursor-pointer rounded-sm text-white p-2 m-1`}>المناطق</p>
                <p onClick={() => setCurrentTab('required_percentage')} className={`${currentTab == 'required_percentage' ? 'bg-blue-500' : null}  cursor-pointer rounded-sm text-white p-2 m-1`}>نسب المراحل</p>
                <p onClick={() => setCurrentTab('industries')} className={`${currentTab == 'industries' ? 'bg-blue-500' : null}  cursor-pointer rounded-sm text-white p-2 m-1`}>الصناعات</p>
            </div>
            <div>
                {Tabs[currentTab]}
            </div>
        </div>
    )
}


function Tax() {

    const [tax, setTax] = useState({
        'rate': 0,
        'name': ''
    });
    const generalServices = new GeneralServices;

    const fetchTax = async () => {
        try {
            const response = await generalServices.get('settings/tax')

            setTax({
                'rate': response.data.rate,
                'name': response.data.name
            })
        } catch (error) {

        }
    }


    useEffect(() => {
        fetchTax()
    }, [])

    const handelUpdate = async () => {
        try {
            const response = await generalServices.post('/settings/tax', tax)
            showSuccessToast('تم تحديث الضريبه بنجاح')
        } catch (error) {

        }
    }


    return (<div className='m-4'>
        <div className='flex'>
            <InputFormGroup
                label='الاسم'
                value={tax.name}
                onChange={(e) => setTax({ ...tax, name: e.target.value })}
            />
            <InputFormGroup
                label='الضريبة'
                value={tax.rate}
                onChange={(e) => setTax({ ...tax, rate: e.target.value })}
            />
        </div>
        <div class="form-group my-3">
            <button onClick={handelUpdate} className="btn btn-md bg-green-500 text-white" >
                تحديث
            </button>
        </div>
    </div>);
}

const Elevator = () => {

    const [types, setTypes] = useState(
        [{ "id": "elevator_types", "name": "المصاعد" },
        { "id": "stops_numbers", "name": "عدد الوقفات" },
        { "id": "elevator_trips", "name": "رحله المصعد" },
        { "id": "cabin_rails_sizes", "name": "مقاس سكك المصعد" },
        { "id": "machine_loads", "name": "حموله المكينه" },
        { "id": "people_loads", "name": "حموله الاشخاص" },
        { "id": "control_cards", "name": "الكنترول" },
        { "id": "entrances_numbers", "name": "عدد المداخل" },
        { "id": "outer_door_directions", "name": "اتجاه فتح الباب" },
        { "id": "inner_door_types", "name": "نوع الباب الداخلى" },
        { "id": "door_sizes", "name": "مقاس فتحه الباب" },
        { "id": "elevator_rooms", "name": "غرفه المصعد" },
        { "id": "machine_types", "name": "نوع المكينه" },
        { "id": "machine_speeds", "name": "سرعه المكينه" },
        { "id": "elevator_warranties", "name": "ضمان المصعد" },
        { "id": "counterweight_rails_sizes", "name": "مقاس سكك المصعد" },
        { "id": "floors", "name": "الطوابق" },
        { "id": "additions", "name": "اضافات اخرى" },

        ]
    );

    const [currentType, setCurrentType] = useState(types[0]);

    const [data, setData] = useState([]);
    const [updatedItem, setUpdatedItem] = useState({});

    const [formData, setFormData] = useState({
        name: '',
    })

    const generalServices = new GeneralServices;

    const get_types_list = async () => {
        try {
            const response = await generalServices.get('get_elevator_data/' + currentType)
            setData(response)

        } catch (error) {

        }

    }

    const create_type = async () => {
        try {
            const response = await generalServices.post('create_elevator_data/' + currentType, formData)
            get_types_list();
        } catch (error) {
            console.log('error', error)

        }
    }

    const update = async () => {

        try {
            const res = await generalServices.post('update_elevator_data', { updatedItem, currentType })
            get_types_list();
            setUpdatedItem({})
        } catch (error) {

        }
    }

    useEffect(() => {
        get_types_list();

        setUpdatedItem({})
    }, [currentType])


    return <div className='p-4'>
        {JSON.stringify(updatedItem.lenght)}
        <div className='flex justify-between'>
            <div>
                <h1>الاعدادات</h1>
            </div>
            <SelectFormGroup
                value={currentType}
                onChange={(e) => setCurrentType(e.target.value)}
                options={types}
                col={3}
            />
        </div>
        <hr />
        <div className='row'>
            <div className='col'>
                <div className='bg-gray-100 my-3 py-2 flex items-end'>
                    <InputFormGroup

                        label='اسم'
                        value={formData.name}
                        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                        col={10}
                    />
                    <div class="form-group col my-2">
                        <button onClick={create_type} className="btn btn-md bg-green-500 text-white" >
                            حفظ
                        </button>
                    </div>
                </div>
            </div>

            <div className='col'>
                <div className='bg-gray-100 my-3 py-2 flex items-end'>
                    <InputFormGroup
                        disabled={!updatedItem?.name}
                        label='اسم'
                        value={updatedItem?.name}
                        onChange={(e) => setUpdatedItem({ ...updatedItem, name: e.target.value })}
                        col={10}
                    />
                    <div class="form-group col my-2">
                        <button onClick={update} className="btn btn-md bg-green-500 text-white" disabled={!updatedItem?.name}>
                            تعديل
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <hr className='my-3' />
        <table class="table table-bordered">
            <thead class="thead-inverse">
                <tr>
                    <th>الاسم</th>
                    <th>اعدادات</th>
                </tr>
            </thead>
            <tbody>
                {data.map((item, index) => {
                    return <tr key={index}>
                        <td>{item.name}</td>
                        <td>
                            <button onClick={() => setUpdatedItem(item)} className=' mx-1 btn-sm bg-blue-500 text-white'>تعديل</button>

                        </td>

                    </tr>
                })}
            </tbody>
        </table>
    </div >
}
function ElevatorTypes() {
    const [elevatorTypes, setElevatorTypes] = useState([]);

    const [name, setName] = useState('');

    const generalServices = new GeneralServices;

    const fetch_elevator_types = async () => {
        try {
            const response = await generalServices.get('elevator_types')
            setElevatorTypes(response)
            console.log('response', response)
        } catch (error) {
            console.log('error', error)
        }
    }

    const handelSave = async () => {

        try {
            const response = await generalServices.post('elevator_types', { name })
            setName('')
            fetch_elevator_types()
            showSuccessToast('تم اضافة نوع المصعد بنجاح')
        } catch (error) {
            console.log('error', error)
        }
    }

    useEffect(() => {
        fetch_elevator_types();
    })

    return <div>


        <div className='my-3'>
            <div className='flex items-end'>
                <InputFormGroup
                    label='الاسم'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    col={11}
                />
                <div class="form-group">
                    <button onClick={handelSave} className="btn btn-md bg-green-500 text-white" >
                        حفظ
                    </button>
                </div>
            </div>
        </div>


        <table class="table table-bordered  ">
            <thead class="thead-inverse">
                <tr>
                    <th>النوع</th>
                    <th>حذف</th>
                </tr>
            </thead>
            <tbody>
                {elevatorTypes.map((type, index) =>
                (
                    <tr key={index}>
                        <td>{type.name}</td>
                        <td>
                            <button className=' mx-1 btn-sm bg-red-500 text-white'>حذف</button>
                            {/* edit */}
                            <button className=' mx-1 btn-sm bg-blue-500 text-white'>تعديل</button>
                        </td>
                    </tr>
                ))}


            </tbody>
        </table>
    </div>
}

function Cities() {

    const [regions, setRegions] = useState([]);
    const [regionId, setRegionId] = useState(0);
    const [cities, setCities] = useState([]);

    const [data, setData] = useState(null);

    const [show, setShow] = useState(false);


    const [validation, setValidation] = useState([]);

    const service = new GeneralServices;



    const fetchRegions = async () => {
        try {
            const response = await service.get('regions')
            setRegions(response)
            setCities(response[0].cities)
        } catch (error) {
            console.log('error', error)
        }
    }


    const fetchCities = async () => {

        regions.filter((region) => {
            if (region.id == regionId) {
                setCities(region.cities)
            }
        })
    }

    const editCity = async () => {

        try {
            const response = await service.post('cities', data)
            setRegions(response)
            setCities(response[0].cities)
            setShow(false)
            setData(null)
        } catch (error) {
            if (error.validationData) {
                setValidation(error.validationData)
            }
        }
    }




    const setEdit = (id) => {
        setShow(true)
        cities.filter((city) => {
            if (city.id == id) {
                setData({ 'edit_name': city.name, 'id': city.id, 'region_id': city.region_id })
            }
        })
    }
    const addCity = async () => {

        try {
            const response = await service.post('cities', { ...data, region_id: regionId })
            setRegions(response)
            response.filter((region) => {
                if (region.id == regionId) {
                    setCities(region.cities)
                }
            })
            setShow(false)
            setData(null)
        } catch (error) {
            if (error.validationData) {
                setValidation(error.validationData)
            }
        }
    }


    const deleteCity = async (id) => {
        console.log('id', id)
        try {
            const response = await service.post('cities/delete', { id })
            setRegions(response)
            response.filter((region) => {
                if (region.id == regionId) {
                    setCities(region.cities)
                }
            })
        } catch (error) {

        }
    }

    useEffect(() => {
        fetchRegions()
    }, [])

    useEffect(() => {
        fetchCities()
    }, [regionId])

    return (<div>
        {show &&
            <Modal onClose={() => setShow(false)} title='تعديل مدينه'>
                <InputFormGroup
                    label='الاسم'
                    value={data?.edit_name}
                    onChange={(e) => setData({ ...data, edit_name: e.target.value })}
                    error={validation?.edit_name?.[0]}
                    col={12}
                />


                <div class="form-group mt-3">
                    <button onClick={editCity} className='btn btn-md btn-info mx-2'>
                        تعديل
                    </button>
                </div>
            </Modal>
        }

        <div className='row justify-between items-center p-2 px-4 bg-slate-400'>
            <InputFormGroup
                label='الاسم'
                value={data?.name}
                onChange={(e) => setData({ ...data, name: e.target.value })}
                error={validation?.name?.[0]}
            />
            <div className='col text-right  items-center mt-4'>
                <button onClick={addCity} className='btn btn-md btn-info mx-2'>
                    اضافة
                </button>
            </div>
        </div>
        <div className='row justify-between items-center p-2'>
            <h1>بيانات المدن </h1>
            <SelectFormGroup
                value={regionId}
                onChange={(e) => setRegionId(e.target.value)}
                options={regions}
                col={3}
            />
        </div>
        <div>
            <table class="table table-bordered">
                <thead class="thead-inverse">
                    <tr>
                        <th>الاسم</th>
                        <th>اعدادات</th>
                    </tr>
                </thead>
                <tbody>
                    {cities && cities.map((city, index) => (

                        <tr>
                            <td>
                                {city.name}
                            </td>
                            <td>
                                <Badge text='تعديل' onClick={() => setEdit(city.id)} />
                                <Badge text='حذف' color='red' onClick={() => deleteCity(city.id)} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    </div>)
}

function Regions() {

    const [regions, setRegions] = useState([]);
    const [data, setData] = useState({});

    const [validation, setValidation] = useState([]);

    const [show, setShow] = useState(false);


    const service = new GeneralServices;



    const fetchRegions = async () => {
        try {
            const response = await service.get('regions')
            setRegions(response)
            console.log('response', response)
        } catch (error) {
            console.log('error', error)
        }
    }

    const setEdit = (id) => {
        setShow(true)
        regions.filter((region) => {
            if (region.id == id) {
                setData({ 'edit_name': region.name, 'id': region.id })
            }
        })
    }


    const addRegion = async () => {
        setValidation([])
        try {
            const response = await service.post('regions', data)
            setRegions(response)
            setData({ name: '' })
        } catch (error) {
            if (error.validationData) {
                setValidation(error.validationData)
            }
        }
    }


    const editegion = async () => {
        setValidation([])
        try {
            const response = await service.post('regions', data)
            setRegions(response)
            setShow(false)
            setData(null)
        } catch (error) {
            if (error.validationData) {
                setValidation(error.validationData)
            }
        }
    }



    const deleteRegion = async (id) => {
        try {
            const response = await service.post('regions/delete', { id })
            setRegions(response)

        } catch (error) {

        }
    }


    useEffect(() => {
        fetchRegions()
    }, [])


    return (<div>
        {show &&
            <Modal onClose={() => setShow(false)} title='تعديل منطقه'>
                <InputFormGroup
                    label='الاسم'
                    value={data?.edit_name}
                    onChange={(e) => setData({ ...data, edit_name: e.target.value })}
                    error={validation?.edit_name?.[0]}
                    col={12}
                />


                <div class="form-group mt-3">
                    <button onClick={editegion} className='btn btn-md btn-info mx-2'>
                        تعديل
                    </button>
                </div>
            </Modal>
        }
        <div className='row justify-between items-center p-2'>
            <h1>بيانات المناطق</h1>

        </div>

        <div className='row justify-between items-center p-2 px-4 bg-slate-400'>
            <InputFormGroup
                label='الاسم'
                value={data?.name}
                onChange={(e) => setData({ ...data, name: e.target.value })}
                error={validation?.name?.[0]}
            />
            <div className='col text-right  items-center mt-4'>
                <button onClick={addRegion} className='btn btn-md btn-info mx-2'>
                    اضافة
                </button>
            </div>
        </div>
        <div>
            <table class="table table-bordered">
                <thead class="thead-inverse">
                    <tr>
                        <th>الاسم</th>
                        <th>اعدادات</th>
                    </tr>
                </thead>
                <tbody>
                    {regions && regions.map((region, index) => (

                        <tr>
                            <td>
                                {region.name}
                            </td>
                            <td>
                                <Badge text='تعديل' onClick={() => setEdit(region.id)} />
                                <Badge text='حذف' onClick={() => deleteRegion(region.id)} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    </div>)
}

function Industries() {

    const [regions, setRegions] = useState([]);
    const [industries, setIndustries] = useState([]);
    const [data, setData] = useState({});

    const [validation, setValidation] = useState([]);

    const [show, setShow] = useState(false);


    const generalServices = new GeneralServices;



    const fetchRegions = async () => {
        try {
            const response = await generalServices.get('regions')
            setRegions(response)
            console.log('response', response)
        } catch (error) {
            console.log('error', error)
        }
    }


    const fetchIndustries = async () => {
        try {
            const response = await generalServices.get('all_industries')
            setIndustries(response)
            console.log('response', response)
        } catch (error) {
            console.log('error', error)
        }
    }

    const setEdit = (id) => {
        setShow(true)
        industries.filter((industry) => {
            if (industry.id == id) {
                setData({ 'edit_name': industry.name, 'id': industry.id })
            }
        })
    }


    const addIndustry = async () => {
        setValidation([])
        try {
            const response = await generalServices.post('industries', data)
            setIndustries(response)
            setData({ name: '' })
        } catch (error) {
            if (error.validationData) {
                setValidation(error.validationData)
            }
        }
    }


    const editIndustry = async () => {
        setValidation([])
        try {
            const response = await generalServices.post('industries', data)
            setIndustries(response)
            setShow(false)
            setData(null)
        } catch (error) {
            if (error.validationData) {
                setValidation(error.validationData)
            }
        }
    }


    useEffect(() => {
        fetchIndustries()
        fetchRegions()
    }, [])


    return (<div>
        {show &&
            <Modal onClose={() => setShow(false)} title='تعديل منطقه'>
                <InputFormGroup
                    label='الاسم'
                    value={data?.edit_name}
                    onChange={(e) => setData({ ...data, edit_name: e.target.value })}
                    error={validation?.edit_name?.[0]}
                    col={12}
                />


                <div class="form-group mt-3">
                    <button onClick={editIndustry} className='btn btn-md btn-info mx-2'>
                        تعديل
                    </button>
                </div>
            </Modal>
        }
        <div className='row justify-between items-center p-2'>
            <h1>بيانات الصناعات</h1>

        </div>

        <div className='row justify-between items-center p-2 px-4 bg-slate-400'>
            <InputFormGroup
                label='الاسم'
                value={data?.name}
                onChange={(e) => setData({ ...data, name: e.target.value })}
                error={validation?.name?.[0]}
            />
            <div className='col text-right  items-center mt-4'>
                <button onClick={addIndustry} className='btn btn-md btn-info mx-2'>
                    اضافة
                </button>
            </div>
        </div>
        <div>
            <table class="table table-bordered">
                <thead class="thead-inverse">
                    <tr>
                        <th>الاسم</th>
                        <th>اعدادات</th>
                    </tr>
                </thead>
                <tbody>
                    {industries && industries.map((Indust, index) => (

                        <tr>
                            <td>
                                {Indust.name}
                            </td>
                            <td>
                                <Badge text='تعديل' onClick={() => setEdit(Indust.id)} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    </div>)
}


function RequiredPercentage() {

    const [stages, setStages] = useState([]);
    const [validation, setValidation] = useState([]);


    const service = new GeneralServices;


    const fetchStages = async () => {
        try {
            const res = await service.get('stages');
            setStages(res)
        } catch (error) {

        }
    }


    const updateStage = async () => {
        setValidation([])
        try {
            const res = await service.post('stages', stages);
            console.log('res', res)
            setStages(res)
            showSuccessToast('تم تحديث البيانات بنجاح')
        } catch (error) {
            console.log('error', error)
            if (error.validationData) {
                setValidation(error.validationData)
            }
        }
    }

    const handlePercentageChange = (index, newValue) => {
        const updatedStages = [...stages];
        updatedStages[index].required_percentage = newValue;
        setStages(updatedStages);
    };

    useEffect(() => {
        fetchStages()
    }, [])




    return <div>

        <table class="table table-bordered">
            <thead class="thead-inverse">
                <tr>
                    <th>المرحله</th>
                    <th>النسبه</th>
                </tr>
            </thead>
            <tbody>
                {stages && stages.map((item, index) => (

                    <tr key={index}>
                        <td>{item.name}</td>
                        <td>
                            <InputFormGroup
                                value={item.required_percentage}
                                onChange={(e) => handlePercentageChange(index, e.target.value)}
                                error={validation[index + '.required_percentage']?.[0]}
                            />
                        </td>
                    </tr>
                )
                )}
            </tbody>
        </table>
        <div className='p-4'>
            <button onClick={updateStage} className='btn btn-info '>تحديث</button>
        </div>
    </div>
}
export default Settings