

import React, { useEffect, useState, useRef } from 'react';

import { useParams } from 'react-router-dom';
import GeneralServices from '../../../services/GeneralServices';
import InputFormGroup from '../../../components/InputFormGroup';
import { showSuccessToast } from '../../../utils/toastUtils';


function StockInvoicesDetails() {


    const { id } = useParams()

    const [invoice, setInvoice] = useState({});
    const [products, setProducts] = useState([])

    const contentToPrintRef = useRef(null);

    const [isPrinting, setIsPrinting] = useState(false);
    const [loading, setLoading] = useState(false)


    const service = new GeneralServices;


    const get_invoice_info = async () => {
        try {
            const res = await service.get(`invoice_list/${id}`);
            setInvoice(res.invoices)
            setProducts(res.invoices.invoice_details)
        } catch (error) {

        }
    }


    const updateStockQty = (id, qty) => {
        const item = products.find(e => e.id === id);
        item.stock_qty = qty;
        setProducts([...products]);
    }


    const handelStockQtyUpdate = async () => {
        setLoading(true)
        try {
            const res = await service.post(`stock/update_qty_invoice/${id}`, {
                invoice_details: products
            });
            showSuccessToast('تم اضافة تحديث الكميه')
        } catch (error) {
            console.log('error', error)
        } finally {
            setLoading(false)
        }
    }


    const handlePrint = () => {
        const contentToPrint = contentToPrintRef.current;

        if (contentToPrint) {
            const printWindow = window.open('', '', 'width=600,height=600');

            printWindow.document.open();
            printWindow.document.write(`
            <html>
              <head>
                <title>Print</title>
              </head>
              <body>
                ${contentToPrint.innerHTML}
              </body>
            </html>
          `);

            printWindow.document.close();
            printWindow.print();
            printWindow.close();
        }
    };

    useEffect(() => {
        get_invoice_info()
    }, [])

    return (
        <>
            <div ref={contentToPrintRef} className='m-4 shadow rounded overflow-hidden p-4'>
                <div>
                    <table class="table table-bordered">

                        <tbody>
                            <tr>
                                <td>
                                    رقم عرض السعر
                                </td>
                                <td>
                                    {invoice?.rfq?.rfq_number ?? 0}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    رقم الفاتورة
                                </td>
                                <td>
                                    {invoice.id}
                                </td>
                            </tr>
                            <tr>
                                <td>الحاله</td>
                                <td>
                                    <span className='badge '>{invoice.status}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    التاريخ
                                </td>
                                <td>
                                    {new Date(invoice.created_at).toLocaleString()}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div>
                    <table class="table table-bordered  ">
                        <thead class="thead-inverse">
                            <tr>
                                <th>اسم المنتج</th>
                                <th>السعر</th>
                                <th>الكميه</th>
                                <th>الكمييه المستلمه</th>
                                <th>المورد</th>
                            </tr>
                        </thead>
                        <tbody>

                            {products && products.map((item, index) => (
                                <tr key={index}>
                                    <td>{item?.product?.name}</td>
                                    <td>{item.price}</td>
                                    <td>{item.qty}</td>
                                    <td>
                                        <InputFormGroup
                                            value={item.stock_qty}
                                            onChange={(e) => updateStockQty(item.id, e.target.value)}
                                        />
                                    </td>
                                    <td>{item?.supplier?.name}</td>
                                </tr>
                            ))}

                        </tbody>
                    </table>
                </div>


            </div>

            <div className='m-4 shadow rounded overflow-hidden p-4'>
                <div className='felx'>
                    <button type="button" disabled={loading} onClick={handelStockQtyUpdate} class="btn btn-primary">تحديث</button>
                    {/* طباعه */}
                    {/* <button type="button" onClick={handlePrint} class="btn mx-1 bg-black text-yellow-200">طباعه</button> */}
                </div>
            </div>


        </>
    )
}

export default StockInvoicesDetails


