import React, { useState } from 'react'
import GeneralServices from '../../services/GeneralServices';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

function RFQList() {


    const [rfqs, setRfqs] = useState([]);

    const service = new GeneralServices;


    const get_rfqs = async () => {
        try {
            const res = await service.get('rfqs')
            setRfqs(res)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        get_rfqs();
    }, [])

    function formatDate(date) {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        // return new Date(date).toLocaleDateString('ar-EG', options);
        return new Date(date).toLocaleDateString('en-US', options);
    }
    return (
        <>

            <div className='m-4 bg-white rounded p-4 shadow'>
                <div className='flex py-2  items-center justify-center'>
                    <h1 className='text-2xl'>طلبات عروض الاسعار</h1>
                </div>
                <table class="table table-bordered">
                    <thead class="thead-inverse">
                        <tr>
                            <th>الرقم</th>
                            <th>المستخدم</th>
                            <th>الحاله</th>
                            <th>التاريخ</th>
                            <th>اعدادات</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rfqs && rfqs.map((item, index) => (
                            <tr key={item.id}>
                                <td>{item?.user?.name}</td>
                                <td>{item.rfq_number}</td>
                                <td>
                                    <span className={`badge badge-${item.rfq_status === 'pending' ? 'warning' : 'success'}`}>{item.rfq_status}</span>
                                </td>
                                <td>{formatDate(item.created_at)}</td>
                                <td>
                                    <Link to={`/rfqs/${item.id}`} className=' btn-sm btn-primary mx-1'>التفاصيل</Link>
                                    <Link to={`/rfqs_supplisers/${item.id}`} className=' btn-sm btn-dark mx-1'>الموردين</Link>
                                </td>
                            </tr>
                        ))}

                    </tbody>
                </table>
            </div>
        </>
    )
}

export default RFQList