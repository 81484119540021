import React, { useEffect, useState } from 'react'
import GeneralServices from '../../services/GeneralServices'
import { useParams } from 'react-router-dom'

function EmployeesShow() {

    const { id } = useParams();
    const [employee, setEmployee] = useState({})

    const service = new GeneralServices


    const get_employee = async () => {
        try {
            const res = await service.get(`employees/${id}`)
            setEmployee(res)
            console.log('res', res)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        get_employee()
    }, [])

    return (
        <div className='m-4 rounded-lg shadow overflow-hidden'>

            <div className='p-2 bg-gray-700  text-white'>
                <h1>بيانات العميل </h1>
            </div>
            <table class="table table-bordered">
                <tbody>
                    <tr>
                        <td>الاسم</td>
                        <td>{employee.name}</td>
                    </tr>
                    <tr>
                        <td>القسم</td>
                        <td>{employee.department}</td>
                    </tr>
                    <tr>
                        <td>التخصصات</td>
                        <td>

                            {employee?.technician_info?.mechanical && <div class="form-check">
                                <label class="form-check-label">
                                    <input type="checkbox" class="form-check-input" name="" id="" value="checkedValue" checked />
                                    ميكانيكيه
                                </label>
                            </div>
                            }
                            {employee?.technician_info?.electricity && <div class="form-check">
                                <label class="form-check-label">
                                    <input type="checkbox" class="form-check-input" name="" id="" value="checkedValue" checked />
                                    كهرباء
                                </label>
                            </div>
                            }
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default EmployeesShow