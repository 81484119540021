import React from 'react'
import { Route, Routes } from 'react-router-dom'
import FinancesLayout from '../../layoutes/FinancesLayout'
import PaymentsList from './pages/payments/PaymentsList'
import Finances from './Finances'

function FinancesRoute() {
    return (
        <Routes>
            <Route path="/" Component={FinancesLayout} >

                <Route path="" Component={Finances} />
                <Route path="/payments" Component={PaymentsList} />

            </Route>
        </Routes>
    )
}

export default FinancesRoute