import React, { useEffect, useState } from 'react'
import NotificationList from './NotificationList ';
import GeneralServices from '../services/GeneralServices';

function Notificationst() {
    const [showNotifications, setShowNotifications] = useState(false);
    const [count, setCount] = useState(null);

    const toggleNotifications = () => {
        setShowNotifications(!showNotifications);
    };

    const [notifications, setNotifications] = React.useState([])

    const service = new GeneralServices;

    const get_notifications = async () => {
        try {
            const res = await service.get('notifications/unread-count')
            setNotifications(res)
            setCount(res)
        } catch (error) {
            console.log('error', error)
        }
    }



    useEffect(() => {
        get_notifications()

    }, [showNotifications])

    return (
        <>
            <li className="navbar-link cursor-pointer" onClick={toggleNotifications}>
                <p className="nav-link">
                    <i className="fa fa-bell"></i>
                    {count && <span className="badge badge-primary">{count}</span>}
                    {/* <span className="badge badge-primary">0</span> */}
                </p>
            </li>
            {showNotifications && (
                <NotificationList setCount={setCount} />
            )}
        </>
    )
}

export default Notificationst