import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import GeneralServices from '../../../../services/GeneralServices'
import Modal from '../../../../components/Modal'
import TextareaInputFormGroup from '../../../../components/TextareaInputFormGroup'
import Badge from '../../../../components/Badge'
import { showSuccessToast } from '../../../../utils/toastUtils'

function MyWorkOrders() {

    const [orders, setOrders] = useState([])
    const [show, setShowModal] = useState(false)
    const [orderId, setOrderId] = useState(null)
    const [comment, setComment] = useState('')
    const [status, setStatus] = useState('starting')

    const service = new GeneralServices
    const get_my_orders = async () => {
        try {
            const res = await service.get('installation/my-work-orders')
            setOrders(res)
        } catch (error) {
            console.log('error', error)
        }
    }

    const toggleStartWorkStatus = async () => {
        try {
            const data = { id: orderId, status, comment };
            const response = await service.post('installation/start-work-status', data);
            showSuccessToast('تمت ارسال الطلب ')

            setShowModal(!show);
            get_my_orders()
        } catch (error) {
            console.log('error', error);
        }
    }

    const showModal = (id) => {
        setOrderId(id)
        setShowModal(!show)
    }

    const workOrderStatus = (status, id) => {
        setStatus(status)
        showModal(id)
    }

    useEffect(() => {
        get_my_orders()

    }, [])

    return (
        <div className='m-4 rounded shadow overflow-auto'>
            {show && <Modal title='اضافة عملية جديدة' onClose={setShowModal} >
                <TextareaInputFormGroup value={comment} onChange={e => setComment(e.target.value)} label='التعليق' />
                <div className='form-group col mt-2'>
                    <button onClick={toggleStartWorkStatus} className='btn btn-primary'>حفظ</button>
                </div>
            </Modal>
            }
            <div className='bg-gray-900 p-3 text-white'>
                <h1>العمليات الخاصة بى </h1>
            </div>
            <div className='p-3'>
                <table class="table table-bordered table-inverse">
                    <thead class="thead-inverse">
                        <tr>
                            <th>التاريخ</th>
                            <th>العنوان</th>
                            <th>خيارات</th>
                        </tr>
                    </thead>
                    <tbody>

                        {orders && orders.map(order => (
                            <tr>
                                <td>{order?.stage?.name}</td>
                                <td>{order?.contract?.contract_number}</td>
                                <td>
                                    {order?.status?.value == 'pending' &&

                                        <> <button onClick={() => workOrderStatus('starting', order.id)} className='btn btn-sm btn-info mx-1'>بداء العمليه</button>
                                            <button onClick={() => workOrderStatus('not started', order.id)} className='btn btn-sm btn-info mx-1'>عدم البدء</button></>
                                    }
                                    <Badge text={order?.status.name} color={order?.status.color} />
                                    <Link to={`/installations/my-work-order/${order.id}`}>
                                        <Badge text={'تفاصيل'} />
                                    </Link>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default MyWorkOrders