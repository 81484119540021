import React, { useEffect, useState } from 'react'
import GeneralServices from '../../services/GeneralServices';
import { Link } from 'react-router-dom';

function EmployeesList() {

    const [employees, setEmployees] = useState([])

    const generalServices = new GeneralServices;


    const handelGetData = async () => {
        try {
            const res = await generalServices.get('employees')
            setEmployees(res)
        } catch (error) {
            console.log('error', error)
        }
    }


    useEffect(() => {
        handelGetData()
    }, [])

    return (
        <div className='my-4  rounded-lg overflow-hidden'>
            <div className='bg-gray-700  p-2 flex justify-between items-center'>
                <h1 className='text-base text-white font-medium'>الموظفين</h1>
                <Link to={"/employees/create"} className='btn btn-primary'>اضافه موظف جديد</Link>
            </div>
            <div>
                <table class="table table-bordered  ">
                    <thead class="thead-inverse">
                        <tr>
                            <th>الاسم</th>
                            <th>القسم</th>
                            <th>اخرى</th>
                            <th>اعدادات</th>
                        </tr>
                    </thead>
                    <tbody>
                        {employees.map((employee, index) => (
                            <tr key={index}>

                                <td>{employee.name}</td>
                                <td>{employee.department}</td>
                                <td>{JSON.stringify(employee.technician_info.mechanical)}</td>
                                <td>

                                    <Link to={`/employees/${employee.id}/show`} class="badge badge-success mx-1">عرض</Link>
                                    <Link to={`/employees/${employee.id}/edit`} class="badge badge-success mx-1">تعديل</Link>
                                </td>
                            </tr>
                        ))}

                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default EmployeesList