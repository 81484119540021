import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import GeneralServices from '../../services/GeneralServices'
import InputFormGroup from '../../components/InputFormGroup'
import SelectFormGroup from '../../components/SelectFormGroup'
import ImageUpload from '../../components/ImageUpload'
import Private from '../Contract/components/Customer/Private'
import Government from '../Contract/components/Customer/Government'

function ClientEdit() {
    const { id } = useParams()

    const [client, setClient] = useState({})
    const [type, setType] = useState(null)
    const [loading, setLoading] = useState(true)
    const [validation, setValidation] = useState([])
    const [data, setData] = useState({})

    const service = new GeneralServices;

    const get_client = async () => {
        setLoading(true)
        try {
            const res = await service.get(`client/${id}`)
            setClient(res)
            setData(res.data)
            setType(res.type)
            console.log(res)
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }
    const update_client = async () => {
        setLoading(true)
        try {
            const res = await service.post(`client/update/${id}`, {
                data, type
            })
            console.log('res', res)
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        get_client()
    }, [])



    const clientTablesType = {
        individual: <Individual setData={setData} data={data} />,
        private: <Private setData={setData} data={data} />,
        government: <Government setData={setData} data={data} />
    }
    if (loading) return null;
    return (
        <div className=' shadow rounded m-4 overflow-hidden'>
            <div className='p-3 bg-gray-700 text-white'>
                <h1>بيانات العميل</h1>
            </div>


            {clientTablesType[type]}
            <hr />
            <div className='p-4'>
                <button onClick={update_client} className='btn btn-primary'>تحديث</button>
            </div>
        </div>
    )
}



const Individual = ({ data, setData, validation = [] }) => {

    // useEffect(() => {
    //     setData({
    //         first_name: '',
    //         second_name: '',
    //         third_name: '',
    //         last_name: '',
    //         phone: '',
    //         phone2: '',
    //         whatsapp: '',
    //         id_number: '',
    //     })
    // }, [])

    const {
        first_name,
        second_name,
        third_name,
        last_name,
        phone,
        phone2,
        whatsapp,
        how_did_you_get_to_us,
        id_number

    } = data;

    const handelonChangeData = (fild, value) => {
        setData({ ...data, [fild]: value })
    }

    const [attachment, setAttachment] = useState(null)
    const handleLogoChange = (image) => {
        data.image = image
        setAttachment(image)
    };



    return (
        <div className='row p-2'>

            <InputFormGroup
                label='الاول'
                value={first_name}
                onChange={(e) => handelonChangeData('first_name', e.target.value)}
                col={3}
                error={validation?.client?.name}
            />
            <InputFormGroup
                label='الثاني'
                value={second_name}
                onChange={(e) => handelonChangeData('second_name', e.target.value)}
                col={3}
            />
            <InputFormGroup
                label='الثالث'
                value={third_name}
                onChange={(e) => handelonChangeData('third_name', e.target.value)}
                col={3}
            />
            <InputFormGroup
                label='اللقب'
                value={last_name}
                onChange={(e) => handelonChangeData('last_name', e.target.value)}
                col={3}
            />
            <InputFormGroup
                label='الجوال'
                value={phone}
                onChange={(e) => handelonChangeData('phone', e.target.value)}
                col={3}
            />
            <InputFormGroup
                label='جوال اخر'
                value={phone2}
                onChange={(e) => handelonChangeData('phone2', e.target.value)}
                col={3}
            />
            <InputFormGroup
                label='الواتس اب'
                value={whatsapp}
                onChange={(e) => handelonChangeData('whatsapp', e.target.value)}
                col={3}
            />
            <InputFormGroup
                label='رقم الهويه'
                value={id_number}
                onChange={(e) => handelonChangeData('id_number', e.target.value)}
                col={3}
            />
            <SelectFormGroup
                label='كيف وصلت الينا'
                value={how_did_you_get_to_us}
                onChange={(e) => handelonChangeData('how_did_you_get_to_us', e.target.value)}
                options={[
                    { id: 'عميل داخلي', name: 'عميل داخلي' },
                    { id: 'الموقع الالكتروني', name: 'الموقع الالكتروني' },
                    { id: 'الفيس', name: 'الفيس' },
                    { id: 'انستا', name: 'انستا' },
                    { id: 'تويتر', name: 'تويتر' },
                    { id: 'سناب شات', name: 'سناب شات' },
                    { id: 'يويتوب', name: 'يويتوب' },
                    { id: 'اعلانات', name: 'اعلانات' },
                    { id: 'عميل لدى المؤسسة', name: 'عميل لدى المؤسسة' },
                ]}
                col={3}
            />

            <div className='p-4'>
                <ImageUpload logo={attachment} onLogoChange={handleLogoChange} />
            </div>
        </div>
    )
}






export default ClientEdit