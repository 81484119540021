import React, { useEffect } from 'react'
import GeneralServices from '../../../../services/GeneralServices'
import Badge from '../../../../components/Badge'
import { Link } from 'react-router-dom'

function OrdersList() {

    const [orders, setOrders] = React.useState([])

    const service = new GeneralServices()

    const get_orders = async () => {
        try {
            const res = await service.get('stock/work_orders_products')
            setOrders(res)
        } catch (error) {
            console.log('error', error)
        }
    }

    useEffect(() => {
        get_orders()
    }, [])

    return (
        <div className='rounded shadow m-4 overflow-auto'>
            <div className='p-3 bg-gray-900 text-white'>
                <h1>منتجات الصرف</h1>
            </div>
            <div className='p-3'>
                <table class="table table-bordered ">
                    <thead class="thead-inverse">
                        <tr>
                            <th>المرحله</th>
                            <th>رقم امر</th>
                            <th>رقم العقد</th>
                            <th>التاريخ</th>
                            <th>الاعدادات</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orders && orders.map((order, index) => {
                            return <tr key={index}>
                                <td>{order?.contract?.stage?.name}</td>
                                <td>{order?.id}</td>
                                <td>{order?.contract?.contract_number}</td>
                                <td>{order?.created_at}</td>
                                <td>
                                    <Link to={'/stocks/orders/' + order?.id}><Badge text='عرض' /></Link>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default OrdersList