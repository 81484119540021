import React, { useEffect, useState } from 'react'
import TextareaInputFormGroup from '../../../../components/TextareaInputFormGroup'
import InputFormGroup from '../../../../components/InputFormGroup'
import GeneralServices from '../../../../services/GeneralServices'
import { Link, useParams } from 'react-router-dom'
import SelectFormGroup from '../../../../components/SelectFormGroup'
import { client_name } from '../../../../utils/utility'
import Badge from '../../../../components/Badge'
import WorkOrderLogs from './components/WorkOrderLogs'
import Comments from './components/Comments'
import RemainingProducts from './components/RemainingProducts'

function WorkOrdersShow() {

    const { id } = useParams();

    const [order, setOrder] = useState({})
    const [contract, setContract] = useState({})
    const [comments, setComments] = useState([])


    const [tab, setTab] = useState('comments')
    const [products, setProducts] = useState([])

    const [comment, setComment] = useState({
        'comment': '',
        'attachment': '',
        'status': 'in progress'
    })


    const service = new GeneralServices

    const add_comment = async () => {
        // make form data 
        const formData = new FormData();

        // Add comment and id to the form data
        formData.append('comment', comment.comment);
        formData.append('attachment', comment.attachment);
        formData.append('status', comment.status);
        formData.append('id', id);

        try {
            const res = await service.post('installation/work-order-log', formData);
            res.length > 0 && setComments(res);

            setComment({
                ...comment,
                'comment': '',
                'attachment': '',
            })
        } catch (error) {
            // Handle error
        }
    }

    const work_order = async () => {
        try {
            const res = await service.get(`installation/work-orders/show/${id}`)
            setOrder(res)
            setComments(res.comments)
            setContract(res.contract)
        } catch (error) {

        }
    }

    const getAttachmentIcon = (fileUrl) => {
        if (!fileUrl) return
        const fileExtension = fileUrl.split('.').pop().toLowerCase();
        if (fileExtension === 'jpg' || fileExtension === 'png' || fileExtension === 'jpeg') {
            return <i className="fa fa-image"></i>;
        } else if (fileExtension === 'pdf') {
            return <i className="fa fa-file-pdf"></i>;
        } else if (fileExtension === 'mp4' || fileExtension === 'avi') {
            return <i className="fa fa-video"></i>;
        } else {
            return <i className="fa fa-file"></i>;
        }
    }




    const get_porducts = async () => {
        try {
            const res = await service.get(`installation/work-orders/dispatched-products/${id}`)
            setProducts(res)
            console.log('res', res)
        } catch (error) {

        }

    }


    const formatDateAndTime = (dateString) => {
        const dateTime = new Date(dateString);

        // Format the date and time
        const formattedDate = dateTime.toLocaleDateString();
        const formattedTime = dateTime.toLocaleTimeString();

        return { formattedDate, formattedTime };
    };

    useEffect(() => {
        get_porducts()
        work_order()
    }, [])

    return (
        <>
            <div className='m-4 rounded shadow overflow-auto'>
                <div className='bg-gray-900 p-3 text-white'>
                    <h1>العمليات الخاصة بى </h1>
                </div>
                <div className='p-3'>
                    <table class="table table-bordered table-inverse">

                        <tbody>
                            <tr className='bg-gray-100'>

                                <td>اسم العميل</td>
                                <td>رقم العقد</td>
                                <td>رقم الجوال</td>
                                <td>الحي</td>
                            </tr>
                            <tr>
                                <td>{client_name(contract?.client)}</td>
                                <td>{contract?.contract_number}</td>
                                <td>{contract?.client?.data?.phone}</td>
                                <td>
                                    {contract?.street}
                                </td>
                            </tr>
                            <tr className='bg-gray-100'>

                                <td>عدد الادوار</td>
                                <td>نوع المصعد</td>
                                <td>اتجاه فتح الباب</td>
                                <td>مقاس فتح الباب</td>
                            </tr>
                            <tr>
                                <td>{contract?.elevator_trip?.name}</td>
                                <td>{contract?.elevator_type?.name}</td>
                                <td>{contract?.outer_door_directions?.name}</td>
                                <td>{contract?.door_size?.name}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className='row p-3'>
                    {order?.technicians && order?.technicians.map((technician, index) => (

                        // <div className='p-4 broder shadow m-2 rounded'>
                        <Badge text={technician?.employee?.name} />

                        // </div>
                    ))}
                </div>
            </div>
            <div className='flex m-4 space-x-4'>
                <Badge text='التعليقات' color={tab == 'comments' ? 'gray' : 'white'} onClick={() => setTab('comments')} />
                <Badge text='المنتجات' color={tab == 'products' ? 'gray' : 'white'} onClick={() => setTab('products')} />
                <Badge text='السجل' color={tab == 'log' ? 'gray' : 'white'} onClick={() => setTab('log')} />
                <Badge text='المنتجات المتبقيه' color={tab == 'remaining' ? 'gray' : 'white'} onClick={() => setTab('remaining')} />
            </div>

            {tab == 'comments' && <div>

                {/* {comments && <Comments data={comments} />} */}
                <AddComment comment={comment} setComment={setComment} add_comment={add_comment} order={order} />

                <div className="m-4 rounded shadow p-3 overflow-hidden">
                    {comments &&
                        comments.map((comment, index) => (
                            <div className="border p-2 m-2 rounded-xl flex items-center" key={index}>
                                <div className="flex items-center flex-col space-y-3">
                                    <div className="w-16 h-16 bg-sky-100 rounded-full"></div>
                                    <Badge text={comment?.user?.name} color="white" />
                                </div>
                                <div className="bg-gray-100 rounded-3xl p-3 overflow-auto whitespace-pre-line">
                                    <p className="text-black">{comment.comment}</p>
                                </div>
                                <div className='col'></div>
                                <div>
                                    <Link target='_blank' to={`http://127.0.0.1:8000/storage/${comment.attachment}`} >

                                        {getAttachmentIcon(comment.attachment)}

                                    </Link>
                                </div>
                            </div>
                        ))}
                </div>
            </div>}
            {tab == 'products' && <div className='p-4 m-4'>
                <table class="table table-bordered ">
                    <thead class="thead-inverse">
                        <tr>
                            <th>اسم المننج</th>
                            <th>الكميه</th>
                            <th>الموظف</th>
                            <th>الحاله</th>
                            <th>التاريخ</th>
                        </tr>
                    </thead>
                    <tbody>
                        {products && products.map((product, index) => (

                            <tr key={index}>
                                <td>{product?.product?.name}</td>
                                <td>{product.qty}</td>
                                <td>{product?.dispatch?.employee.name}</td>
                                <td>{product?.status == 0 ? <Badge text='غير مسلتم' color={'red'} /> : <Badge text='مستلمة' color='green' />}</td>

                                <td>
                                    {formatDateAndTime(product.created_at).formattedDate} - {formatDateAndTime(product.created_at).formattedTime}
                                </td>
                            </tr>
                        ))}

                    </tbody>
                </table>
            </div>}
            {tab == 'log' && <WorkOrderLogs id={id} />}
            {tab == 'remaining' && <RemainingProducts id={id} />}


        </>
    )
}


const AddComment = ({ comment, setComment, add_comment }) => {
    return (
        <div className='m-4 rounded shadow overflow-auto p-3'>
            <TextareaInputFormGroup
                value={comment.comment}
                onChange={e => setComment({ ...comment, 'comment': e.target.value })}
                label="ملاحظات"
            />
            <div className='row'>
                <InputFormGroup
                    label="مرفق"
                    type="file"
                    onChange={e => setComment({ ...comment, 'attachment': e.target.files[0] })}
                    col={6}
                />
                <SelectFormGroup
                    label="نوع العملية"
                    value={comment.status}
                    onChange={e => setComment({ ...comment, 'status': e.target.value })}
                    options={[
                        { id: 'in progress', name: 'قيد العمل' },
                        { id: 'ready for delivery', name: 'جاهز للتسليم' },
                    ]}
                />
            </div>
            <hr className='my-3' />
            <div className='px-3 py-1'>
                <button onClick={add_comment} className='btn btn-primary'>اضافة</button>
            </div>
        </div>
    )
}


export default WorkOrdersShow