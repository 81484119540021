import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./routes/routes";
import "./App.css";

import * as PusherPushNotifications from "@pusher/push-notifications-web";


//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
import "primereact/resources/primereact.min.css";
import AuthContextProvider, { AuthContext } from "./contexts/AuthContextProvider";
import { Toaster } from "react-hot-toast";
import { useContext } from "react";
import FileUploadComponent from "./FileUploadComponent";

const App = () => {

  // return <FileUploadComponent />
  // return <ConvertToPDF />
  const { user } = JSON.parse(localStorage.getItem('user')) ?? []

  const beamsClient = new PusherPushNotifications.Client({
    instanceId: "608af209-9cef-40c2-88dc-a42349540956",
  });



  useEffect(() => {

    if (user?.email) {

      beamsClient
        .start()
        .then((beamsClient) => beamsClient.getDeviceId())
        .then((deviceId) =>
          console.log("Successfully registered with Beams. Device ID:", deviceId)
        )
        .then(() => beamsClient.addDeviceInterest(user.email))
        .then(() => beamsClient.getDeviceInterests())
        .then((interests) => console.log("Current interests:", interests))
        .catch(console.error)
    } else {
      console.log('user', user)
    }
  }, [])


  const enableNotifications = async () => {
    try {
      if (Notification.permission !== 'granted') {
        await Notification.requestPermission();
        console.log('granted', Notification)
      }
      console.log('not granted')

      // code to enable notifications goes here

    } catch (error) {
      console.error('Error enabling notifications:', error);
    }
  }
  // const enableNotifications = () => {
  //   beamsClient.start().then(() => console.log("Registered with beams!"));
  // }

  // return <button className="m-4 btn bg-blue-500" onclick={enableNotifications()}>Enable Notifications</button>
  return (
    <AuthContextProvider>
      <Toaster reverseOrder={false} />
      <Router>
        <AppRoutes />
      </Router>
    </AuthContextProvider>
  );
};

export default App;

// const [deviceToken, setDeviceToken] = useState(null);
// const [notification, setNotification] = useState(null);

// useEffect(() => {
//   // Initialize OneSignal.
//   OneSignal.init({ appId: '46eae8f3-ecc0-49bd-94be-c276e7e72ee2' });
//   console.log('token', 'token')
//   // Get the device token.
//   OneSignal.getDeviceToken().then((token) => {
//     setDeviceToken(token);
//     console.log('token', token)
//   });

//   // Listen for push notifications.
//   OneSignal.addEventListener('pushReceived', (notification) => {
//     setNotification(notification);
//   });
// }, []);

// if (!deviceToken) {
//   return <div>Loading...</div>;
// }

// if (notification) {
//   return (
//     <div>
//       <h1>ahmed hmed</h1>
//       <h1>{notification.headings.en}</h1>
//       <p>{notification.contents.en}</p>
//     </div>
//   );
// }

// return <div>No new notifications.</div>;
