import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import GeneralServices from '../../services/GeneralServices';

function Suppliers() {

    const [suppliers, setSuppliers] = useState([])
    const service = new GeneralServices();

    const get_suppliers = async () => {

        try {
            const res = await service.get('suppliers')
            setSuppliers(res)
        } catch (error) {

        }
    }
    useEffect(() => {
        get_suppliers()
    }, [])

    return (
        <div className='my-4  rounded-lg overflow-hidden'>
            <div className='bg-gray-700  p-2 flex justify-between items-center'>
                <h1 className='text-base text-white font-medium'>الموردين</h1>
                <Link className='btn btn-primary' to="/suppliers/create">اضافه مورد جديد</Link>
            </div>
            <div>
                <table class="table table-bordered  ">
                    <thead class="thead-inverse">
                        <tr>
                            <th>الاسم</th>
                            <th>البريد</th>
                            <th>رقم الهاتف</th>
                            <th>العنوان</th>
                            <th>عدد عروض الاسعار</th>
                            <th>عروض قيد المراجعه</th>
                            <th>اعدادت</th>
                        </tr>
                    </thead>
                    <tbody>
                        {suppliers.map((supplier, index) => (
                            <tr key={index}>
                                <td>{supplier.name}</td>
                                <td>{supplier.email}</td>
                                <td>{supplier.phone}</td>
                                <td>{supplier.address}</td>
                                <td>{supplier.reviews ?? 0}</td>
                                <td>{supplier.pending_reviews ?? 0}</td>

                                <td>
                                    <Link className='badge badge-success mx-1' to={`/suppliers/${supplier.id}`}>
                                        عرض
                                    </Link>
                                    <Link className='badge badge-warning mx-1' to={`/suppliers/edit/${supplier.id}`}>
                                        تعديل
                                    </Link>
                                </td>

                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Suppliers