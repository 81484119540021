import React, { useEffect, useState } from 'react'
import InputFormGroup from '../../../../components/InputFormGroup';
import ImageUpload from '../../../../components/ImageUpload';
import FileUploadComponent from '../../../../FileUploadComponent';
import GeneralServices from '../../../../services/GeneralServices';
import { showSuccessToast } from '../../../../utils/toastUtils';


const Individual = ({ data, setData, validation = [] }) => {


    const {
        first_name,
        second_name,
        third_name,
        last_name,
        phone,
        phone2,
        whatsapp,
        id_number

    } = data;


    useEffect(() => {
        setData({
            first_name: '',
            second_name: '',
            third_name: '',
            last_name: '',
            phone: '',
            phone2: '',
            whatsapp: '',
            id_number: '',
        })
    }, [])

    const service = new GeneralServices


    const handelonChangeData = (fild, value) => {
        setData({ ...data, [fild]: value })
    }

    const [attachment, setAttachment] = useState(null)
    const handleLogoChange = (image) => {
        data.image = image
        setAttachment(image)
    };

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];

        if (selectedFile) {
            readAndUploadFile(selectedFile);
        }
    };

    const readAndUploadFile = (file) => {
        const reader = new FileReader();

        reader.onloadend = () => {
            // The result contains the binary data as a base64-encoded string
            const binaryString = reader.result.split(',')[1];

            // setFile(binaryString)
            setData({ ...data, image: binaryString })
            // console.log('binaryString', binaryString)
            // console.log('file', file)
        };
        reader.readAsDataURL(file);
    };


    const findClint = async (type, value) => {

        console.log('ahmed hmed')
        try {
            const res = await service.get(`clients/${type}/${value}`)
            console.log('res', res)

            if (res.id) {
                showSuccessToast(' بيانات العميل موجوده مسبقا')
                setData({
                    ...data,
                    first_name: res.data.first_name,
                    second_name: res.data.second_name,
                    third_name: res.data.third_name,
                    last_name: res.data.last_name,
                    phone: res.data.phone,
                    phone2: res.data.phone2,
                    whatsapp: res.data.whatsapp,
                    id_number: res.data.id_number,
                })
            }
        } catch (error) {
            console.log('error', error)
        }
    }

    return (
        <div className='row p-2'>
            <InputFormGroup
                label='رقم الهويه'
                value={id_number}
                onChange={(e) => handelonChangeData('id_number', e.target.value)}
                onBlur={() => findClint('identity', id_number)}
                col={3}
                error={validation['client.id_number']?.[0]}
            />
            <InputFormGroup
                label='الاول'
                value={first_name}
                onChange={(e) => handelonChangeData('first_name', e.target.value)}
                col={3}
                error={validation['client.first_name']?.[0]}
            />
            <InputFormGroup
                label='الثاني'
                value={second_name}
                onChange={(e) => handelonChangeData('second_name', e.target.value)}
                col={3}

                error={validation['client.second_name']?.[0]}
            />
            <InputFormGroup
                label='الثالث'
                value={third_name}
                onChange={(e) => handelonChangeData('third_name', e.target.value)}
                col={3}

                error={validation['client.third_name']?.[0]}
            />
            <InputFormGroup
                label='اللقب'
                value={last_name}
                onChange={(e) => handelonChangeData('last_name', e.target.value)}
                col={3}

                error={validation['client.last_name']?.[0]}
            />
            <InputFormGroup
                label='الجوال'
                value={phone}
                onChange={(e) => handelonChangeData('phone', e.target.value)}
                col={3}

                error={validation['client.phone']?.[0]}
            />
            <InputFormGroup
                label='جوال اخر'
                value={phone2}
                onChange={(e) => handelonChangeData('phone2', e.target.value)}
                col={3}


                error={validation['client.phone2']?.[0]}
            />
            <InputFormGroup
                label='الواتس اب'
                value={whatsapp}
                onChange={(e) => handelonChangeData('whatsapp', e.target.value)}
                col={3}
                error={validation['client.whatsapp']?.[0]}
            />

            <FileUploadComponent setData={setData} data={data} validation={validation} />



        </div>
    )
}




export default Individual