import React from 'react'
import GeneralServices from '../../services/GeneralServices';
import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import SelectFormGroup from '../../components/SelectFormGroup';
import InputFormGroup from '../../components/InputFormGroup';

function SupplierView() {


    const { id } = useParams()

    const [invoice, setInvoice] = useState({});
    const [products, setProducts] = useState([])


    const service = new GeneralServices;


    const get_invoice_info = async () => {
        try {
            const res = await service.get(`invoice_list/${id}`);
            console.log('res', res)
            setInvoice(res)
            setProducts(res.invoice_details)
        } catch (error) {

        }
    }



    useEffect(() => {
        get_invoice_info()
    }, [])

    return (
        <>
            <div className='m-4 shadow rounded overflow-hidden p-4'>
                <div>
                    <table class="table table-bordered">

                        <tbody>
                            <tr>
                                <td>
                                    رقم عرض السعر
                                </td>
                                <td>
                                    {invoice?.rfq?.rfq_number ?? 0}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    رقم الفاتورة
                                </td>
                                <td>
                                    {invoice.id}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    مجموع
                                </td>
                                <td>
                                    {invoice.calculate_total} ريال
                                </td>
                            </tr>
                            <tr>
                                <td>الحاله</td>
                                <td>
                                    <span className='badge '>{invoice.status}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    التاريخ
                                </td>
                                <td>
                                    {new Date(invoice.created_at).toLocaleString()}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>


                <div>
                    <table class="table table-bordered  ">
                        <thead class="thead-inverse">
                            <tr>
                                <th>اسم المنتج</th>
                                <th>السعر</th>
                                <th>الكميه</th>
                                <th>المجموع</th>
                                <th>المورد</th>
                            </tr>
                        </thead>
                        <tbody>

                            {products && products.map((item, index) => (
                                <tr key={index}>
                                    <td>{item?.product?.name}</td>
                                    <td>{item.price}</td>
                                    <td>{item.qty}</td>
                                    <td>{item.qty * item.price} ريال</td>
                                    <td>{item?.supplier?.name}</td>
                                </tr>
                            ))}

                        </tbody>
                    </table>
                </div>


            </div>


        </>
    )
}

export default SupplierView

