// routes/routes.js

import React from "react";
import { Routes, Route } from "react-router-dom";

import NotFound from "../components/NotFound";

import Login from "../pages/Auth/Login";
import DashboardLayout from "../layoutes/DashboardLayout";
import Dashboard from "../pages/Dashboard";
import ContractList from "../pages/Contract/ContractList";
import ContractCreate from "../pages/Contract/ContractCreate";
import ContractView from "../pages/Contract/ContractView";
import ClientsList from "../pages/Client/ClientsList";
import EmployeesList from "../pages/Employees/EmployeesList";
import Settings from "../pages/settings/Settings";
import PurchaseContract from "../pages/Purchases/Contracts/PurchaseContract";
import PurchaseContractProducts from "../pages/Purchases/Contracts/PurchaseContractProducts";
import suppliers from "../pages/Suppliers/Supplier";
import SupplierCreate from "../pages/Suppliers/SupplierCreate";
import InvoicesList from "../pages/Invoices/InvoicesList";
import InvoiceCreate from "../pages/Invoices/InvoiceCreate";
import SupplierInvoices from "../pages/Suppliers/Invoice/SupplierInvoices";
import SupplierInvoicesShow from "../pages/Suppliers/Invoice/SupplierInvoicesShow";
import PaymentsList from "../pages/Payments/PaymentsList";
import ProductQuantities from "../pages/ProductQuantities/ProductQuantities";
import RFQList from "../pages/Rfq/RFQList";
import RFQ from "../pages/Rfq/RFQ";
import RfqSupplier from "../pages/Rfq/RfqSupplier";
import InvoiceShow from "../pages/Invoices/InvoiceShow";
import MyInvoices from "../pages/Suppliers/MyInvoices";
import SupplierView from "../pages/Suppliers/SupplierView";
import SupplierInvoice from "../pages/Suppliers/SupplierInvoice";
import StockInvoicesList from "../pages/Stock/Invoices/StockInvoicesList";
import StockInvoicesDetails from "../pages/Stock/Invoices/StockInvoicesDetails";
import supplierShow from "../pages/Suppliers/supplierShow";
import SupplierInvoiceShow from "../pages/Suppliers/SupplierInvoiceShow";
import PurchaseNotfications from "../pages/Purchases/PurchaseNotfications";
import ContractTemplate from "../pages/settings/ContractTemplate";
import SupplierEdit from "../pages/Suppliers/SupplierEdit";
import EmployeesCreate from "../pages/Employees/EmployeesCreate";
import ContractEdit from "../pages/Contract/ContractEdit";
import ClientShow from "../pages/Client/ClientShow";
import ClientEdit from "../pages/Client/ClientEdit";
import EmployeesShow from "../pages/Employees/EmployeesShow";
import EmployeesEdit from "../pages/Employees/EmployeesEdit";
import AssignToTechnicians from "../pages/Technicians/AssignToTechnicians";
import ContractsAssignedList from "../pages/Technicians/Contracts/ContractsAssignedList";
import SupplierLayout from "../layoutes/SupplierLayout";
import Installations from "../models/installations/Installations";
import RepresentativesList from "../pages/representatives/RepresentativesList";
import Rules from "../pages/settings/Rules";
import StocksRoute from "../models/stocks/StocksRoute";
import InstallationsRoutes from "../models/installations/InstallationsRoutes";
import ProductsList from "../models/stocks/pages/products/ProductsList";
import PurchasesRoutes from "../models/purchases/PurchasesRoutes";
import FinancesRoute from "../models/finances/FinancesRoute";




const AppRoutes = () => {
  return (
    <Routes>



      <Route path="supplier" Component={SupplierLayout} >

        <Route path="" Component={Installations} />

        <Route path="myInvoices" Component={MyInvoices} />

        <Route path="supplier_invoice" Component={SupplierInvoice} />
      </Route>
      <Route path="stocks/*" element={<StocksRoute />} />
      <Route path="installations/*" element={<InstallationsRoutes />} />
      <Route path="purchases/*" element={<PurchasesRoutes />} />
      <Route path="finances/*" element={<FinancesRoute />} />


      <Route Component={DashboardLayout} >

        <Route path="/" Component={Dashboard} />
        <Route path="representatives" Component={RepresentativesList} />
        <Route path="rules" Component={Rules} />
        <Route path="settings/products" Component={ProductsList} />



        <Route path="/contracts" Component={ContractList} />
        <Route path="/contracts/create" Component={ContractCreate} />
        <Route path="/contracts/edit/:id" Component={ContractEdit} />
        <Route path="/contracts/:id" Component={ContractView} />
        <Route path="/contracts/assign-to-technicians/:id" Component={AssignToTechnicians} />

        <Route path="/contracts/contracts-assigned" Component={ContractsAssignedList} />


        <Route path="/clients" Component={ClientsList} />
        <Route path="/clients/:id/show" Component={ClientShow} />
        <Route path="/clients/:id/edit" Component={ClientEdit} />

        <Route path="/employees" Component={EmployeesList} />
        <Route path="/employees/create" Component={EmployeesCreate} />
        <Route path="/employees/:id/show" Component={EmployeesShow} />
        <Route path="/employees/:id/edit" Component={EmployeesEdit} />

        <Route path="/settings" Component={Settings} />
        <Route path="/settings/template" Component={ContractTemplate} />

        <Route path="/purchases/contracts" Component={PurchaseContract} />
        <Route path="/purchases/contracts/:id" Component={PurchaseContractProducts} />

        <Route path="/suppliers" Component={suppliers} />
        <Route path="/suppliers/:id" Component={supplierShow} />
        <Route path="/suppliers/edit/:id" Component={SupplierEdit} />
        <Route path="/suppliers/create" Component={SupplierCreate} />

        <Route path="/suppliers/:id/invoices/:id" Component={SupplierInvoiceShow} />



        <Route path="/invoices/create" Component={InvoiceCreate} />

        <Route path="/invoice_list" Component={InvoicesList} />
        <Route path="/invoice_list/:id" Component={InvoiceShow} />

        <Route path="/supplier/invoices" Component={SupplierInvoices} />
        <Route path="/supplier/invoices/:id" Component={SupplierInvoicesShow} />


        <Route path="/product_quantities" Component={ProductQuantities} />


        <Route path="/payments" Component={PaymentsList} />

        <Route path="/rfqs" Component={RFQList} />
        <Route path="/rfqs/:id" Component={RFQ} />

        <Route path="/rfqs_supplisers/:id" Component={RfqSupplier} />


        <Route path="/myInvoices" Component={MyInvoices} />

        <Route path="/supplier_invoice" Component={SupplierInvoice} />

        <Route path="/supplier_invoice/:id" Component={SupplierView} />

        <Route path="/supplier_invoices/:id" Component={RfqSupplier} />


        <Route path="/stock/invoices" Component={StockInvoicesList} />
        <Route path="/stock/invoices/:id" Component={StockInvoicesDetails} />

        <Route path="/purchases/notfications" Component={PurchaseNotfications} />







      </Route>
      <Route path="*" element={<NotFound />} />
      <Route path="/login" Component={Login} />

    </Routes>
  );
};

export default AppRoutes;
