import React, { useState } from 'react';
import './ImageUpload.css'; // Create  a CSS file for styling

function ImageUpload({ logo, onLogoChange, text = 'اختيار المرفق' }) {
    const [selectedImage, setSelectedImage] = useState(logo);


    const onFileChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        console.log('ahmed hmed')

        reader.onload = () => {
            setSelectedImage(reader.result);

            // setSelectedImage(reader.result.split(',')[1]);
            // onLogoChange(reader.result); // Notify parent component about the logo change
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const clearImage = () => {
        setSelectedImage(null);
        onLogoChange(null); // Notify parent component about clearing the logo
    };

    return (
        <div className="image-upload-container rounded-md">
            <div className={`dropzone ${selectedImage ? 'has-image' : ''}`}>
                <label htmlFor="fileInput">
                    {selectedImage ? (
                        <>
                            <img src={selectedImage} alt="Uploaded" className="preview-image" />
                            {/* <button className="delete-button" onClick={clearImage}>
                                Delete
                            </button> */}
                            <img src="https://us.123rf.com/450wm/kritchanut/kritchanut1409/kritchanut140900028/31219782-cross-icon-in-circle-can-be-used-as-delete-block-close-button-etc.jpg"
                                alt="delte" className="delete-image-button cursor-pointer" onClick={clearImage} />
                        </>
                    ) : (
                        <p>{text}</p>
                    )}
                </label>
                <input
                    type="file"
                    id="fileInput"
                    accept="image/*"
                    onChange={onFileChange}
                    style={{ display: 'none' }}
                />
            </div>
        </div>
    );
}


export default ImageUpload;
