import React, { useEffect, useState } from 'react'
import GeneralServices from '../../services/GeneralServices'
import InputFormGroup from '../../components/InputFormGroup'
import { useParams } from 'react-router-dom';
import { showErrorToast, showLoadingToast } from '../../utils/toastUtils';
import toast from 'react-hot-toast';

import { MultiSelect } from "react-multi-select-component";
import SelectFormGroup from '../../components/SelectFormGroup';

function EmployeesEdit() {


    const { id } = useParams();
    const [employee, setEmployee] = useState({})
    const [user, setUser] = useState({})
    const [rules, setRules] = useState([])

    // setValidation
    const [validation, setValidation] = useState([]);


    const service = new GeneralServices


    const get_employee = async () => {
        try {
            const { employeeData, rules, user } = await service.get(`employees/${id}`)
            setEmployee({
                name: employeeData.name,
                department: employeeData.department,
                mechanical: employeeData?.technician_info?.mechanical,
                electricity: employeeData?.technician_info?.electricity,
            })
            if (user.rule_category_id == 0) {
                setUser({ ...user, rule_category_id: rules[0].id })
            } else {

                setUser(user)
            }
            setRules(rules)

        } catch (error) {
            console.log(error)
        }
    }


    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setEmployee({
            ...employee,
            [name]: checked ? 1 : 0,
        });
    };

    const update_emplyee = async () => {

        const t = showLoadingToast('جاري اضافة الموظف')
        try {
            const res = await service.post(`employees/update/${id}`, { employee, user })
            console.log('res', res)
            showSuccessToast('مبروك اضافة الموظف بنجاح')


        } catch (error) {
            if (error.validationData) {
                showErrorToast('تاكيد من انك ملئت جميع الحقول المطلوبة')
                setValidation(error.validationData)
                console.log('first', error.validationData)
            }
        }
        finally {
            // setLoading(false)
        }
        toast.dismiss(t);
    }



    useEffect(() => {
        get_employee()

    }, [])

    return (


        <div className='my-4  rounded shadow overflow-y'>
            <div className='bg-gray-700  p-2 flex justify-between items-center'>
                <h1 className='text-base text-white font-medium'>تعديل موظف</h1>
            </div>

            <div className='row p-4'>
                <InputFormGroup
                    label='اسم الموظف'
                    value={employee.name}
                    onChange={(e) => setEmployee({ ...employee, name: e.target.value })}
                />


                <div className='col-6 mt-1'>
                    <label>التخصصات للفنين</label>
                    <div className='row form-group'>
                        <div class="form-check form-group col-3">
                            <label class="form-check-label">
                                <input type="checkbox"
                                    class="form-check-input"
                                    id="electricity"
                                    name="electricity"
                                    checked={employee.electricity === 1}
                                    onChange={handleCheckboxChange}
                                />
                                كهرباء
                            </label>
                        </div>

                        <div class="form-check form-group col-3">
                            <label class="form-check-label">
                                <input type="checkbox"
                                    class="form-check-input"
                                    id="mechanical"
                                    name="mechanical"
                                    checked={employee.mechanical === 1}
                                    onChange={handleCheckboxChange}
                                />
                                ميكانيكا
                            </label>
                        </div>
                    </div>
                </div>
                <SelectFormGroup
                    label='الصلاحيات'
                    value={user.rule_category_id}
                    onChange={(e) => setUser({ ...user, rule_category_id: e.target.value })}
                    options={rules} />

                <InputFormGroup
                    label='رقم الهاتف'
                    value={user.phone}
                    onChange={(e) => setUser({ ...user, phone: e.target.value })}
                />
                <InputFormGroup
                    label='البريد الالكتروني'
                    value={user.email}
                    onChange={(e) => setUser({ ...user, email: e.target.value })}
                />
            </div>


            <hr className='mt-3' />
            <div className='p-3'>
                <button onClick={update_emplyee} className='bg-blue-500 btn text-white p-2'>تعديل </button>
            </div>

        </div>
    )

}
export default EmployeesEdit