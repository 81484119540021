import React, { useEffect, useState } from "react";
import SelectFormGroup from "../../../components/SelectFormGroup";
import GeneralServices from "../../../services/GeneralServices";
import InputFormGroup from "../../../components/InputFormGroup";
import Loader from "../../../components/Loader";

function Address({ contract, setContract, addressData = {}, validation = [] }) {
  const { project_name, region_id, city_id, street, neighborhood } =
    addressData;

  const [Loading, setLoading] = useState(true);

  const [data, setData] = useState([]);

  const [address, setAddress] = useState({
    stage_id: 1,
    area: 1,
    city: 1,
    street: "",
    projectName: "",
    neighborhood: "",
  });

  const [area, setArea] = useState("");

  const [city, setCity] = useState("");
  const [cities, setCities] = useState("");

  useEffect(() => {
    get_data();
  }, []);

  useEffect(() => {
    setAddress({
      ...address,
      projectName: project_name,
      area: region_id,
      city: city_id,
      street: street,
      neighborhood: neighborhood,
    });
  }, []);

  useEffect(() => {
    setContract({ ...contract, contract_header: address });
  }, [address]);

  useEffect(() => {
    setContract({ ...contract, contract_header: address });
  }, [address]);

  const generalServices = new GeneralServices();

  const get_data = async () => {
    try {
      const res = await generalServices.get("contract_header_information");
      console.log("res", res);
      setData(res);
      setArea(res[0]);
      setCities(res[0].cities);
      setAddress({ ...address, area: res[0].id, city: res[0].cities[0].id })
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handelUpdateData = (name, value) => {
    if (name == "area") {
      const city = data.find((item) => item.id == value).cities[0];
      const area = data.find((item) => item.id == value);

      setArea(area);
      setCities(area.cities);
      setCity(city);
      address.city = city.id;
      address.area = value;

      setContract({ ...contract, contract_header: address });
    } else {
      setAddress({ ...address, [name]: value });
      setContract({ ...contract, contract_header: address });
    }
  };

  if (Loading) {
    return <Loader />;
  } else
    return (
      <div className="mb-4">
        <div className="bg-gray-700  p-2 flex justify-between items-center my-1">
          <h1 className="text-base text-white font-medium">العنوان</h1>
        </div>
        <div className="row px-2">
          <SelectFormGroup
            label="المرحله"
            value={contract.stage_id}
            onChange={(e) => handelUpdateData("stage_id", e.target.value)}
            options={[
              { id: 1, name: "المرحله الاولي" },
              { id: 2, name: "المرحله الثانيه" },
              { id: 3, name: "المرحله الثالثه" },
              // { id: 2, name: "قيد المراجعه" },
              // { id: 3, name: "موقع" },
              // { id: 4, name: "معلق" },
              // { id: 5, name: "ملغي" },
            ]}
            col={4}
          />

          <InputFormGroup
            label="اسم المشروع"
            value={address.projectName}
            onChange={(e) => handelUpdateData("projectName", e.target.value)}
            col={4}
          />
          <SelectFormGroup
            label="المنطقه"
            value={address.area}
            onChange={(e) => handelUpdateData("area", e.target.value)}
            options={data}
            col={4}
          />

          <SelectFormGroup
            label="المدينه"
            value={address.city}
            onChange={(e) => handelUpdateData("city", e.target.value)}
            options={cities}
            col={4}
          />

          <InputFormGroup
            label="الشارع"
            value={address.street}
            onChange={(e) => handelUpdateData("street", e.target.value)}
            name="street"
            col={4}
          />
          <InputFormGroup
            label="الحي"
            value={address.neighborhood}
            onChange={(e) => handelUpdateData("neighborhood", e.target.value)}
            col={4}
            error={validation["contract_header.neighborhood"]?.[0]}
          />
        </div>
      </div>
    );
}

export default Address;
