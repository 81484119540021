import React, { useContext, useEffect, useState } from 'react'
import GeneralServices from '../../../services/GeneralServices';
import { useParams } from 'react-router-dom';
import { showErrorToast, showLoadingToast, showSuccessToast } from '../../../utils/toastUtils';
import SelectFormGroup from '../../../components/SelectFormGroup';
import toast, { Toaster } from 'react-hot-toast';
import { AuthContext } from '../../../contexts/AuthContextProvider';
import InputFormGroup from '../../../components/InputFormGroup';

function SupplierInvoicesShow() {


    const { user, setUser } = useContext(AuthContext);

    // get id form url
    const { id } = useParams();
    const [invoice, setInvoice] = useState([]);
    const [invoices, setInvoices] = useState([]);
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);

    const [validation, setValidation] = useState([]);

    const [industries, setIndustries] = useState([]);

    const [supplierId, setSupplierId] = useState(1);
    const [suppliers, setSuppliers] = useState([]);

    const [newPrice, setNewPrice] = useState({
        price: '',
        note: 'صناعه سعوديه',
    });



    const service = new GeneralServices;

    const get_products = async () => {
        try {
            const res = await service.get(`get_rfq_products/${id}/${supplierId}`);

            if (res.length !== 0) {

                setInvoice(res.line_items)
                // setInvoice(res)
                console.log('res', res)
                setProducts(res)
            } else {



            }
        } catch (error) {
            showErrorToast('لا يوجد منتجات متاحه')
            setInvoice([])
            setProducts([])
        }
    }

    const get_industries = async () => {
        try {
            const res = await service.get(`industries`);
            setIndustries(res)
            console.log('industries', res)
        } catch (error) {

        }
    }

    const get_suppliers = async () => {
        try {
            const res = await service.get(`suppliers`);
            setSuppliers(res)
        } catch (error) {
            // showErrorToast('لا يوجد منتجات متاحه')
        }
    }


    const addPrice = (productId) => {
        const newData = invoice.map((item) => {
            if (item.product_id === productId) {
                console.log('item', item);
                return {
                    ...item,
                    prices: [
                        ...item.prices,
                        { price: null, note: 'صناعه سعوديه' }
                    ]
                };
            }
            return item;
        });
        setInvoice(newData); // Use setInvoice instead of setInvoices
    };

    const deletePrice = (product_id, index) => {
        const newData = invoice.map((item) => {
            if (item.product_id === product_id) {
                return {
                    ...item,
                    prices: item.prices.filter((price, i) => i !== index)
                };
            }
            return item;

        })
        setInvoice(newData);
    }

    const changePrice = (productId, priceIndex, newPrice) => {
        const newData = invoice.map((item) => {
            if (item.product_id === productId) {
                const updatedPrices = item.prices.map((price, index) => {
                    if (index === priceIndex) {
                        return {
                            ...price,
                            price: newPrice,
                        };
                    }
                    return price;
                });
                return {
                    ...item,
                    prices: updatedPrices,
                };
            }
            return item;
        });
        setInvoice(newData);
    };

    const changeNote = (productId, noteIndex, newNote) => {
        const newData = invoice.map((item) => {
            if (item.product_id === productId) {
                const updatedPrices = item.prices.map((price, index) => {
                    if (index === noteIndex) {
                        return {
                            ...price,
                            note: newNote,
                        };
                    }
                    return price;
                });
                return {
                    ...item,
                    prices: updatedPrices,
                };
            }
            return item;
        });
        setInvoice(newData);
    };

    const handleCreateResponse = async () => {
        const toastId = showLoadingToast('جارى انشاء العرض')
        setValidation([])
        try {
            const res = await service.post('create_response', { invoice, supplierId, id })
            showSuccessToast('تم انشاء العرض بنجاح')

        } catch (error) {
            if (error.validationData) {
                setValidation(error.validationData)
            }
            console.log('error', error)
        }
        finally {

            toast.dismiss(toastId)
        }
    }

    useEffect(() => {
        get_products()
        get_suppliers()
        get_industries()
    }, [])


    useEffect(() => {
        get_products()
    }, [supplierId])



    if (invoice && invoice.length < 0) {
        return <div className='flex justify-center'>
            <h1 className='bg-red-500 text-white p-2 rounded-sm'>لاتوجد عروض</h1>
        </div>
    }


    return (
        <div className='m-4 rounded-md overflow-hidden shadow'>

            {/* <button onClick={get_products} className='btn btn-md btn-info'>update</button> */}
            <div className='bg-gray-900 p-3 flex justify-between items-center'>
                <h1 className='text-white'>تقديم عرض سعر</h1>
                {user.user.level != 'supplier' &&
                    <SelectFormGroup
                        value={supplierId}
                        onChange={(e) => setSupplierId(e.target.value)}
                        options={suppliers}
                        col={3}
                    />}
            </div>
            <div className='p-4'>
                <table class="table table-bordered">
                    <thead class="thead-inverse">
                        <tr>
                            <th>الاسم</th>
                            <th>الكميه</th>
                            <th>الاسعار</th>
                        </tr>
                    </thead>
                    <tbody>
                        {invoice && invoice.length > 0 && invoice.map((item, indexItem) => (
                            <tr>
                                <td>{item?.product?.name}</td>
                                <td>{item.quantity}</td>
                                <td>
                                    {item?.prices ? item?.prices.map((price, index) => (
                                        <div className='flex items-center m-2'>
                                            <SelectFormGroup
                                                value={price.note}
                                                onChange={(e) => changeNote(item.product_id, index, e.target.value)}
                                                options={industries}
                                            />

                                            {/* <input
                                                value={price.price}
                                                onChange={(e) => changePrice(item.product_id, index, e.target.value)}
                                                type="text"
                                                class="form-control m-1 col"
                                                placeholder="السعر"
                                            /> */}
                                            <InputFormGroup
                                                value={price.price}
                                                onChange={(e) => changePrice(item.product_id, index, e.target.value)}
                                                col={3}
                                                // error={validation[`prices.${indexItem}.price`]?.[0]}
                                                error={validation[`invoice.${indexItem}.prices.${index}.price`]?.[0]}
                                            />
                                            <span class="badge badge-danger p-2 justify-normal" onClick={() => deletePrice(item.product_id, index)}>حذف</span>

                                        </div>
                                    )) : null}


                                    <div className='flex items-center m-2'>
                                        <span onClick={() => addPrice(item.product_id)} className="badge p-2 m-1 cursor-pointer badge-success">اضافة</span>
                                    </div>
                                </td>
                            </tr>
                        ))


                        }

                    </tbody>
                </table>
            </div>
            <div className='col p-4'>
                <button className="btn btn-primary" onClick={handleCreateResponse}>حفظ</button>
            </div>
        </div >
    )
}

export default SupplierInvoicesShow