import React, { useState } from 'react'
import InputFormGroup from '../../components/InputFormGroup'
import GeneralServices from '../../services/GeneralServices'
import { showErrorToast, showSuccessToast } from '../../utils/toastUtils'

function SupplierCreate() {



    const [loading, setLoading] = useState(false)
    const [validations, setValidations] = useState([])
    const [supplier, setSupplier] = useState({
        name: '',
        email: '',
        phone: '',
        address: '',
    })

    const service = new GeneralServices

    const createSupplier = async () => {
        setValidations([])
        try {
            const res = await service.post('suppliers', supplier)
            // عرض رساله نجاح
            showSuccessToast('تم اضافة المورد بنجاح')
            // الذهاب الى الموردين
            window.location.href = '/suppliers'
        } catch (error) {
            console.log('error', error)
            if (error.validationData) {
                setValidations(error.validationData)
                showErrorToast(error.message)
            }
        }
    }




    return (
        <div className='my-4  rounded-lg overflow-hidden'>
            <div className='bg-gray-700  p-2 flex justify-between items-center'>
                <h1 className='text-base text-white font-medium'>اضافة مورد</h1>
            </div>
            <div className='p-3'>
                <div className='row'>
                    <InputFormGroup
                        label='اسم المورد'
                        value={supplier.name}
                        onChange={(e) => setSupplier({ ...supplier, name: e.target.value })}
                        error={validations?.name?.[0]}
                    />
                    <InputFormGroup
                        label='الهاتف'
                        value={supplier.phone}
                        onChange={(e) => setSupplier({ ...supplier, phone: e.target.value })}
                        error={validations?.phone?.[0]}
                    />
                    <InputFormGroup
                        label='البريد'
                        value={supplier.email}
                        onChange={(e) => setSupplier({ ...supplier, email: e.target.value })}
                        error={validations?.email?.[0]}
                    />
                    <InputFormGroup
                        label='العنوان'
                        value={supplier.address}
                        onChange={(e) => setSupplier({ ...supplier, address: e.target.value })}
                        error={validations?.address?.[0]}
                    />
                </div>
                <div className=' mt-3'>
                    <div class="form-group col">

                        <button onClick={createSupplier} class="btn btn-primary">اضافة</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SupplierCreate