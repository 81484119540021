import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import GeneralServices from '../../../../services/GeneralServices';
import Badge from '../../../../components/Badge';
import { client_name } from '../../../../utils/utility';
import { showErrorToast, showSuccessToast } from '../../../../utils/toastUtils';

function CreateWorkOrder() {

    const { id } = useParams();

    const navigate = useNavigate()

    const [employees, setEmployees] = useState([])
    const [selectedEmployees, setSelectedEmployees] = useState([])
    const [contract, setContract] = useState([]);

    const [loading, setLoading] = useState(false)

    const service = new GeneralServices



    const get_employees = async () => {
        try {
            const res = await service.get(`installation/employees`)
            setEmployees(res)
            console.log('setEmployees res', res)
        } catch (error) {
            console.log('error', error)
        }
    }

    const toggleEmployee = (id) => {
        if (selectedEmployees.includes(id)) {
            setSelectedEmployees(selectedEmployees.filter(employee => employee !== id))
        } else {
            setSelectedEmployees([...selectedEmployees, id])
        }
    }


    const get_contract = async () => {
        try {
            const res = await service.get(`installation/contract/${id}`);
            setContract(res)
            setStageId(res.stage_id)
        } catch (error) {

        }
    }

    const createWorkOrder = async () => {
        try {
            const res = await service.post(`installation/work-orders`, {
                contract_id: id,
                employees: selectedEmployees
            })
            showSuccessToast('تم انشاء طلب الامر بنجاح')
            // redirect to work order
            navigate(`/installations/contracts`)

        } catch (error) {
            showErrorToast(error.message)
        }
    }


    // اذا العقد لديه ورك اوردر فى المرحله دي يجب اعاده توجيهه الرابط الى البيانات
    const isHasWorkOrder = async () => {
        try {
            const res = await service.get(`installation/has-work-order/${id}`)

            if (res.has_work_order) {
                window.location.href = `/installations/work-orders/${id}`
            }
        } catch (error) {
            console.log('error', error)
        }
    }

    useEffect(() => {
        isHasWorkOrder()
        get_employees()
        get_contract()
    }, [])

    return (
        <div className='m-4 rounded shadow overflow-y-auto overflow-x-hidden'>
            <div className='bg-gray-800 text-white p-3 flex justify-between'>
                <h1>اسناد عقد الى العقد</h1>
                <Link to={`/installations/contracts/${id}`} className='border rounded p-1'>التفاصيل</Link>
            </div>

            <div className='p-3'>
                <table class="table table-bordered table-inverse">

                    <tbody>
                        <tr className='bg-gray-100'>
                            <td>اسم العميل</td>
                            <td>المرحله</td>
                            <td>رقم العقد</td>
                            <td>رقم الجوال</td>
                            <td>الحي</td>
                        </tr>
                        <tr>
                            <td>{client_name(contract?.client)}</td>
                            <td>{contract?.stage?.name}</td>
                            <td>{contract?.contract_number}</td>
                            <td>{contract?.client?.data?.phone}</td>
                            <td>
                                {contract?.street}
                            </td>
                        </tr>
                        <tr className='bg-gray-100'>

                            <td>عدد الادوار</td>
                            <td>نوع المصعد</td>
                            <td>اتجاه فتح الباب</td>
                            <td>مقاس فتح الباب</td>
                            <td> عدد الوقفات</td>
                        </tr>
                        <tr>
                            <td>{contract?.elevator_trip?.name}</td>
                            <td>{contract?.elevator_type?.name}</td>
                            <td>{contract?.outer_door_directions?.name}</td>
                            <td>{contract?.door_size?.name}</td>
                            <td>{contract?.stops_numbers?.name}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <hr className='my-3' />
            <div className='row'>
                {employees && employees.map((employee) =>

                    <div onClick={() => toggleEmployee(employee.id)} className='col-3 cursor-pointer'>
                        <div className='text-center rounded shadow m-2 p-3 flex items-center'>
                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="40" height="40" viewBox="0 0 128 128">
                                <path fill="#fff" d="M64 16A48 48 0 1 0 64 112A48 48 0 1 0 64 16Z"></path><path fill={selectedEmployees.includes(employee.id) ? '#8ccfb9' : 'none'} d="M64 25A39 39 0 1 0 64 103A39 39 0 1 0 64 25Z"></path><path fill="none" stroke="#444b54" stroke-miterlimit="10" stroke-width="6" d="M64 16A48 48 0 1 0 64 112A48 48 0 1 0 64 16Z"></path><path fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="6" d="M42 69L55.55 81 86 46"></path>
                            </svg>
                            <div className='justify-start'>
                                <h1 className='mx-2'>{employee.name}</h1>
                                <div>
                                    {employee?.technician_info?.mechanical == 1 && <Badge text='ميكانيكي' color='red' />}
                                    {employee?.technician_info?.electricity == 1 && <Badge text='كهرباء' color='green' />}
                                </div>
                            </div>
                        </div>
                    </div>

                )}
            </div>
            <hr className='mt-4' />
            <div className='p-4 bg-gray-100'>
                <button disabled={selectedEmployees.length == 0 || loading} onClick={createWorkOrder} className='btn btn-primary'>حفظ</button>
            </div>
        </div>
    )
}

export default CreateWorkOrder