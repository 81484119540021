

import React, { useEffect, useState } from "react";
import GeneralServices from '../../../../services/GeneralServices';
import Badge from "../../../../components/Badge";
import SelectFormGroup from "../../../../components/SelectFormGroup";
import { showErrorToast, showSuccessToast } from "../../../../utils/toastUtils";
import { Link } from "react-router-dom";
import Modal from "../../../../components/Modal";
import TextareaInputFormGroup from "../../../../components/TextareaInputFormGroup";

function ReadyForApprovalWorkOrders() {

    const [orders, setOrders] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [activeStatus, setActiveStatus] = useState("ready for delivery");
    const [status, setStatus] = useState("approved");
    const [comment, setComment] = useState(null);
    const [workOrderId, setWorkOrderId] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [validation, setValidation] = useState([]);
    const [approvalType, setApprovalType] = useState(null);

    const service = new GeneralServices();

    const get_orders = async () => {
        try {
            const res = await service.get('installation/work-orders/3');
            setOrders(res);
        } catch (error) {
            showErrorToast(error.message)
        }
    };


    const get_status = async () => {
        try {
            const res = await service.get(`installation/work-orders-status`);
            setStatuses(res);
            console.log('res', res)
            // add all as first option

            setStatuses([{ id: "all", name: "الكل" }, ...res]);
        } catch (error) {
            console.log("error", error.message);
        }
    };

    const formtDate = (d) => {
        const date = new Date(d);
        const formattedDate = date.toLocaleDateString().split('/').join('-');
        return formattedDate;
    }


    const workStatus = (id, type) => {
        setApprovalType(type);
        setWorkOrderId(id);
        setShowModal(!showModal);
    }


    const approval = async () => {

        setValidation([]);
        try {
            const res = await service.post(`installation/work-orders/approval`, {
                id: workOrderId,
                status,
                comment,
                approval: approvalType
            })

            setOrders(res.data)
            setApprovalType(null);


            setShowModal(!showModal);
        } catch (error) {
            if (error.validationData) {
                setValidation(error.validationData)
            }
        } finally {
            // setApprovalType(null);
        }
    }

    const onClose = () => setShowModal(!showModal);




    useEffect(() => {
        get_orders();
    }, [activeStatus]);

    return (
        <>
            {showModal && (
                <Modal title="تعديل حالة الطلب" onClose={onClose}>
                    <SelectFormGroup
                        value={status}
                        onChange={e => setStatus(e.target.value)}
                        options={[
                            { id: 'approved', name: 'اعتماد' },
                            { id: 'conditionally', name: 'اعتماد مع ملاحظات' },
                            { id: 'rejected', name: 'رفض' },
                        ]}
                        col={12}
                        error={validation?.status?.[0]}
                    />
                    <TextareaInputFormGroup
                        value={comment}
                        onChange={e => setComment(e.target.value)}
                        label="ملاحظات"
                        col={12}
                        error={validation?.comment?.[0]}
                    />
                    <div className="form-group col mt-2">
                        <button onClick={approval} className="btn btn-primary">اضافة</button>
                    </div>
                </Modal>
            )}



            <div className="m-4 rounded shadow overflow-y-auto overflow-x-hidden">
                <div className="bg-gray-700 p-3 text-white row items-center justify-between">
                    <h1>العمليات الجاريه</h1>

                </div>
                <div className="p-4">
                    <table class="table table-bordered">
                        <thead class="thead-inverse">
                            <tr>
                                <th>المرحله</th>
                                <th>تاريخ الطلب</th>
                                <th>تاريخ البدايه</th>
                                <th>تاريخ النهايه</th>
                                <th>الحاله</th>
                                <th>التفاصيل</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orders && orders.map((order, index) => {

                                return <tr key={index}>
                                    <td>{order?.stage?.name}</td>
                                    <td>{formtDate(order.created_at)}</td>
                                    <td>
                                        <Badge
                                            text={order.start_at === null ? 'لم تبداء' : order.start_at}
                                            color={order.start_at === null ? 'red' : 'green'}
                                        />
                                    </td>
                                    <td>
                                        <Badge
                                            text={order.end_at === null ? 'لم ينتهي' : order.end_at}
                                            color={order.end_at === null ? 'red' : 'green'}
                                        />
                                    </td>


                                    <td>
                                        <Badge text={'اعتماد'} color={'blue'} onClick={() => workStatus(order.id, 'chief_approval')} />

                                    </td>
                                    <td>
                                        <Link to={`/installations/work-orders/${order.id}`}>
                                            <Badge text={'تفاصيل'} />
                                        </Link>

                                    </td>


                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

const HeaderStatCards = ({ color = 'red', title = 'لم يبدا', count = '35', icon = 'chart-bar' }) => {
    return (
        <div class="relative flex flex-col min-w-0 break-words bg-white rounded-lg mb-6 xl:mb-0 shadow-lg col m-1">
            <div class="flex-auto p-4">
                <div class="flex flex-wrap">
                    <div class="relative w-full pr-4 max-w-full flex-grow flex-1">
                        <h5 class={`text-blueGray-400  bg-${color}-200 inline-block text-xs   px-2 py-1 rounded-full mb-4`}>
                            {title}
                        </h5>
                        <br />
                        <span class=" text-xl">{count}</span>
                    </div>
                    <div class="relative w-auto pl-4 flex-initial">
                        <div class={`text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 shadow-lg rounded-full bg-${color}-500`}>
                            <i class={`fas fa-${icon}`}></i>
                        </div>
                    </div>
                </div>

            </div>
        </div >
    );
};
export default ReadyForApprovalWorkOrders;

