import React from 'react'

function Badge({ onClick = null, text, color, icon }) {
    return (
        <span onClick={onClick} class={`bg-${color}-100 mx-1 text-xs px-2 py-1 text-${color}-800 border-${color}-400 border  rounded-full mb-4 ${onClick ? 'cursor-pointer' : ''}`}>
            <i class={`fa ${icon} text-white  ${icon && 'mx-1'}`} aria-hidden="true"></i>
            {text}

        </span>

    )
}

export default Badge