import React from "react";
import GeneralServices from "../../services/GeneralServices";
import { showErrorToast, showSuccessToast } from "../../utils/toastUtils";
import { useEffect } from "react";
import { useState } from "react";
import {
  formatDate,
  client_name,
  client_phone,
  contract_status,
  client_type,
} from "../../utils/utility";
import { Link } from "react-router-dom";
import Badge from "../../components/Badge";

function PaymentsList() {
  const [payments, setPayments] = useState([]);

  const service = new GeneralServices();

  const get_payments = async () => {
    try {
      const { data } = await service.get("payments");
      setPayments(data);
      console.log(data);
    } catch (error) {
      showErrorToast(error.message);
    }
  };

  useEffect(() => {
    get_payments();
  }, []);

  return (
    <div className="m-4 rounded-sm overflow-hidden">
      <div className="bg-gray-700  p-2 flex justify-between items-center">
        <h1 className="text-base text-white font-medium">الدفعيات السابقه</h1>
      </div>

      <div>
        <table class="table table-bordered  ">
          <thead class="thead-inverse">
            <tr>
              <th>بيانات العميل</th>
              <th>بيانات المصعد</th>
              <th>المبلغ المدفوع</th>
              {/* <th>المبلغ المستحق دفعه</th> */}
              <th>التاريخ</th>
            </tr>
          </thead>
          <tbody>
            {payments &&
              payments.map((item, index) => (
                <tr>
                  <td>

                    <table class="table table-bordered">
                      <tbody>
                        <tr>
                          <td>
                            اسم العميل
                          </td>
                          <td>{client_name(item.client)}</td>
                        </tr>
                        <tr>
                          <td>
                            نوع العميل
                          </td>
                          <td>{client_type(item.client)}</td>
                        </tr>
                        <tr>
                          <td>
                            رقم الهاتف
                          </td>
                          <td>{client_phone(item.client)}</td>
                        </tr>

                      </tbody>
                    </table>
                  </td>
                  <td>
                    <table class="table table-bordered">
                      {/* اظهار رقم العقد - عدد الوقفات - نوع المصعد - اظهار المرفقات في حالة وجودها  */}

                      <tbody>
                        <tr>
                          <td>رقم العقد</td>
                          <td>
                            {item?.contract?.contract_number}
                          </td>
                        </tr>
                        <tr>
                          <td>نوع المصعد</td>
                          <td>
                            {item?.contract?.elevator_type?.name}
                          </td>
                        </tr>
                        <tr>
                          <td>عدد الوقفات</td>
                          <td>
                            {item?.contract?.stops_numbers?.name}
                          </td>
                        </tr>
                        <tr>
                          <td>المرفقات</td>
                          <td>
                            {item?.attachments && item?.attachments.map((file, index) => (

                              <Link to={'http://localhost:8000/' + file} target="_blank">
                                <Badge text='مرفق' />
                              </Link>
                            ))}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td>{item.amount}</td>
                  {/* <td>{item.contract.remaining_cost}</td> */}
                  <td>{formatDate(item.created_at)}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default PaymentsList;
