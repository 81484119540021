import React, { useEffect, useState } from "react";
import SelectFormGroup from "../../../components/SelectFormGroup";
import GeneralServices from "../../../services/GeneralServices";
import { Link } from "react-router-dom";
import {
  client_name,
  client_phone,
  elevator_type,
} from "../../../utils/utility";

function PurchaseContract() {
  const [satge, setSatge] = useState(1);
  const [contracts, setContracts] = useState([]);

  const service = new GeneralServices();

  const get_contacts = async () => {
    try {
      const { data } = await service.get(`signed_contracts/${satge}`);
      setContracts(data);
      console.log(data);
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    get_contacts();
  }, [satge]);

  return (
    <div className="my-4  rounded-lg overflow-hidden">
      <div className="bg-gray-700  p-2 flex justify-between items-center">
        <h1 className="text-base text-white font-medium">عقودات موقعه</h1>
        {/* <SelectFormGroup
          name="type"
          value={satge}
          onChange={(e) => setSatge(e.target.value)}
          options={[
            { id: "1", name: "المرحله الاولى" },
            { id: "2", name: "المرحله الثانيه" },
            { id: "3", name: "المرحله الثالثه" },
          ]}
          col={3}
        /> */}
      </div>
      <div>
        <table class="table table-bordered  ">
          <thead class="thead-inverse">
            <tr>
              <th> رقم العقد</th>
              <th> الماليه</th>
              <th>اسم العميل</th>
              <th>جوال العميل</th>
              <th>نوع المصعد</th>
              <th>الحالة</th>
              <th>المدينه</th>
              <th>اعدادات</th>
            </tr>
          </thead>
          <tbody>
            {contracts &&
              contracts.map((contract, index) => (
                <tr>

                  <td>{contract.contract_number}</td>
                  <td>{contract.paid_amount}</td>
                  <td>{client_name(contract?.client)}</td>
                  <td>{client_phone(contract?.client)}</td>
                  <td>{`${elevator_type(contract)} -
                  ${contract?.stopsNumbers?.name}`}</td>
                  <td>{contract.contract_status}</td>
                  <td>{contract?.city?.name}</td>
                  {/* <td>
                    {contract.project_name}
                    <br />
                    {contract.contract_status == "assigned" && (
                      <span class="badge mx-1 badge-primary">موقعه</span>
                    )}
                    {contract.is_invoice_created && (
                      <span class="badge mx-1 badge-info">الفاتورة</span>
                    )}
                  </td> */}

                  <td>

                    {contract.is_ready_to_start ?
                      <button
                        disabled
                        class={`btn w-24 text-white btn-sm ${contract.is_invoice_created ? ' bg-green-500' : ' bg-blue-500'}`}

                      >
                        {!contract.is_invoice_created ? ' لم يتم' : 'تم الطلب'}

                      </button> :
                      <button disabled className="btn w-24 text-white btn-sm bg-red-500">
                        غير مدفوع
                      </button>

                    }
                    <Link
                      class={`btn w-24 text-white btn-sm  bg-black mx-1`}
                      to={`/purchases/contracts/${contract.id}`}
                      role="button"
                    >
                      تفاصيل

                    </Link>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default PurchaseContract;
