import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import GeneralServices from '../../services/GeneralServices';

function MyInvoices() {
    const [rfqs, setRfqs] = useState([]);

    const service = new GeneralServices;


    const get_rfqs = async () => {
        try {
            const res = await service.get('rfqs')
            setRfqs(res)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        get_rfqs();
    }, [])

    function formatDate(date) {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        // return new Date(date).toLocaleDateString('ar-EG', options);
        return new Date(date).toLocaleDateString('en-US', options);
    }
    return (
        <div className='m-4 rounded-md overflow-hidden'>
            <div className='bg-gray-700 p-3'>
                <h1 className='text-white font-medium'>عروض الاسعار</h1>
            </div>
            <div>
                <table class="table table-bordered">
                    <thead class="thead-inverse">
                        <tr>
                            <th>الرقم</th>
                            <th>التاريخ</th>
                            <th>اعدادات</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rfqs && rfqs.map((item, index) => (
                            <tr key={item.id}>
                                <td>{item.rfq_number}</td>

                                <td>{formatDate(item.created_at)}</td>
                                <td>
                                    <Link to={`/supplier/invoices/${item.id}`} className=' btn-sm bg-green-500 text-white'>تقديم</Link>
                                </td>
                            </tr>
                        ))}

                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default MyInvoices
