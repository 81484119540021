// installationsRoutes.js

import React from "react";
import { Routes, Route } from "react-router-dom";
import Dashboard from "../../pages/Dashboard";
import WorkOrders from "./pages/work-orders/WorkOrders";
import InstallationsLayout from "../../layoutes/InstallationsLayout";
import Installations from "./Installations";
import ReadyForApprovalWorkOrders from "./pages/supervisors/ReadyForApprovalWorkOrders";
import WorkOrdersShow from "./pages/work-orders/WorkOrdersShow";
import MyWorkOrders from "./pages/work-orders/MyWorkOrders";
import MyWorkOrderShow from "./pages/work-orders/MyWorkOrderShow";
import CreateWorkOrder from "./pages/work-orders/CreateWorkOrder";
import Contracts from "./pages/contracts/Contracts";
import ContractView from "./pages/contracts/ContractView";

const InstallationsRoutes = () => (
    <Routes>
        <Route path="/" Component={InstallationsLayout} >
            <Route path="" Component={Installations} />
            <Route path="ready-for-approval-work-orders" Component={ReadyForApprovalWorkOrders} />
            <Route path="work-orders" Component={WorkOrders} />
            <Route path="work-orders/:id" Component={WorkOrdersShow} />

            <Route path="my-work-orders" Component={MyWorkOrders} />
            <Route path="my-work-order/:id" Component={MyWorkOrderShow} />
            <Route path="work-orders/create/:id" Component={CreateWorkOrder} />

            <Route path="contracts" Component={Contracts} />
            <Route path="contracts/:id" Component={ContractView} />
        </Route>
    </Routes>
);

export default InstallationsRoutes;
