import React, { useEffect } from 'react'
import InputFormGroup from '../../components/InputFormGroup'
import SelectFormGroup from '../../components/SelectFormGroup'
import { useState } from 'react';
import GeneralServices from '../../services/GeneralServices';
import MyTableWithSelect from '../../components/MyTableWithSelect';
import MultiSelect from '../../components/MultiSelect';
import { showErrorToast, showLoadingToast, showSuccessToast } from '../../utils/toastUtils';
import toast from 'react-hot-toast';

function EmployeesCreate() {

    const [employee, setEmployee] = useState({
        'name': '',
        'email': '',
        'rule_category_id': 1,
        'password': '',
        'department': 'admin',
        'mechanical': 0,
        'electricity': 0,
    });

    const [validation, setValidation] = useState([]);

    const [rules, setRules] = useState([]);

    const service = new GeneralServices();

    const add_emplyee = async () => {
        setValidation([]);
        const t = showLoadingToast('جاري اضافة الموظف')
        try {
            const res = await service.post('employees', employee)
            console.log('res', res)
            showSuccessToast('مبروك اضافة الموظف بنجاح')


        } catch (error) {
            if (error.validationData) {
                showErrorToast('تاكيد من انك ملئت جميع الحقول المطلوبة')
                setValidation(error.validationData)
            }
        }
        finally {
            // setLoading(false)
        }
        toast.dismiss(t);
    }

    const getRules = async () => {

        try {
            const res = await service.get('rules')
            console.log('res', res)
            setRules(res)


        } catch (error) {
        }
    }

    const [selectedOptions, setSelectedOptions] = useState([]);

    const handleSelectionChange = (newSelection) => {
        setSelectedOptions(newSelection);
    };

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setEmployee({
            ...employee,
            [name]: checked ? 1 : 0,
        });
    };

    useEffect(() => {
        getRules()
    }, [])


    return (
        <div className='my-4  rounded shadow overflow-hidden'>
            <div className='bg-gray-700  p-2 flex justify-between items-center'>
                <h1 className='text-base text-white font-medium'>اضافة موظف</h1>
            </div>

            <div className='row p-4'>
                <InputFormGroup
                    label='اسم الموظف'
                    value={employee.name}
                    onChange={(e) => setEmployee({ ...employee, name: e.target.value })}
                    error={validation?.name?.[0]}
                />

                <SelectFormGroup
                    label='اسم القسم'
                    value={employee.department}
                    onChange={(e) => setEmployee({ ...employee, department: e.target.value })}
                    options={[
                        { id: 'admin', name: 'الاداره' },
                        { id: 'installations', name: 'التركيب' },
                        { id: 'finance', name: 'الماليه' },
                        { id: 'purchases', name: 'المشتريات' },
                        { id: 'stocks', name: 'المخازن' },
                        { id: 'finance', name: 'الماليه' },
                    ]}
                    error={validation?.department?.[0]}
                />


                <InputFormGroup
                    label='كلمه السر'
                    value={employee.password}
                    onChange={(e) => setEmployee({ ...employee, password: e.target.value })}
                    error={validation?.password?.[0]}
                />
                <InputFormGroup
                    label='البريد الالكتورني'
                    value={employee.email}
                    onChange={(e) => setEmployee({ ...employee, email: e.target.value })}
                    error={validation?.email?.[0]}
                />
                <SelectFormGroup
                    label="الصلاحيات"
                    value={employee.rule_category_id}
                    onChange={(e) => setEmployee({ ...employee, rule_category_id: e.target.value })}
                    options={rules}
                    error={validation?.rule_category_id?.[0]}
                />


                <div class="form-group col-6 row mt-4">


                    <div class="form-check form-group col-6">
                        <label class="form-check-label">
                            <input type="checkbox"
                                class="form-check-input"
                                id="electricity"
                                name="electricity"
                                checked={employee.electricity === 1}
                                onChange={handleCheckboxChange}
                            />
                            كهرباء
                        </label>
                    </div>

                    <div class="form-check form-group col-6">
                        <label class="form-check-label">
                            <input type="checkbox"
                                class="form-check-input"
                                id="mechanical"
                                name="mechanical"
                                checked={employee.mechanical === 1}
                                onChange={handleCheckboxChange}
                            />
                            ميكانيكا
                        </label>
                    </div>
                </div>
            </div>

            <hr className='mt-3' />
            <div className='p-3'>
                <button onClick={add_emplyee} className='bg-blue-500 btn text-white p-2'>اضافة موظف</button>
            </div>
        </div>
    )
}

export default EmployeesCreate