import React, { useEffect } from 'react'
import InputFormGroup from '../../../../components/InputFormGroup';
import SelectFormGroup from '../../../../components/SelectFormGroup';
import { useState } from 'react';
import ImageUpload from '../../../../components/ImageUpload';
import FileUploadComponent from '../../../../FileUploadComponent';


const Government = ({ data, setData, validation = [] }) => {



    const {
        name,
        id_number,
        phone,
        whatsapp,
        phone2

    } = data;

    useEffect(() => {
        setData({
            name: name ?? '',
            id_number: id_number ?? '',
            phone: phone ?? '',
            whatsapp: whatsapp ?? '',
            phone2: phone2 ?? '',
        })
    }, [])



    const handelonChangeData = (fild, value) => {
        setData({ ...data, [fild]: value })
    }


    const [attachment, setAttachment] = useState(null)
    const handleLogoChange = (image) => {
        data.image = image
        setAttachment(image)
    };



    return (
        <div className='row px-2'>
            <InputFormGroup
                label='اسم الجهه'
                value={name}
                onChange={(e) => handelonChangeData('name', e.target.value)}
                col={3}

                error={validation['client.name']?.[0]}
            />
            <InputFormGroup
                label='رقم الهوية'
                value={id_number}
                onChange={(e) => handelonChangeData('id_number', e.target.value)}
                col={3}
                error={validation['client.id_number']?.[0]}
            />
            <InputFormGroup
                label='الجوال'
                value={phone}
                onChange={(e) => handelonChangeData('phone', e.target.value)}
                col={3}
                error={validation['client.phone']?.[0]}
            />
            <InputFormGroup
                label='واتس اب'
                value={whatsapp}
                onChange={(e) => handelonChangeData('whatsapp', e.target.value)}
                col={3}

                error={validation['client.whatsapp']?.[0]}
            />
            <InputFormGroup
                label='جوال اخر'
                value={phone2}
                onChange={(e) => handelonChangeData('phone2', e.target.value)}
                col={3}
                error={validation['client.phone2']?.[0]}
            />


            <FileUploadComponent setData={setData} data={data} validation={validation} />

        </div>
    )
}


export default Government

