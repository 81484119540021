import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import GeneralServices from '../../../../services/GeneralServices';
import Badge from '../../../../components/Badge';
import Modal from '../../../../components/Modal';



function ContractView() {

    const { id } = useParams();

    const [contract, setContract] = useState([]);

    const service = new GeneralServices;

    const get_contract = async () => {
        try {
            const res = await service.get(`contract/${id}`)
            setContract(res)
            console.log('res', res)
        } catch (error) {

        }
    }




    useEffect(() => {
        get_contract()
    }, [])

    return (
        <div className='m-4'>
            <div className='rounded-t-lgoverflow-hidden'>
                <div className='bg-gray-700  p-2 flex justify-between items-center'>
                    <h1 className='text-base text-white font-medium'>بيانات العقد</h1>

                </div>
                <div className='p-4'>

                    <table class="table table-bordered  ">

                        <tbody>
                            <tr className='bg-gray-100'>
                                <td className='col-2'>اسم المشروع</td>
                                <td className='col-2'>المدنيه</td>
                                <td className='col-2'>الشارع</td>
                                <td className='col-2'>حاله العقد</td>

                            </tr>
                            <tr>
                                <td>{contract?.project_name}</td>
                                <td>{contract?.city?.name}</td>
                                <td>{contract?.street}</td>
                                <td>
                                    <span class="badge badge-success">{contract?.contract_status}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>




                </div>
            </div>

            <div className='rounded-t-lgoverflow-hidden'>
                <div className='bg-gray-700  p-2 flex justify-between items-center'>
                    <h1 className='text-base text-white font-medium'>بيانات العميل</h1>

                </div>
                <div className='p-4'>
                    <table class="table table-bordered  ">

                        <tbody>
                            <tr className='bg-gray-100'>
                                <td className='col-4'>الاسم</td>
                                <td className='col-4'>الهاتف</td>
                                <td className='col-4'>النوع</td>

                            </tr>
                            <tr>
                                <td>{contract.client?.data.name}</td>
                                <td>{contract.client?.data.phone}</td>
                                <td>
                                    <span class="badge badge-dark">{contract.client?.type}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>




                </div>
            </div>
            <div className='bg-gray-700  p-2 flex justify-between items-center'>
                <h1 className='text-base text-white font-medium'>بيانات المصعد</h1>

            </div>
            <div className='p-4'>
                <table class="table table-bordered  ">

                    <tbody>
                        <tr className='bg-gray-100'>
                            <td className='col-2'>نوع المصعد</td>
                            <td className='col-2'>مقاس سكك الكبينة</td>
                            <td className='col-2'>مقاس سكك الثقل</td>
                            <td className='col-2'>عدد الوقفات</td>
                            <td className='col-2'>مشوار المصعد</td>
                            <td className='col-2'>غرفه المصعد</td>

                        </tr>
                        <tr>
                            <td>{contract?.elevator_type?.name}</td>
                            <td>{contract?.cabin_rails_size?.name}</td>
                            <td>{contract?.counter_weight_rails_size?.name}</td>
                            <td>{contract?.stops_numbers?.name}</td>
                            <td>{contract?.elevator_trip?.name}</td>
                            <td>{contract?.elevator_room?.name}</td>
                        </tr>
                    </tbody>
                </table>


                <table class="table table-bordered  ">

                    <tbody>
                        <tr className='bg-gray-100'>
                            <td className='col-2'>نوع المكينه</td>
                            <td className='col-2'>ضمان المكينة</td>
                            <td className='col-2'>حموله المكينة</td>
                            <td className='col-2'>سرعه  المكينة</td>
                            <td className='col-2'>حموله الاشخاص</td>
                            <td className='col-2'>كرت الكنترول</td>

                        </tr>
                        <tr>
                            <td className='col-2'>{contract?.machine_type?.name}</td>
                            <td className='col-2'>{contract?.machine_warranty_id}</td>
                            <td className='col-2'>{contract?.machine_load?.name}</td>
                            <td className='col-2'>{contract?.machine_speed?.name}</td>
                            <td className='col-2'>{contract?.people_load?.name}</td>
                            <td className='col-2'>{contract?.control_card?.name}</td>
                        </tr>
                    </tbody>
                </table>
                <table class="table table-bordered  ">

                    <tbody>
                        <tr className='bg-gray-100'>
                            <td className='col-2'>عدد المداخل</td>
                            <td className='col-2'>اتجاه فتح الباب الخارجي</td>
                            <td className='col-2'>نوع الباب الداخلى</td>
                            <td className='col-2'>مقاس فتحه الباب</td>
                            <td className='col-2'>اضافات اخرى</td>
                            <td className='col-2'>اجمالى عدد الزيارات</td>

                        </tr>
                        <tr>
                            <td>{contract?.entrances_number?.name}</td>
                            <td>{contract?.outer_door_directions?.name}</td>

                            <td>حموله المكينة</td>
                            <td>{contract?.counter_weight_rails_size?.name}</td>
                            <td>{contract?.other_additions}</td>
                            <td>{contract?.visits_number}</td>
                        </tr>
                    </tbody>
                </table>
                <table class="table table-bordered  ">

                    <tbody>
                        <tr className='bg-gray-100'>
                            <td className='col-2'>الصيانه المجانيه</td>
                            <td className='col-2'>ضمان المصعد</td>
                            <td className='col'>ملاحظات</td>

                        </tr>
                        <tr>
                            <td>{contract?.free_maintenance_id}</td>
                            <td>{contract?.elevator_warranty_id}</td>
                            <td>حموله المكينة</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className='bg-gray-700  p-2 flex justify-between items-center'>
                <h1 className='text-base text-white font-medium'>مواصفات الباب الخارجي</h1>

            </div>
            <div className='p-4'>
                <table class="table table-bordered table-inverse">
                    <thead class="thead-inverse">
                        <tr>
                            <th>الطابق</th>
                            <th>عدد الابواب</th>
                            <th>موصفات الباب الخارجي</th>
                            <th>اتجاه فتح الباب</th>
                            <th>موصفات الباب الخارجي الثاني</th>
                            <th>اتجاه فتح الباب الثاني</th>
                        </tr>
                    </thead>
                    <tbody>

                        {contract?.outer_door_specifications && contract.outer_door_specifications.map(specification => (
                            <tr>
                                <td>{specification?.floor?.name}</td>
                                <td>{specification?.number_of_doors}</td>
                                <td>{specification?.door_specification?.name}</td>
                                <td>{specification?.opening_direction?.name}</td>
                                <td>{specification?.door_specification_tow?.name}</td>
                                <td>{specification?.opening_direction_tow?.name}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <div className='bg-gray-700  p-2 flex justify-between items-center'>
                <h1 className='text-base text-white font-medium'>البيانات المالية</h1>

            </div>
            <div className='p-4'>
                <table class="table table-bordered table-inverse">
                    <thead class="thead-inverse">
                        <tr>
                            <th>المجموع</th>
                            <th>الضريبه</th>
                            <th>المجموع + الضريبه</th>
                        </tr>
                    </thead>
                    <tbody>
                        {contract?.installments && contract.installments.map(payment => (
                            <tr>
                                <td>{payment.amount} SRA </td>
                                <td>{payment.tax} SRA </td>
                                <td>{parseFloat(payment.amount) + parseFloat(payment.tax)} SRA </td>
                            </tr>
                        ))}

                    </tbody>
                </table>
            </div>


            <Representatives contract={contract} />
        </div>
    )
}



const Representatives = ({ contract }) => {


    return (
        <>

            <div className='bg-gray-700  p-2 flex text-white justify-between items-center'>
                <h1 className='text-base text-white font-medium'>المناديب</h1>

            </div>
            <table class="table table-bordered  ">
                <thead class="thead-inverse">
                    <tr>
                        <th>الاسم</th>
                        <th>الهاتف</th>
                        <th>النوع</th>
                    </tr>
                </thead>
                <tbody>
                    {contract?.representatives && contract?.representatives.map((representative, index) => {
                        if (representative.representativeable_type === 'App\\Models\\Client') {
                            return <tr>
                                <td>
                                    {representative.representativeable.data.first_name} {representative.representativeable.data.last_name}
                                </td>
                                <td>

                                    <span className='rounded-full p-2 border text-block items-center space-x-2 m-2 '>
                                        <i class="fa fa-phone text-green-500 text-4xl mx-1" aria-hidden="true"></i>
                                        {representative.representativeable.data.phone}
                                    </span>
                                    <span className='rounded-full p-2 border text-block items-center space-x-2 '>
                                        <i class="fa fa-whatsapp text-green-500 text-4xl mx-1" aria-hidden="true"></i>
                                        {representative.representativeable.data.whatsapp}
                                    </span>
                                </td>
                                <td>
                                    <Badge text='عميل' color="green" />

                                </td>
                            </tr>
                        }
                        else if (representative.representativeable_type === 'App\\Models\\Employee') {
                            return <tr>
                                <td>
                                    <p> {representative.representativeable.name}</p>
                                </td>
                                <td>
                                </td>
                                <td>
                                    <Badge text='مندوب داخلى' color="yallow" />
                                </td>
                            </tr>
                        }
                        else {
                            return <tr>
                                <td>{representative.name}</td>
                                <td>
                                    <span className='rounded-full p-2 border text-block items-center space-x-2 m-2 '>
                                        <i class="fa fa-phone text-green-500 text-4xl mx-1" aria-hidden="true"></i>
                                        {representative.phone}
                                    </span>
                                </td>
                                <td>
                                    <Badge text='مندوب خارجي' color="red" />
                                </td>
                            </tr>
                        }
                    })}

                </tbody>
            </table>
        </>)

};




export default ContractView