import React, { useContext } from "react";
import AuthServices from "../services/AuthServices";
import { AuthContext } from "../contexts/AuthContextProvider";
import Notificationst from "./Notificationst";
import { Link, useNavigate } from "react-router-dom";

export default function MainHeader() {
  const authServices = new AuthServices();

  const navigate = useNavigate()

  const { user, setUser } = useContext(AuthContext);


  const handleLogout = async () => {
    try {
      await authServices.post('logout');
      localStorage.removeItem('user');
      setUser(null);
    } catch (error) {
      if (error.state) {

        navigate('/login');
      }
      console.log('error', error.state)
    }
  }

  const logUser = async () => {


  }

  return (
    <nav className="main-header navbar navbar-expand navbar-white navbar-light">
      {/* <!-- Left navbar links --> */}
      <ul className="navbar-nav">
        <li className="nav-item d-none d-sm-inline-block">
          <a onClick={logUser} href="#" className="nav-link">
            <span
              id="alix_freeplan_tbar-btnInnerEl"
              data-ref="btnInnerEl"
              unselectable="on"
              className="x-btn-inner x-btn-inner-default-toolbar-small x-rtl"
            >
              <span>مرحبا</span>
              <span className="mx-1">{user?.user?.name}</span>


            </span>
          </a>
        </li>
        <li>
          <Link to={'/'} className="border px-2 py-1 rounded-full mx-3">الاداره</Link>
          <Link to={'/stocks'} className="border px-2 py-1 rounded-full mx-3">المخازن</Link>
          <Link to={'/purchases'} className="border px-2 py-1 rounded-full mx-3">المشتريات</Link>
          <Link to={'/finances'} className="border px-2 py-1 rounded-full mx-3">الماليه</Link>
          <Link to={'/installations'} className="border px-2 py-1 rounded-full mx-3">التركيب</Link>
        </li>
      </ul>
      {/* <!-- Right navbar links --> */}
      <ul className="navbar-nav ml-auto">

        <div id="app">
          <div>
            <Notificationst />

          </div>
        </div>



        <li onClick={handleLogout} className="navbar-link cursor-pointer">
          <p className="nav-link logout">
            <i className="fa fa-power-off"></i>
          </p>
        </li>
      </ul>
    </nav>
  );
}
