import React, { useEffect, useState } from 'react'
import GeneralServices from '../../../../services/GeneralServices'
import { useParams } from 'react-router-dom';
import Badge from '../../../../components/Badge';

function ProductDetails() {

    const { id } = useParams()


    const [product, setProduct] = useState({})
    const [activeTab, setActiveTab] = useState('purchase')

    const service = new GeneralServices

    const get_product = async () => {

        try {
            const res = await service.get(`stock/products/${id}`)
            setProduct(res)
        } catch (error) {
            console.log('error', error)
        }
    }

    const dateFormat = (date) => {
        const d = new Date(date)
        return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`

    }

    useEffect(() => {
        get_product()
    }, [])




    return (
        <div className='m-4 rounded shadow overflow-hidden'>
            <div className='p-3 bg-gray-900 text-white'>
                <h1>تفاصيل المنتج</h1>
            </div>
            <div className='p-2'>
                <table class="table table-bordered ">
                    <thead class="thead-inverse">
                        <tr>
                            <th>الاسم</th>
                            <th>الكميه</th>
                            <th>المرحله</th>
                            <th>المصعد</th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr>
                            <td>{product.name}</td>
                            <td>{product.in_stock}</td>
                            <td>
                                <Badge text={product?.ths_stage?.name} color={product?.ths_stage?.id === 1
                                    ? 'red'
                                    : product?.ths_stage?.id === 2
                                        ? 'green'
                                        : 'blue'} />

                            </td>
                            <td><Badge text={product?.elevator_type?.name} /></td>

                        </tr>
                    </tbody>
                </table>
            </div>
            <div className='p-2'>
                <Badge text='المشتريات' color={activeTab == 'purchase' ? 'green' : ''} onClick={() => setActiveTab('purchase')} />
                <Badge text='المصروفات' color={activeTab == 'expense' ? 'green' : ''} onClick={() => setActiveTab('expense')} />
            </div>
            <div>
                {activeTab == 'purchase' && <div className='p-2'>
                    <table class="table table-bordered  ">
                        <thead class="thead-inverse">
                            <tr>
                                <th>رقم الفاتورة</th>
                                <th>العقودات</th>
                                <th>المورد</th>
                                <th>السعر</th>
                                <th>التاريخ</th>
                            </tr>
                        </thead>
                        <tbody>
                            {product?.invoice_detail?.map((item, index) => (

                                <tr>
                                    <td>{item?.invoice_id}#</td>
                                    <td>{item?.invoice_id}#</td>
                                    <td>{item?.supplier?.name}</td>
                                    <td>{item?.price}</td>
                                    <td>{dateFormat(item?.created_at)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>}
                {activeTab == 'expense' && <div className='p-2'>
                    <table class="table table-bordered  ">
                        <thead class="thead-inverse">
                            <tr>
                                <th>رقم العقد</th>
                                <th>الكميه</th>
                                <th>موظف المخزن</th>
                                <th>المسلتم</th>
                                <th>التاريخ</th>
                            </tr>
                        </thead>
                        <tbody>
                            {product?.dispatch_items?.map((item, index) => (

                                <tr>
                                    <td>{item?.dispatch?.contract_id}#</td>
                                    <td>{item.qty}</td>
                                    <td>{item?.dispatch?.user?.name}</td>
                                    <td>{item?.dispatch?.employee?.name}</td>
                                    <td>{dateFormat(item?.created_at)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>}
            </div>
        </div>
    )
}

export default ProductDetails