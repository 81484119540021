import React, { useEffect } from 'react'
import GeneralServices from '../../services/GeneralServices'
import Badge from '../../components/Badge';

import Moment from 'react-moment';

function Installations() {

    const [notifications, setNotifications] = React.useState([])

    const service = new GeneralServices;

    const get_notifications = async () => {
        try {
            const res = await service.get('notifications')
            setNotifications(res)
        } catch (error) {
            console.log('error', error)
        }
    }

    useEffect(() => {
        get_notifications()
    }, [])

    return (
        <div className='m-4'>
            <div className='row'>

            </div>




        </div>
    )
}

const Notification = ({ notification }) => {
    const convertToDateString = (dateString) => {
        const moment = require('moment');
        moment.locale("ar");
        const date = moment(dateString);
        return date.fromNow();
    };

    return (
        <div className={`items-center rounded shadow-sm p-2 m-2 overflow-ellipsis`}>
            <div className='row items-center'>
                <div className='w-8 h-8 rounded-full mx-1 border border-indigo-300 justify-center items-center flex overflow-auto bg-indigo-100'>
                    <div className={`bg-red-500 w-2 h-2 rounded-full top-0 left-0`}></div>
                    <i class="fa fa-bell" aria-hidden="true"></i>
                </div>
                <div className='col p-2'>
                    <div>
                        <p className='font-semibold text-sm text-gray-500'>{notification.data.title}</p>
                        <p className='text-sm text-gray-500'>{notification.data.body}</p>
                    </div>
                </div>
            </div>
            <hr className='mt-1' />
            <div className='flex items-center space-x-2 p-2 bg-gray-50'>
                <Badge text={convertToDateString(notification.created_at)} />
                <div className='w-2 h-2 rounded-full bg-gray-500 mx-1 mb-4'></div>
                <Badge text={notification.type} />
            </div>
        </div>

    );
}

export default Installations