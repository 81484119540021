import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContextProvider';
import AuthServices from '../../services/AuthServices';
import InputFormGroup from '../../components/InputFormGroup';
import Alert from '../../components/Alert';
import Badge from '../../components/Badge';
import GeneralServices from '../../services/GeneralServices';

const Login = () => {
    const [email, setEmail] = useState('admin@gmail.com');
    const [password, setPassword] = useState('password');

    const [errors, setErrors] = useState(null);


    const [validationError, setValidationError] = useState('');


    const { user, setUser } = useContext(AuthContext);

    const [users, setUsers] = useState([]);

    const [loading, setLoading] = useState(true);


    const service = new GeneralServices;

    const get_users = async () => {
        try {
            const res = await service.get('users');
            setUsers(res)
        } catch (error) {

        }
    }


    useEffect(() => {

        if (user) {

            navigate('/');

        }
        setLoading(false);
    }, [user]);

    useEffect(() => {
        get_users()
    }, []);



    const navigate = useNavigate();
    const authServices = new AuthServices();


    const handleSubmit = async (e) => {
        e.preventDefault();
        setValidationError('');
        try {
            const userData = await authServices.post('login', { email, password },);
            setUser(userData);

            localStorage.setItem('user', JSON.stringify(userData));

            navigate('/');
        } catch (error) {
            console.log('error', error)
            setErrors(error)
            if (error.validationData) {
                setValidationError(error.validationData);
            }
        }
    };

    if (loading) {
        return null; // or return a loading spinner
    }

    return (
        <div className="min-h-screen flex items-center justify-center bg-blue-200">
            <div className="w-full max-w-6xl flex bg-white rounded-lg  shadow-lg overflow-hidden">
                <div className="w-1/2 bg-blue-500 p-30 flex flex-col justify-center items-center text-white">
                    <h1 className="text-4xl font-bold mb-3">مرحبا بكم فى نظام المصاعد</h1>
                    <h2 className="text-2xl mb-5">اسهل , اوضح , اشمل</h2>
                    <div className='row p-3'>
                        {users && users.map(u => <span className='cursor-pointer m-1'><Badge text={u.name} color={u.email == email ? 'green' : 'gray'} onClick={() => setEmail(u.email)} /></span>)}

                    </div>
                    <ul className="list-disc ml-5">
                    </ul>
                </div>

                <div className="w-1/2 p-12">
                    <h3 className="text-2xl mb-5 text-center">قم بالدخول لاستخدام النظام</h3>
                    {errors && <Alert
                        message={errors.message}
                        type={errors.type}
                    />}
                    <form onSubmit={handleSubmit}>

                        <div className="mb-2">

                            <InputFormGroup
                                label='البريد الالكتروني'
                                type='email'
                                placeholder="البريد"
                                value={email}
                                col={12}
                                onChange={(e) => setEmail(e.target.value)}
                                error={validationError?.email?.[0]}
                            />
                            <InputFormGroup
                                label='كلمه السر'
                                type='password'
                                placeholder="كلمه السر"
                                value={password}
                                col={12}
                                onChange={(e) => setPassword(e.target.value)}
                                error={validationError?.password?.[0]}
                            />
                        </div>



                        <button type="submit" className="w-full py-2 px-4 text-center bg-blue-600 text-white rounded hover:bg-blue-500">دخول</button>
                    </form>



                </div>
            </div>
        </div>
    );
};

export default Login;
