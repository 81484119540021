import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import GeneralServices from '../../services/GeneralServices'
import SelectFormGroup from '../../components/SelectFormGroup'
import Modal from '../../components/Modal'
import InputFormGroup from '../../components/InputFormGroup'
import { showSuccessToast } from '../../utils/toastUtils'
import Badge from '../../components/Badge'

function ProductQuantities() {


    const [floors, setFloors] = useState([1, 2, 3, 4, 5, 6, 7, 8])
    const [activeFloor, setActiveFloor] = useState(1)
    const [elevator_type_id, setElevatorTypeId] = useState(1)
    const [products, setProducts] = useState([])

    const [stages, setStages] = useState([])

    const [stageOne, setStageOne] = useState([])
    const [stageTwo, setStageTwo] = useState([])
    const [stageThree, setStageThree] = useState([])

    const [showModal, setShowModal] = useState(false)



    const generalServices = new GeneralServices;


    const ProductQuantities = async () => {
        try {
            const res = await generalServices.post('product_quantities', {
                elevator_type_id,
                floor: activeFloor
            })



            setStageOne(res.stageOne)
            setStageTwo(res.stageTwo)
            setStageThree(res.stageThree)
        } catch (error) {
            console.log('error', error)
        }
    }
    // اضافة منتج
    const addProduct = () => setShowModal(!showModal)


    useEffect(() => {
        ProductQuantities();

    }, [activeFloor, elevator_type_id])


    return (
        <div className='m-4 mt-5 bg-white rounded-md shadow-md'>
            {showModal && <AddProductModal ProductQuantities={ProductQuantities} setShowModal={setShowModal} />}
            <div className='flex justify-between p-3 bg-gray-800 text-white items-center'>
                <p className='font-extrabold'>الادوار :</p>

                {floors.map((floor, index) => {
                    return <div
                        onClick={() => setActiveFloor(floor)}
                        className={`p-2 cursor-pointer rounded-md font-extrabold ${activeFloor == floor ? 'bg-blue-500 w-14 items-center justify-center flex text-white' : null}`}
                        key={index}>{floor}</div>
                })}
                <SelectFormGroup
                    value={elevator_type_id}
                    onChange={e => setElevatorTypeId(e.target.value)}
                    options={[
                        { id: 1, 'name': 'مصعد عادي' },
                        { id: 2, 'name': 'مصعد اوتمتك' },
                        { id: 3, 'name': 'طعام' },
                        { id: 4, 'name': 'بضاعه' },
                        { id: 5, 'name': 'مخصص' },
                    ]}
                    col={2}
                />
                <button onClick={addProduct}>اضافة منتج</button>
            </div>

            <div className='p-2'>
                <StageComponent ProductQuantities={ProductQuantities} stage={stageOne} info={{ name: 'المرحله الاولى' }} />
                <StageComponent ProductQuantities={ProductQuantities} stage={stageTwo} info={{ name: 'المرحله الثانيه' }} />
                <StageComponent ProductQuantities={ProductQuantities} stage={stageThree} info={{ name: 'المرحله الثالثه' }} />
            </div>
        </div>
    )
}


const AddProductModal = ({ setShowModal, ProductQuantities }) => {

    const [stages, setStages] = useState([
        { id: 1, 'name': 'المرحله الاولى' },
        { id: 2, 'name': 'المرحله الثانيه' },
        { id: 3, 'name': 'المرحله الثالثه' }
    ])

    const [products, setProducts] = useState([])

    const [elevator_types, setElevator_types] = useState([
        { id: 1, 'name': 'عادي' },
        { id: 2, 'name': 'اوتمتك' },
        { id: 3, 'name': 'طعام' },
        { id: 4, 'name': 'بضاعه' },
        { id: 5, 'name': 'مخصص' },
    ])

    const [floors, setFloors] = useState([
        { id: 1, name: 1 },
        { id: 2, name: 2 },
        { id: 3, name: 3 },
        { id: 4, name: 4 },
        { id: 5, name: 5 },
        { id: 6, name: 6 },
        { id: 7, name: 7 },
        { id: 8, name: 8 },
    ])

    const [data, setData] = useState({
        'elevator_type_id': 1,
        'stage': 1,
        'product_id': 1,
        'qty': 1,
        'floor': 1,
        'price': 1,
    })

    const generalServices = new GeneralServices;

    const get_products = async () => {
        try {
            const res = await generalServices.get('products')
            setProducts(res)
        } catch (error) {
            console.log('error', error)
        }
    }

    const handelAddProduct = async () => {
        try {

            await generalServices.post('add_product_quantities', data)
            showSuccessToast('تم اضافة المنتج بنجاح')
            ProductQuantities()
            setShowModal(false)
        } catch (error) {
            console.log('error', error)

        }
    }

    useEffect(() => {
        get_products()

    }, [])


    return (
        <Modal title='اضافة منتج' onClose={() => setShowModal(false)} setShowModal={setShowModal}>
            <SelectFormGroup
                label='المرحله'
                value={data.stage}
                onChange={e => setData({ ...data, stage: e.target.value })}
                options={stages}
                col={12}
            />
            <SelectFormGroup
                label='نوع المصعد'
                value={data.elevator_type_id}
                onChange={e => setData({ ...data, elevator_type_id: e.target.value })}
                options={elevator_types}
                col={12}
            />
            <SelectFormGroup
                label='الطابق'
                value={data.floor}
                onChange={e => setData({ ...data, floor: e.target.value })}
                options={floors}
                col={12}
            />
            <SelectFormGroup
                label='المنتج'
                value={data.product_id}
                onChange={e => setData({ ...data, product_id: e.target.value })}
                options={products}
                col={12}
            />
            <InputFormGroup
                label='الكميه'
                value={data.qty}
                onChange={e => setData({ ...data, qty: e.target.value })}
                col={12}
            />
            <InputFormGroup
                label='سعر الوحده'
                value={data.price}
                onChange={e => setData({ ...data, price: e.target.value })}
                col={12}
            />
            <hr className='my-3' />
            <div class="form-group col">

                <button onClick={handelAddProduct} class="btn btn-primary">اضافة</button>
            </div>
        </Modal>
    )
}

const StageComponent = ({ stage, info, ProductQuantities }) => {


    const service = new GeneralServices;

    const deleteProduct = async (id) => {
        try {
            await service.post(`product_quantities/delete/${id}`)
            showSuccessToast('تم حذف المنتج بنجاح')
            ProductQuantities();
        } catch (error) {
            console.log('error', error)
        }
    }

    return (
        <div>
            <div className='flex justify-center p-3'>
                <h1 className='text-2xl'>{info.name}</h1>
                <hr />
            </div>
            <table class="table table-bordered  ">
                <thead class="thead-inverse">
                    <tr>
                        <th>الاسم</th>
                        <th>الكميه</th>
                        <th>سعر الوحده</th>
                        <th>المجموع</th>
                        <th>اعدادات</th>
                    </tr>
                </thead>
                <tbody>
                    {stage.map((item, index) => (

                        <tr>
                            <td>{item?.product.name}</td>
                            <td>{item.qty}</td>
                            <td>{item.price}</td>
                            <td>{item.price * item.qty}</td>
                            <td>
                                <Badge text='حذف' color='red' onClick={() => deleteProduct(item.id)} />
                            </td>

                        </tr>
                    ))}

                </tbody>
            </table>
        </div>
    );

}
export default ProductQuantities