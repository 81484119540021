import React, { useEffect, useState } from 'react'
import GeneralServices from '../../../services/GeneralServices';
import Modal from '../../../components/Modal';
import InputFormGroup from '../../../components/InputFormGroup';
import TextareaInputFormGroup from '../../../components/TextareaInputFormGroup';

function ContractsAssignedList() {

    const [contracts, setContracts] = useState([])
    const [show, setShow] = useState(false)

    const service = new GeneralServices;

    const get_contracts = async () => {
        try {
            const data = await service.get('technician/contracts')
            setContracts(data)
        } catch (error) {
            console.log('error', error)
        }
    }

    const chechSatutes = async (status) => {

    }

    //  togleModel
    const toggleModel = () => {
        setShow(!show)
    }

    useEffect(() => {
        get_contracts()
    }, [])


    return (
        <div className='m-4 rounded shadow overflow-auto'>
            {show && <Modal onClose={toggleModel} title='بيانات الحاله'>
                <div className=''>
                    <TextareaInputFormGroup
                        label={'السبب'}
                        col={12}
                    />
                    <hr className='my-3' />
                    <div class="form-group col">

                        <button type="button" onClick={() => setShow(true)} class="btn btn-primary">اضافة</button>
                    </div>
                </div>
            </Modal>
            }

            <div className='p-2 bg-gray-700 text-white'>
                <h1>العقودات</h1>
            </div>
            <button type="button" onClick={get_contracts} class="btn btn-primary my-4">تحديث</button>
            <table class="table table-bordered  ">
                <thead class="thead-inverse">
                    <tr>
                        <th></th>
                        <th></th>
                        <th>الحاله</th>
                    </tr>
                </thead>
                <tbody>

                    {contracts && contracts.map((item, index) => (
                        <tr>
                            <td scope="row"></td>
                            <td>{item.id}</td>
                            <td>
                                <span onClick={() => chechSatutes('start')} class="badge badge-success cursor-pointer mx-1">بدء العملية </span>
                                <span onClick={toggleModel} class="badge badge-danger cursor-pointer mx-1">عدم بدء العملية </span>

                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default ContractsAssignedList
