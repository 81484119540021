import React, { useState } from "react";
import SelectFormGroup from "../../components/SelectFormGroup";
import GeneralServices from "../../services/GeneralServices";
import { useEffect } from "react";
import InputFormGroup from "../../components/InputFormGroup";
import { showErrorToast, showLoadingToast, showSuccessToast } from "../../utils/toastUtils";
import toast from "react-hot-toast";

function InvoiceCreate() {
    const [stage, setStage] = useState(1);

    const [products, setProducts] = useState([]);
    const [quotation_ids, setQuotation_ids] = useState([]);



    const service = new GeneralServices();

    const get_products = async () => {
        setProducts([]);
        setQuotation_ids([]);

        try {
            const data = await service.get(`invoices/${stage}`);
            setProducts(data.quotationData);
            setQuotation_ids(data.quotation_ids);

        } catch (error) {
            console.log("error", error);
        }
    };


    const handelSubmit = async () => {
        const show = showLoadingToast('جارى انشاء الفاتورة')
        try {
            const data = await service.post(`invoices`, { products, stage, quotation_ids });
            showSuccessToast('تم انشاء الفاتورة بنجاح')
            get_products();
        } catch (error) {

            showErrorToast(error?.validationData.products?.[0])

        } finally {
            toast.dismiss(show);
        }
    };


    useEffect(() => {

        get_products();
    }, [stage]);







    return (
        <div className="m-4 rounded-md overflow-hidden">
            <div className="flex justify-between p-2 items-center bg-gray-700">
                <h1 className="text-white font-medium">انشاء فاتوره</h1>
                <SelectFormGroup
                    value={stage}
                    onChange={(e) => setStage(e.target.value)}
                    options={[
                        { id: 1, name: "المرحله الاولى" },
                        { id: 2, name: "المرحله الثانيه" },
                        { id: 3, name: "المرحله الثالثه" },
                    ]}
                    col={3}
                />
            </div>

            <div>
                <table class="table table-bordered  ">
                    <thead class="thead-inverse">
                        <tr>
                            <th>اسم المنتج</th>
                            <th>الكميه</th>
                        </tr>
                    </thead>
                    <tbody>
                        {products &&
                            products.map((item, index) => {
                                return (
                                    <tr>
                                        <td>{item.product?.name}</td>
                                        <td>
                                            <InputFormGroup
                                                type="number"
                                                value={item.total_quantity}

                                                col={3}
                                            />
                                        </td>

                                    </tr>
                                );
                            })}
                    </tbody>
                </table>
            </div>
            <div className="row p-4 bg-slate-700">

                <div class="form-group">
                    <button onClick={handelSubmit} class="btn btn-primary">
                        انشاء
                    </button>
                </div>
            </div>
        </div>
    );
}

export default InvoiceCreate;
