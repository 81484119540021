import React, { useEffect, useState } from 'react'
import SelectFormGroup from '../../../components/SelectFormGroup'
import TextareaInputFormGroup from '../../../components/TextareaInputFormGroup'
import GeneralServices from '../../../services/GeneralServices';
import Loader from '../../../components/Loader';
import InputFormGroup from '../../../components/InputFormGroup';
import Multiselect from 'multiselect-react-dropdown';
import ReactQuill from 'react-quill';

function ElevatorData({ contract, setContract }) {

    const [data, setData] = useState([]);
    const [Loading, setLoading] = useState(true);
    const [otherAdditions, setOtherAdditions] = useState([])
    const [selectedOtherAdditions, setSelectedOtherAdditions] = useState([])

    const [elevatorData, setElevatorData] = useState({
        floor: '',
        elevator_type: '',
        cabin_rails_size: '',
        counterweight_rails_size: '',
        stop_number: '',
        elevator_trip: '',
        elevator_room: '',
        machine_type: '',
        machine_warranty: '',
        machine_load: '',
        machine_speed: '',
        people_load: '',
        control_card: '',
        entrances_number: '',
        outer_door_direction: '',
        inner_door_type: '',
        door_size: '',
        other_additions: '',
        visits_number: '',
        free_maintenance: '',
        elevator_warranty: '',
        elevator: '',
        notes: '',

    })

    useEffect(() => {
        setContract({ ...contract, elevator: elevatorData })
    }, [elevatorData])

    useEffect(() => {
        get_data();
        setContract({ ...contract, elevator: elevatorData })
    }, [])




    const generalServices = new GeneralServices;

    const get_data = async () => {
        try {
            const res = await generalServices.get('elevator_data')
            setData(res)
            setElevatorData(
                {
                    elevator_type: res.elevator_types[0].id,
                    cabin_rails_size: res.cabin_rails_sizes[0].id,
                    counterweight_rails_size: res.counterweight_rails_sizes[0].id,
                    stop_number: res.stops_numbers[0].id,
                    elevator_trip: res.elevator_trips[0].id,
                    elevator_room: res.elevator_rooms[0].id,
                    machine_type: res.machine_types[0].id,
                    machine_warranty: 1,
                    machine_load: res.machine_loads[0].id,
                    machine_speed: res.machine_speeds[0].id,
                    people_load: res.people_loads[0].id,
                    control_card: res.control_cards[0].id,
                    entrances_number: res.entrances_numbers[0].id,
                    outer_door_direction: res.outer_door_directions[0].id,
                    inner_door_type: res.inner_door_types[0].id,
                    door_size: res.door_sizes[0].id,
                    other_additions: res.additions,
                    visits_number: 1,
                    free_maintenance: 'سنه',
                    elevator_warranty: 'سنه',
                }
            )
            setOtherAdditions(res.additions)
        } catch (error) {
            console.log('error', error)
        } finally {
            setLoading(false)
        }
    }

    const handelUpdateData = (fild, value) => setElevatorData({ ...elevatorData, [fild]: value })

    useEffect(() => {
        setElevatorData({ ...elevatorData, other_additions: selectedOtherAdditions })

    }, [selectedOtherAdditions])


    if (Loading) return <Loader />;
    return (
        <div className='my-4'>
            <div className='bg-gray-700 p-2 flex justify-between items-center'>
                <h1 className='text-base text-white font-medium'>بيانات المصعد</h1>
            </div>

            <div className='row p-3'>
                <SelectFormGroup
                    label='نوع المصعد'
                    value={elevatorData.elevator_type}
                    onChange={(e) => handelUpdateData('elevator_type', e.target.value)}
                    options={data.elevator_types}
                    col={2}
                />
                <SelectFormGroup
                    label='مقاس سكك الكبينة'
                    value={data.cabin_rails_size}
                    onChange={(e) => handelUpdateData('cabin_rails_size', e.target.value)}
                    options={data.cabin_rails_sizes}

                    col={2}
                />

                <SelectFormGroup
                    label='مقاس سكك الثقل'
                    value={elevatorData.counterweight_rails_size}
                    onChange={(e) => handelUpdateData('counterweight_rails_size', e.target.value)}
                    options={data.counterweight_rails_sizes}
                    col={2}
                />
                <SelectFormGroup
                    label='عدد الوقفات'
                    value={elevatorData.stop_number}
                    onChange={(e) => handelUpdateData('stop_number', e.target.value)}
                    options={data.stops_numbers}
                    col={2}
                />
                <SelectFormGroup
                    label='مشوار المصعد'
                    value={elevatorData.elevator_trip}
                    onChange={(e) => handelUpdateData('elevator_trip', e.target.value)}
                    options={data.elevator_trips}
                    col={2}
                />
                <SelectFormGroup
                    label='غرفه المصعد'
                    value={elevatorData.elevator_room}
                    onChange={(e) => handelUpdateData('elevator_room', e.target.value)}
                    options={data.elevator_rooms}
                    col={2}
                />
                <SelectFormGroup
                    label='نوع المكينه'
                    value={elevatorData.machine_type}
                    onChange={(e) => handelUpdateData('machine_type', e.target.value)}
                    options={data.machine_types}
                    col={2}
                />
                <SelectFormGroup
                    label='ضمان المكينة'
                    value={elevatorData.machine_warranty}
                    onChange={(e) => handelUpdateData('machine_warranty', e.target.value)}
                    options={[
                        { id: 1, name: 1 },
                        { id: 2, name: 2 },
                        { id: 3, name: 3 },
                        { id: 4, name: 4 },
                        { id: 5, name: 5 },
                        { id: 6, name: 6 },
                        { id: 7, name: 7 },
                        { id: 8, name: 8 },
                        { id: 9, name: 9 },
                        { id: 10, name: 10 },

                    ]}
                    col={2}
                />
                <SelectFormGroup
                    label='حموله المكينه'
                    value={elevatorData.machine_load}
                    onChange={(e) => handelUpdateData('machine_load', e.target.value)}
                    options={data.machine_loads}
                    col={2}
                />
                <SelectFormGroup
                    label='سرعه المكينة'
                    value={elevatorData.machine_speed}
                    onChange={(e) => handelUpdateData('machine_speed', e.target.value)}
                    options={data.machine_speeds}
                    col={2}
                />
                <SelectFormGroup
                    label='حموله الاشخاص'
                    value={elevatorData.people_load}
                    onChange={(e) => handelUpdateData('people_load', e.target.value)}
                    options={data.people_loads}
                    col={2}
                />
                <SelectFormGroup
                    label='كرت الكنترول'
                    value={elevatorData.control_card}
                    onChange={(e) => handelUpdateData('control_card', e.target.value)}
                    options={data.control_cards}

                    col={2}
                />
                <SelectFormGroup
                    label='عدد المداخل'
                    value={elevatorData.entrances_number}
                    onChange={(e) => handelUpdateData('entrances_number', e.target.value)}
                    options={data.entrances_numbers}

                    col={2}
                />
                <SelectFormGroup
                    label='اتجاه فتح الباب الخارجي'
                    value={elevatorData.outer_door_direction}
                    onChange={(e) => handelUpdateData('outer_door_direction', e.target.value)}
                    options={data.outer_door_directions}
                    col={2}
                />
                <SelectFormGroup
                    label='نوع الباب الداخلى'
                    value={elevatorData.inner_door_type}
                    onChange={(e) => handelUpdateData('inner_door_type', e.target.value)}
                    options={data.inner_door_types}
                    col={2}
                />
                <SelectFormGroup
                    label='مقاس فتحه الباب'
                    value={elevatorData.door_size}
                    onChange={(e) => handelUpdateData('door_size', e.target.value)}
                    options={data.door_sizes}
                    col={2}
                />

                <InputFormGroup
                    label='اجمالى عدد الزيارات'
                    value={elevatorData.visits_number}
                    onChange={(e) => handelUpdateData('visits_number', e.target.value)}
                    col={2}
                />



                <SelectFormGroup
                    label='الصيانه المجانيه'
                    value={elevatorData.free_maintenance}
                    onChange={(e) => handelUpdateData('free_maintenance', e.target.value)}
                    options={[
                        { id: 'سنه', name: 'سنه' },
                        { id: 'سنتين', name: 'سنتين' },
                        { id: 'ثلاث سنوات', name: 'ثلاث سنوات' },
                        { id: 'اربعة سنوات', name: 'اربعة سنوات' },
                        { id: 'خمسة سنوات', name: 'خمسةسنوات' },
                        { id: 'ستة سنوات ', name: 'ستة سنوات' },
                        { id: 'سبعه سنوات ', name: 'سبعه سنوات' },
                        { id: 'ثمانيه سنوات ', name: 'ثمانيه سنوات' },
                        { id: 'تسعه سنوات ', name: 'تسعه سنوات' },
                        { id: 'عشرة سنوات ', name: 'عشرة سنوات' },
                    ]}
                    col={2}
                />
                <SelectFormGroup
                    label='ضمان المصعد'
                    value={elevatorData.elevator_warranty}
                    onChange={(e) => handelUpdateData('elevator_warranty', e.target.value)}
                    options={data.elevator_warranties}
                    col={2}
                />
                <div class="form-group col">
                    <label>اضافات اخرى</label>
                    <Multiselect
                        options={otherAdditions}
                        selectedValues={selectedOtherAdditions}
                        onSelect={setSelectedOtherAdditions}
                        onRemove={setSelectedOtherAdditions}
                        displayValue="name"
                        placeholder="اضافات اخرى"
                        // showCheckbox={true}
                        style={{ 'textAlign': 'right' }}
                    // onSearch={setSelectedEmployees}
                    />
                </div>


                <div class="form-group col-12 mt-4">
                    <label for="">ملاحظات</label>
                    <div className="scrollable-container col-12" style={{ maxHeight: '300px', overflow: 'auto' }}>
                        <ReactQuill
                            value={elevatorData.notes}
                            onChange={(e) => handelUpdateData('notes', e)}
                            theme="snow"

                        // modules={modules}
                        />
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ElevatorData