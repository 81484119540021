import React, { useState } from 'react';
import axios from 'axios';

const FileUploadComponent = ({ setData, data, validation }) => {

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];

        if (selectedFile) {
            readAndUploadFile(selectedFile);
        }
    };

    const readAndUploadFile = (file) => {
        const reader = new FileReader();

        reader.onloadend = () => {
            const binaryString = reader.result.split(',')[1];

            setData({ ...data, image: binaryString })
        };
        reader.readAsDataURL(file);
    };

    return (
        <div class="form-group">
            <label for="attachment">المرفقات</label>
            <input accept="image/*" type="file" class="form-control" onChange={handleFileChange} />
            <small id="helpId" class="form-text text-muted">{validation['client.image']?.[0]}</small>
        </div>
    );
};

export default FileUploadComponent;
