import React, { useEffect, useState } from 'react'
import GeneralServices from '../../../../../services/GeneralServices'
import Badge from '../../../../../components/Badge'
import { formatDateAndTime } from '../../../../../utils/utility'


const WorkOrderLogs = ({ id }) => {

    const [logs, setLogs] = useState([])


    const service = new GeneralServices

    const get_logs = async () => {
        console.log('get_logs')
        try {
            const res = await service.get(`installation/work-orders-logs/${id}`)
            setLogs(res)
            console.log('res', res)
        } catch (error) {
            console.error('error', error)
        }
    }




    useEffect(() => {
        get_logs()
        console.log('work order commant')

    }, [])



    return <div className='p-4'>
        <table class="table table-bordered ">
            <thead class="thead-inverse">
                <tr>
                    <th>التعليق</th>
                    <th>الحاله</th>
                    <th>المستخدم</th>
                    <th>التاريخ</th>
                </tr>
            </thead>
            <tbody>
                {logs && logs.map((log, index) => (

                    <tr key={index}>
                        <td>{log.comment}</td>
                        <td>
                            <Badge text={log?.status?.name} color={log?.status?.color} />
                        </td>
                        <td>{log?.user?.name}</td>
                        <td>{formatDateAndTime(log?.created_at).formattedDate} - {formatDateAndTime(log?.created_at).formattedTime}</td>
                    </tr>
                ))}

            </tbody>
        </table>
    </div>
}

export default WorkOrderLogs