import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import GeneralServices from '../../../../services/GeneralServices';
import Loader from '../../../../components/Loader';
import { client_name } from '../../../../utils/utility';

function Contracts() {

    const [contracts, setContracts] = useState([]);
    const [currentContract, setCurrentContract] = useState(null);
    const [attachmentModel, setAttachmentModel] = useState(false);
    const [payModel, setPayModel] = useState(false);
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        get_Contracts();

    }, [])


    const service = new GeneralServices;

    const get_Contracts = async () => {
        setLoading(true)
        try {
            const { data } = await service.get('installation/contracts')
            setContracts(data)
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }
    const open_attachment_model = (contract) => {
        setCurrentContract(contract)
        setAttachmentModel(!attachmentModel)
        // if (attachmentModel) {
        //     const c = contracts.find(c => c.id == contract.id)
        //     c.attachment = res.data.attachment
        //     setContracts(contracts)
        // }
    }

    // const open_attachment = (contract) => window.open('https://store.waelelevators.net/storage/' + contract.attachment, '_blank');
    // const create_pdf = (contract) => window.open('http://127.0.0.1:8000/generate_pdf/' + contract.id, '_blank');

    const open_attachment = (contract) => window.open('https://store.waelelevators.net/storage/' + contract.attachment, '_blank');
    const create_pdf = (contract) => window.open('https://store.waelelevators.net/generate_pdf/' + contract.id, '_blank');


    const pay = (contract) => {
        setCurrentContract(contract)
        setPayModel(!payModel)
    }

    if (loading) return <div className='flex items-center justify-center w-full h-screen'><Loader /></div>

    return (
        <div className="card m-4">

            <div className="card-header">
                <div className='flex justify-between'>
                    <h2>العقودات</h2>
                </div>
            </div>
            <div className="card-body">
                <table className="table table-bordered  ">
                    <thead className="thead-inverse">
                        <tr>
                            <th>اسم العميل</th>
                            <th>اسم المشروع</th>
                            <th>العنوان</th>
                            <th>المبلغ</th>
                            <th>نوع المصعد</th>
                            <th>اعدادت</th>
                        </tr>
                    </thead>
                    <tbody>
                        {contracts.length && contracts.map((contract, index) => {
                            if (!contract.has_work_order) {

                                return <tr key={index}>
                                    <td>
                                        <table class="table table-bordered">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        الاسم
                                                    </td>
                                                    <td>{client_name(contract.client)}</td>
                                                </tr>
                                                <tr>
                                                    <td>نوع العميل</td>
                                                    <td>{(contract?.client?.type)}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                    <td>{contract.project_name} </td>
                                    <td></td>
                                    <td>{contract.total ?? 0}</td>
                                    <td>{contract?.elevatorType?.name}</td>
                                    <td>
                                        <Link to={'/installations/work-orders/create/' + contract.id} className="badge cursor-pointer badge-info mx-1">اسناد فنين</Link>
                                        <Link to={`${contract.id}`} className="badge cursor-pointer badge-info mx-1">تفاصيل</Link>
                                    </td>
                                </tr>
                            }
                        })}
                    </tbody>
                </table>
            </div>
            <div className="card-footer text-muted">

            </div>
        </div>
    )
}

export default Contracts