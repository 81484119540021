import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import GeneralServices from '../../services/GeneralServices';
import SelectFormGroup from '../../components/SelectFormGroup';

function AssignToTechnicians() {

    const { id } = useParams()

    const [employees, setEmployees] = useState([]);
    const [stageId, setStageId] = useState(1);
    const [contract, setContract] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState([]);


    const service = new GeneralServices;

    const get_employees = async () => {
        try {
            const res = await service.get('employees')
            setEmployees(res)
        } catch (error) {

        }
    }

    const get_contract = async () => {
        try {
            const res = await service.get(`contract/${id}`);
            setContract(res)
            setStageId(res.stage_id)
        } catch (error) {

        }
    }

    const assignToTechnicians = async () => {
        try {
            const res = await service.post(`contract/${id}/assign`, {
                stage_id: stageId,
                employees: selectedEmployee,
            })
            showSuccessToast('تم تسليم العقد بنجاح')
        } catch (error) {

        }
    }

    const employee_toggle = (employee) => {
        if (selectedEmployee.includes(employee.id)) {
            setSelectedEmployee(selectedEmployee.filter(e => e != employee.id))
        } else {
            setSelectedEmployee([...selectedEmployee, employee.id])
        }
    }


    useEffect(() => {
        get_employees()
        get_contract()

    }, [])


    return (
        <div className='m-4 rounded overflow-auto'>
            <div className='bg-gray-700 p-2 flex justify-between items-center text-white'>
                <div>
                    <h1> اسناد الفنين للعقد</h1>
                </div>
                <SelectFormGroup
                    value={stageId}
                    onChange={(e) => setStageId(e.target.value)}
                    options={
                        [
                            { 'id': 1, 'name': 'المرحله الاولى' },
                            { 'id': 2, 'name': 'المرحله الثانيه' },
                            { 'id': 3, 'name': 'المرحله الثالثه' },
                        ]
                    }
                    col={3}
                />
            </div>

            <div className='p-3 shadow bg-white rounded'>
                <div className=''>
                    <table class="table table-bordered  ">
                        <tbody>
                            <tr className='bg-gray-100'>
                                <td className='col-2'>اسم المشروع</td>
                                <td className='col-2'>المدنيه</td>
                                <td className='col-2'>الشارع</td>
                                <td className='col-2'>المرحله</td>
                            </tr>
                            <tr>
                                <td>{contract?.project_name}</td>
                                <td>{contract?.city?.name}</td>
                                <td>{contract?.street}</td>
                                <td>
                                    <span class="badge badge-success">{contract?.stage?.name}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>

                <hr className='my-4' />
                <div className='row justify-between'>
                    {employees.map((employee) => (
                        <div class="col-3 shadow m-2 p-2 rounded">
                            <div class="form-check form-group ">
                                <label class="form-check-label">
                                    <input type="checkbox" class="form-check-input" onChange={() => employee_toggle(employee)} checked={selectedEmployee.includes(employee.id)} />
                                    {employee.name}
                                    <div>
                                        {employee.technician_info.mechanical == 1 ? <span class="badge mx-1 badge-success">ميكنيكيا</span> : null}
                                        {employee.technician_info.electricity == 1 ? <span class="badge mx-1 badge-danger">كهرباء</span> : null}
                                    </div>
                                </label>
                            </div>
                        </div>
                    ))}
                </div>
                <hr className='my-4' />

                <div>
                    <button onClick={assignToTechnicians} className='btn btn-primary'>اضافة</button>
                </div>
            </div>
        </div>
    )
}

export default AssignToTechnicians