import React from "react";
import { Link } from "react-router-dom";

function SupplierSidebar() {

    // if (user && user.user.level == 'admin') {
    // if (loading) return <Loader />

    return (
        <aside className="main-sidebar sidebar-light-primary elevation-4">
            <Link to="/" className="brand-link text-center">
                <img src={`https://pos.joudcart.com/dist/img/lo.png`} alt="Logo" />
            </Link>

            <div className="sidebar">
                <nav className="mt-2">
                    <ul
                        className="nav nav-pills nav-sidebar flex-column"
                        data-widget="treeview"
                        role="menu"
                        data-accordion="false"
                    >
                        <li className="nav-item"></li>

                        <li className="nav-item">
                            <Link to="/supplier" className="nav-link ">
                                <svg
                                    focusable="false"
                                    className=""
                                    data-icon="home"
                                    width="1em"
                                    height="1em"
                                    fill="currentColor"
                                    aria-hidden="true"
                                    viewBox="64 64 896 896"
                                >
                                    <path d="M946.5 505L560.1 118.8l-25.9-25.9a31.5 31.5 0 00-44.4 0L77.5 505a63.9 63.9 0 00-18.8 46c.4 35.2 29.7 63.3 64.9 63.3h42.5V940h691.8V614.3h43.4c17.1 0 33.2-6.7 45.3-18.8a63.6 63.6 0 0018.7-45.3c0-17-6.7-33.1-18.8-45.2zM568 868H456V664h112v204zm217.9-325.7V868H632V640c0-22.1-17.9-40-40-40H432c-22.1 0-40 17.9-40 40v228H238.1V542.3h-96l370-369.7 23.1 23.1L882 542.3h-96.1z"></path>
                                </svg>
                                <p>الرئيسية</p>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/supplier/myInvoices" className="nav-link ">
                                <img style={{ width: "18px", height: "18px" }} src={`../../../icons/diploma.png`} />
                                <p>عروض الاسعار</p>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/supplier/supplier_invoice" className="nav-link ">
                                <svg
                                    focusable="false"
                                    className=""
                                    data-icon="home"
                                    width="1em"
                                    height="1em"
                                    fill="currentColor"
                                    aria-hidden="true"
                                    viewBox="64 64 896 896"
                                >
                                    <path d="M946.5 505L560.1 118.8l-25.9-25.9a31.5 31.5 0 00-44.4 0L77.5 505a63.9 63.9 0 00-18.8 46c.4 35.2 29.7 63.3 64.9 63.3h42.5V940h691.8V614.3h43.4c17.1 0 33.2-6.7 45.3-18.8a63.6 63.6 0 0018.7-45.3c0-17-6.7-33.1-18.8-45.2zM568 868H456V664h112v204zm217.9-325.7V868H632V640c0-22.1-17.9-40-40-40H432c-22.1 0-40 17.9-40 40v228H238.1V542.3h-96l370-369.7 23.1 23.1L882 542.3h-96.1z"></path>
                                </svg>
                                <p>الفواتير</p>
                            </Link>
                        </li>





                    </ul>
                </nav>
            </div>
        </aside>
    );
}

export default SupplierSidebar;
