import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import GeneralServices from '../../services/GeneralServices';
import Modal from '../../components/Modal';
import InputFormGroup from '../../components/InputFormGroup';
import { showErrorToast, showSuccessToast } from '../../utils/toastUtils';
import SelectFormGroup from '../../components/SelectFormGroup';
import { client_name, playSound } from '../../utils/utility';
import Loader from '../../components/Loader';

function ContractList() {

    const [contracts, setContracts] = useState([]);
    const [currentContract, setCurrentContract] = useState(null);
    const [attachmentModel, setAttachmentModel] = useState(false);
    const [payModel, setPayModel] = useState(false);

    const [attachments, setAttachments] = useState([]);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        get_Contracts();

    }, [])


    const service = new GeneralServices;

    const get_Contracts = async () => {
        setLoading(true)
        try {
            const { data } = await service.get('contracts')
            setContracts(data)
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }
    const open_attachment_model = (contract) => {
        setCurrentContract(contract)
        setAttachmentModel(!attachmentModel)
        // if (attachmentModel) {
        //     const c = contracts.find(c => c.id == contract.id)
        //     c.attachment = res.data.attachment
        //     setContracts(contracts)
        // }
    }

    // const open_attachment = (contract) => window.open('https://store.waelelevators.net/storage/' + contract.attachment, '_blank');
    // const create_pdf = (contract) => window.open('http://127.0.0.1:8000/generate_pdf/' + contract.id, '_blank');

    const open_attachment = (contract) => window.open('https://store.waelelevators.net/storage/' + contract.attachment, '_blank');
    const create_pdf = (contract) => window.open('https://store.waelelevators.net/generate_pdf/' + contract.id, '_blank');


    const pay = (contract) => {
        setCurrentContract(contract)
        setPayModel(!payModel)
    }


    const [searchTerm, setSearchTerm] = useState('');

    const filteredContracts = contracts.filter((contract) => {
        const searchString = `${contract.client?.data?.name || ''} ${contract.client?.type || ''} ${contract.client?.data?.phone || ''}   ${contract.client?.data?.phone2 || ''} ${contract.client?.data?.whatsapp || ''}  ${contract.client?.data?.owner_name || ''} ${contract.project_name || ''} ${contract.district || ''} ${contract.city?.name || ''} ${contract.elevatorType?.name || ''} ${contract.contract_status || ''} ${contract.total || ''}`;
        return searchString.toLowerCase().includes(searchTerm.toLowerCase());
    });

    return (
        <div className="card m-4">

            <Attachment contracts={contracts} setContracts={setContracts} show={attachmentModel} contract={currentContract} open={open_attachment_model} />
            <Pay show={payModel} open={setPayModel} contract={currentContract} contracts={contracts} setContracts={setContracts} />
            <div className="card-header">
                <div className='row col justify-between'>
                    <h2>العقودات</h2>
                    <div className='col'></div>
                    <div className='row col-3' >
                        <InputFormGroup
                            value={searchTerm}
                            type='search'
                            placeholder='البحث'
                            onChange={(e) => setSearchTerm(e.target.value)}
                            col={9}
                        />
                        <Link to={`create`} className='btn bg-blue-500 text-white'>انشاء</Link>
                    </div>
                </div>
            </div>

            <div className="card-body">
                {loading ? <div className='flex items-center justify-center w-full'><Loader /></div> :

                    <table className="table table-bordered  ">
                        <thead className="thead-inverse">
                            <tr>
                                <th>اسم العميل</th>
                                <th>بيانات المصعد</th>
                                <th>المبلغ</th>
                                <th>حاله العقد</th>
                                <th>التاريخ</th>
                                <th>اعدادت</th>

                            </tr>
                        </thead>
                        <tbody>
                            {filteredContracts && filteredContracts.map((contract, index) =>
                                <tr key={index}>
                                    <td>
                                        <table class="table table-bordered">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        الاسم
                                                    </td>
                                                    <td>{client_name(contract.client)}</td>
                                                </tr>
                                                <tr>
                                                    <td>نوع العميل</td>
                                                    <td>{(contract?.client?.type)}</td>
                                                </tr>
                                                <tr>
                                                    <td>الهاتف</td>
                                                    <td>{(contract?.client?.data?.phone)}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                    <td>
                                        <table class="table table-bordered">

                                            <tbody>
                                                <tr>
                                                    <td>اسم المشروع</td>
                                                    <td>{contract.project_name}</td>
                                                </tr>
                                                <tr>
                                                    <td>نوع المصعد</td>
                                                    <td>{contract?.elevatorType?.name}</td>
                                                </tr>
                                                <tr>
                                                    <td>عدد الوقفات</td>
                                                    <td>{contract?.stopsNumbers?.name}</td>
                                                </tr>
                                                <tr>
                                                    <td>اسم الحي</td>
                                                    <td>{contract?.district}</td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </td>
                                    <td>{contract.total ?? 0}</td>
                                    <td>{contract.contract_status}</td>
                                    <td>{contract.created_at}</td>

                                    <td>

                                        {/* تحميل PDF */}
                                        <span onClick={() => create_pdf(contract)} className="badge items-center cursor-pointer badge-dark">
                                            <i className="fa fa-file-pdf-o mx-1" aria-hidden="true"></i>
                                            انشاء</span>
                                        <span onClick={() => pay(contract)} className="badge items-center mx-1 cursor-pointer badge-dark">
                                            <i className="fa fa-money mx-1" aria-hidden="true"></i>
                                            دفعيه</span>
                                        <Link to={'/contracts/' + contract.id} className="badge cursor-pointer badge-warning mx-1">عرض</Link>
                                        <Link to={'/contracts/edit/' + contract.id} className="badge cursor-pointer badge-info mx-1">تعديل</Link>
                                        {
                                            contract.attachment !== null ?
                                                <span onClick={() => open_attachment(contract)} className="badge badge-success cursor-pointer mx-1">تحميل</span>
                                                :
                                                <span onClick={() => open_attachment_model(contract)} className="badge cursor-pointer badge-warning mx-1">ارفاق العقد</span>
                                        }


                                    </td>

                                </tr>
                            )}
                        </tbody>
                    </table>}
            </div>
            <div className="card-footer text-muted">

            </div>
        </div>
    )
}

function Attachment({ show, open, contract, contracts, setContracts }) {
    if (!show) return null;

    const [attachment, setAttachment] = useState(null);
    const [validation, setValidation] = useState([]);
    const [loading, setLoading] = useState(false)


    const service = new GeneralServices;

    const attachment_file = async () => {

        setLoading(true)


        setValidation([])
        try {

            const formData = new FormData();
            formData.append('attachment', attachment);
            const res = await service.post('contracts/' + contract.id + '/attachment', formData)
            console.log('res', res)
            showSuccessToast('تم ارفاق العفد بنجاح')
            // setContracts(prevContracts => prevContracts.map(c => c.id === contract.id ? { ...c, attachment: res.data.attachment } : c))
            const c = contracts.find(c => c.id == contract.id)
            c.attachment = res.file_path
            setContracts(contracts)
            open()

        } catch (error) {
            if (error.validationData) {
                setValidation(error.validationData)
            }
        } finally {
            setLoading(false)

        }
    }

    const update_attachment = () => {
        const contracts = [...contracts]
        const c = contracts.find(c => c.id == contract.id)
        // c.attachment = res.data.attachment
        c.attachment = 'https://google.com';
        setContracts(contracts)

    }


    return <Modal title='ارفاق ملف' onClose={open}>
        <InputFormGroup
            type='file'
            name='attachment'
            label='الملف'
            onChange={(e) => {
                setAttachment(e.target.files[0]);
            }}
            col={12}
            error={validation?.attachment?.[0]}
        />
        <button onClick={attachment_file} disabled={loading} className={`btn btn-primary btn-md btn-block my-2 ${loading && 'loading'}`}>ارفاق</button>
    </Modal>

}

function Pay({ show, open, contract, contracts, setContracts }) {

    if (!show) return null;

    const [amount, setAmount] = useState(null);

    const [installments, setInstallments] = useState([]);
    const [stage, setStage] = useState(1);
    const [validation, setValidation] = useState([]);

    const [attachments, setAttachments] = useState(null);


    const [loading, setLoading] = useState(false)

    const service = new GeneralServices;

    const get_installments = async () => {
        try {
            const data = await service.get('contracts/' + contract.id + '/installments')
            setInstallments(data)
            setStage(contract.stage_id)
            console.log('data', contract)
            setAmount(data?.contract?.remaining_cost)
        } catch (error) {

        }
    }
    const payment = async (remaining) => {
        setValidation([]);
        setLoading(true)


        const formData = new FormData();

        if (attachments) {

            for (const attachment of attachments) {
                formData.append('files[]', attachment);
            }
        }

        formData.append('amount', amount)
        formData.append('contract_id', contract.id)
        formData.append('stage', stage)

        try {
            const res = await service.post('/payment', formData, {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json',
            })
            showSuccessToast('تم الدفع بنجاح')
            open();
        } catch (error) {


            if (error.validationData) {
                setValidation(error.validationData)
            }
            setLoading(false)
        } finally {
            setLoading(false)
        }
    }


    useEffect(() => {
        get_installments()

    }, [])


    return <Modal title='دفع العقد' onClose={open}>
        <div>
            <table class="table table-bordered">

                <tbody>
                    <tr>
                        <td>رقم العقد </td>
                        <td>#{contract.id}</td>
                    </tr>
                    <tr>
                        <td>المبلغ الكلي</td>
                        <td>{contract.total}</td>
                    </tr>

                    <tr>
                        <td>المتبقى</td>
                        <td>{contract.remaining_cost}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <table class="table table-bordered  ">
            <thead class="thead-inverse">
                <tr>
                    <th>الدفعيه</th>
                    <th>المبلغ</th>
                    <th>الضريبه</th>
                    <th>المجمموع </th>
                </tr>
            </thead>
            <tbody>
                {installments && installments.map((item, index) => {
                    const payedTitiles = ['الدفيعه الاولي', 'الدفيعه الثانيه', 'الدفيعه الثالثه', 'الدفيعه الرابعه', 'الدفيعه الخامسه']
                    return <tr>
                        <td>{payedTitiles[index]}</td>
                        <td>{item.amount}</td>
                        <td>{item.tax}</td>
                        <td>{parseFloat(item.amount) + parseFloat(item.tax)}</td>
                    </tr>
                }
                )}

            </tbody>
        </table>
        <hr className='my-3' />
        <InputFormGroup
            type='number'
            name='cost'
            value={amount}
            label='المبلغ'
            onChange={(e) => setAmount(e.target.value)}
            col={12}
            error={validation?.amount?.[0]}
        />
        <InputFormGroup
            type='file'
            name='attachments'
            label='المرفقات'
            onChange={(e) => setAttachments(e.target.files)}
            multiple={true}
            col={12}
        />
        <button onClick={() => payment(contract.remaining_cost)} type="button" disabled={loading} class="btn btn-primary btn-md col my-2 btn-block">دفع</button>
    </Modal>

}
export default ContractList