import React, { useEffect, useState } from 'react'
import InputFormGroup from '../../../../components/InputFormGroup'
import Badge from '../../../../components/Badge';
import GeneralServices from '../../../../services/GeneralServices';
import { showSuccessToast } from '../../../../utils/toastUtils';

function RfqCreate() {

    const [products, setProduct] = useState([]);

    const [productLis, setProductLis] = useState([]);


    const service = new GeneralServices;

    const creatInvoice = async () => {
        try {
            const res = await service.post('stock/rfq', { products })
            showSuccessToast('تم انشاء عرض السعر بنجاح');
            setProduct([])
        } catch (error) {

        }
    }

    const getProduct = async () => {
        try {
            const res = await service.get('stock/products')
            setProductLis(res)
            console.log('res', res)
        } catch (error) {

        }
    }

    const addProduct = (item) => {
        // اذا كان المنتج موجود فى ال products يتم تعديل الكميه
        const index = products.findIndex(product => product.id === item.id);
        if (index !== -1) {
            const updatedProducts = [...products];
            updatedProducts[index].qty += 1;
            setProduct(updatedProducts);
        }
        else {
            setProduct([...products, { ...item, qty: 1, name: item.name, id: item.id }]);
        }
    }



    const deleteItem = (id) => {
        alert("ahmed hmed", id)
    }

    const decrease = (id) => {
        const productIndex = products.findIndex((product) => product.id === id);
        if (productIndex !== -1) {
            if (products[productIndex].qty > 1) {
                products[productIndex].qty -= 1;
            } else {
                products.splice(productIndex, 1);
            }
            setProduct([...products]);
        }
    }


    const increce = (id) => {
        const product = products.find((product) => product.id === id);
        if (product) {
            product.qty += 1;
            setProduct([...products]);
        }
    }

    useEffect(() => {
        getProduct()
    }, [])


    return <div>

        <div className='p-3 h-full'>
            <div className='row  h-full'>
                <div className='col-8'>
                    <div className='row'>

                        {productLis.map((item, index) => <div className='m-1'>

                            <Badge text={item.name}
                                color={products.some((cartItem) => cartItem.id === item.id) ? 'red' : 'blue'}
                                onClick={() => addProduct(item)} /></div>)}
                    </div>
                </div>

                <div className='col-4 border flex-col'>
                    <div className='p-3  max-h-[600px] overflow-scroll'>
                        {products.map((item, index) => <div className='p-3 rounded border my-1'>
                            <div className='row justify-between' key={index}>
                                <p>{item.name}</p>
                                <div className='items-center content-center justify-center'>
                                    <Badge text='-' onClick={() => decrease(item.id)} color={'red'} />
                                    <p>{item.qty}</p>
                                    <Badge text='+' onClick={() => increce(item.id)} color={'green'} />
                                </div>
                            </div>
                        </div>
                        )}
                    </div>
                    <hr />
                    <div className='m-2 '>
                        <button onClick={creatInvoice} className='bg-blue-500 text-white rounded p-2'>انشاء عرض سعر</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

}

export default RfqCreate