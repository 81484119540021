import React from 'react'
import { Link } from 'react-router-dom'

function RfqList() {
    return (
        <div className='m-4 rounded shadow overflow-hidden'>
            <div className='bg-gray-400 text-white items-center justify-between row p-3'>
                <h1>عروض الاسعار</h1>
                <Link to='create' className='p-2 rounded border text-white'>انشاء عرض سعر</Link>
            </div>
        </div>
    )
}

export default RfqList